import React from 'react'
import { logoSrc } from 'common/style/icons'

import './LoginDialog.scss'

/*const req = require.context('common/style/icons/', false)
const logo = req('./logo.png').default
*/

const NotMe = ({accountStore, error, cellNum, updateField, onKeyDown, notMe, sentMessage, sentError, onStateChange, t}) => {
  const name = decodeURIComponent(accountStore.otpStatus.ContactName).replace(/\+/g, ' ')
  return <div styleName='loginForm'>
    <div styleName='logo'>
      <img src={logoSrc} />
    </div>
    <div styleName='innerContainer'>
      <div>
        <div styleName='heading'>{t('otp.notMeTitle')}</div>
        <div styleName='divider'></div>
      </div>
      <div>
        {error != null &&
          <div styleName="error_box">{error}</div>
        }
        {
          sentMessage !== '' && <div styleName={sentError ? 'error_box' : 'error_box green'}>{sentMessage}</div>
        }
        {sentMessage === '' && <div styleName='inputContainer'>
          <div styleName='label' style={{marginBottom: '3rem'}}>{t('otp.notMeEx', {name})}</div>
          <input 
            styleName='input'
            type="number"
            name="cellNum"
            value={cellNum}
            onChange={updateField}
            onKeyDown={e => onKeyDown(e, 'notMe')}
          />
        </div>}
        <div styleName='bottom'>
          <div styleName='link'><a onClick={() => onStateChange('login')} style={{paddingRight: '20px'}}>{t('otp.backToLogin')}</a></div>
        </div>
      </div>
      <button styleName='btn' onClick={notMe}>{t('otp.notMe')}</button>
    </div>
  </div>
}

export default NotMe