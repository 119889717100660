import React from "react";
import { winner } from 'common/style/icons'
import ReactTooltip from "react-tooltip";

import "../home.scss";
//const req = require.context("common/style/icons/", false);
//const winner = req("./winner.png").default;

const Winner = ({ data }) => {

  const { Summery = '' } = data
  return (
    <div styleName="winner-container" key={data.InfoID}>
      <div>
        <img src={winner} />
        <div styleName="title">{data.Winner}</div>
        <div styleName="label">:המכרז</div>
        <div styleName="value" data-tip={Summery}>{Summery.length > 200 ? `${Summery.slice(0, 200)}...` : Summery}</div>
        {Summery.length > 200 && <ReactTooltip type='info' multiline className='tooltip-default' />}
      </div>
      <div>
        <div styleName="divider"></div>
        <div styleName="label">:מפרסם</div>
        <div styleName="value">{data.Publisher}</div>
        {/* <div styleName="button">צפייה במכרז</div> */}
      </div>
    </div>
  );
};

export default Winner;
