import React from "react";
import { logoSrc } from "common/style/icons";
import OtpInput from "common/components/OtpInput";

import "./LoginDialog.scss";

/*const req = require.context('common/style/icons/', false)
const logo = req('./logo.png').default
*/

const SendOtp = ({
  accountStore,
  error,
  authCode,
  updateField,
  onKeyDown,
  sendOtp,
  resendCode,
  sentMessage,
  sentError,
  onStateChange,
  t,
  isMobile,
}) => {
  // const sentTo = accountStore.otpStatus.SentTo.replace(/\*/g, '')
  const sentTo = "";

  const setOtp = (otp) => {
    const evt = {};
    evt.target.name = "authCode";
    evt.target.value = otp;
    updateField(evt);
  };

  return (
    <div styleName="loginForm otp">
      <div styleName="logo">
        <img src={logoSrc} />
      </div>
      <div styleName="innerContainer">
        <div>
          <div styleName="heading">{t("otp.otpTitle")}</div>
          <div styleName="divider"></div>
        </div>
        <div>
          {error != null && <div styleName="error_box">{error}</div>}
          {sentMessage !== "" && (
            <div styleName={sentError ? "error_box" : "error_box green"}>
              {sentMessage}
            </div>
          )}
          <div styleName="inputContainer">
            <div styleName="label" style={{ marginBottom: "3rem" }}>
              {t("otp.sendOtpEx")}
            </div>
            {/* {isMobile && <OtpInput onChange={setOtp} />} */}
            <input
              styleName="input"
              type="number"
              name="authCode"
              value={authCode}
              onChange={updateField}
              onKeyDown={(e) => onKeyDown(e, "sendOtp")}
            />
          </div>
          {!isMobile && (
            <div styleName="bottom">
              <div styleName="link">
              <a>
             {t("otp.didNotRecieveMessage")}
                </a>
                <a
                  onClick={() => onStateChange("notMe")}
                  style={{ paddingRight: "7px" }}
                >
                  {t("otp.notMyNumber")}
                </a>
              </div>
            </div>
          )}
          <div styleName="bottom flexColumn">
            <div styleName='some-text'>{t("otp.didNotRecieveMessage")}</div>
            <div styleName="link resend-code">
              <a onClick={resendCode}>{t("otp.resendCode")}</a>
            </div>
          </div>
        </div>
        <button styleName="btn" onClick={sendOtp}>
          {t("otp.login")}
        </button>
      </div>
    </div>
  );
};

export default SendOtp;
