import React from "react";
//import { object, func } from 'prop-types'
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import { getDefaultDates } from "common/utils/filter";
import moment from "moment";
import find from "lodash/find";
import filter from "lodash/filter";
import MultipleFilter from "../MultipleFilter";
import ComboFilter from "../ComboFilter";
import TenderTypeFilter from "../TenderTypeFilter";
import DateFilter from "../DateFilter";
import DateButtons from "../DateButtons";
import SearchTextFilter from "../SearchTextFilter";
import AuctionFilter from "../AuctionFilter";
import ExcludeFilter from "../ExcludeFilter";
import Loading from "common/components/Loading/Loading";

import "./resultFilter.scss";

export default
@translate()
@inject("searchStore")
@observer
class Filters extends React.Component {
  /*
  static propTypes = {
    selectedFilters: object,
    setSelected: func
  }
  */

  @observable dateField = "publishdate";
  @observable dateType = 'calendar'
  /*
  componentDidMount() {
    //console.log('filters mount')
  }*/

  componentWillReceiveProps(nextProps) {
    const { searchStore } = this.props;
    //only if not committed: create a new filter from subsubject tags - to mark them on MultipleFilter
    if (searchStore.filters.length == 0) {
      //get current tags and check if there are subsubjects in it
      const tags = filter(searchStore.tags, (item) => {
        return item.ResType === "subsubject" || item.ResType === "subject";
      });

      const subjectFilter = { field: "subject", values: [] }
      const subSubjectFilter = { field: "subsubject", values: [] }
      //iterate on tags:
      tags.map((tag) => {
        const { ResType, ID } = tag

        if (ResType === "subject" && !subjectFilter.values.includes(ID)) {
          subjectFilter.values.push(ID)
        }
        else if (ResType === "subsubject" && !subSubjectFilter.values.includes(ID)) {
          subSubjectFilter.values.push(ID)
        }
        const newFilters = [];
        if (subjectFilter.values.length > 0) {
          newFilters.push(subjectFilter)
        }
        if (subSubjectFilter.values.length > 0) {
          newFilters.push(subSubjectFilter)
        }
        const filters = JSON.stringify(newFilters);
        searchStore.applyFilters(filters);
      });
    }
  }

  cleanFilters = () => {
    const { searchStore } = this.props;
    searchStore.filters.clear();
    searchStore.clearFilterLabels();
    //searchStore.clearResults()
    searchStore.fromRoute = true; //raise route flag
    searchStore.initialDate = true; //for last month label...
    searchStore.loadNextResults();
    searchStore.loadNextFilters(); //cached, but will allow filters to be unchecked on child components
  };

  chooseDateField = (field) => {
    this.dateField = field;
    //set the date field name
    const { searchStore, t } = this.props;
    searchStore.setSelectedFilters(
      "dateField",
      this.dateField,
      t("filter.more")
    );
  };

  changeDateType = (field) => {
    this.dateType =  field
  }

  render() {
    const {
      searchStore,
      searchStore: { filtersLoading, selectedFilters, tags },
      t,
      isMobile,
      onClose,
    } = this.props;
    //note: selectedFilters - should maintain the state of child filter components, after this component recreates;
    const subsubjects = selectedFilters ? selectedFilters.subsubjects : "";
    const publishers = selectedFilters ? selectedFilters.publishers : "";
    const dateField = selectedFilters
      ? selectedFilters.dateField || "publishdate"
      : "publishdate";
    const defaultDates = getDefaultDates(tags);
    const dateValues =
      selectedFilters && selectedFilters.date
        ? selectedFilters.date[dateField] || defaultDates
        : defaultDates;
    const text = selectedFilters ? selectedFilters.searchText : "";

    return (
      <div
        styleName={`filter_container ${isMobile ? "mobile_filter_container" : ""
          }`}
      >
        <div styleName="filter_title">{t("results.filtersPopupTitle")}</div>
        <div styleName="filter_subtitle">{t("results.filterSubtitle")}</div>
        {!isMobile && <div styleName="line" />}
        {/* <div styleName="filter_ttl">
          <a styleName="clean" onClick={this.cleanFilters}>
            {t("filter.clean")}
          </a>
          <h4>{t("filter.title")}:</h4>
        </div> */}
        {filtersLoading && <Loading />}
        {!filtersLoading && (
          <div>
            {/* {isMobile && <AuctionFilter />} */}
            {
              <MultipleFilter
                type="subsubjects"
                items={searchStore.availableFilters.Subjects}
                label={subsubjects}
              />
            }
            <div styleName="line" />
            <TenderTypeFilter
              items={searchStore.availableFilters.TenderTypes}
            />
            <div styleName="line" />
            <MultipleFilter
              type="publishers"
              items={searchStore.availableFilters.Publishers}
              label={publishers}
            />
            <div styleName="line" />

            {/*<ComboFilter
              type="publishers"
              items={searchStore.availableFilters.Publishers}
            />*/}

            <ExcludeFilter />
            <div styleName="line" />
            <DateFilter
              dateField={this.dateField}
              chooseDateField={this.chooseDateField}
              dateValues={dateValues}
              dateType={this.dateType}
            />
            <DateButtons
              dateField={this.dateField}
              chooseDateField={this.chooseDateField}
              changeDateType={this.changeDateType}
            />
            <SearchTextFilter text={text} isMobile={isMobile} />
            {isMobile && (
              <>
                <div styleName="line" />
                <div styleName="bottom-container">
                  <div styleName="total-filters">
                    {t("results.totalFilters", { count: 15 })}
                  </div>
                  <div styleName="filter-btn" onClick={onClose}>
                    {t("results.closeFiltersPopup")}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}
