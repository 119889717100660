import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Authenticate from 'common/components/Authenticate'
import CheckVersion from 'common/components/CheckVersion'
import Topbar from 'app/components/Topbar'
//import ContactAction from 'common/components/ContactAction'
import WhatsAppIcon from 'common/components/WhatsAppIcon'
import GoUpIcon from 'common/components/UpIcon'
import HomePage from 'pages/home'
import SearchPage from 'pages/search'
import ResultsPage from 'pages/results'
import MainPage from 'pages/main'
import TenderPage from 'pages/tender'
import FavoritesPage from 'pages/favorites'
import RemindersPage from 'pages/reminders'
import PublishPage from 'pages/publish'
import ContactPage from 'pages/contact'
//import SubscriptionsPage from 'pages/subscriptions'
import ServicesPage from 'pages/services'
import ThankYouPage from 'pages/thankyou'
import ArticlesPage from 'pages/articles'
import ArticlePage from 'pages/article'
import CategoryPage from 'pages/category'
import SmartAgentPage from 'pages/smartagent'
import SmartAgentDefPage from 'pages/smartagentdef'
import RadarPage from 'pages/radar'
import AboutPage from 'pages/about'
import SiteMapPage from 'pages/sitemap'
import LoginPage from 'pages/login'
import DistAgentPage from 'pages/distagent'
import RedirectorPage from 'pages/redirector'
import RestorePage from 'pages/restore'
import FeedbackPage from 'pages/feedback'
import NotFound404 from 'pages/notFound404'
import BottomBar from '../common/components/BottomBar'
import RadarInfoPage from 'pages/radarInfo'
import TermsOfUsePage from 'pages/termsOfUse'
import QandAPage from 'pages/qanda'

import './pages.scss'

class Pages extends React.Component {
  state = {
    notify: false,
  };

  showNotification = (notify) => {
    this.setState({ notify });
    //console.log('notify', notify)
  };

  render() {
    const { accountStore } = this.props;
    return (
      <section>
        <Authenticate />
        <CheckVersion />
        <Topbar notify={this.state.notify} />
        <div styleName="app1">
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home/:opencats?">
              <HomePage />
            </Route>
            <Route path="/results/:sort/:tags/:filters/:isHome?">
              <ResultsPage />
            </Route>
            <Route path="/main">
              <MainPage showNotification={this.showNotification} />
            </Route>
            <Route path="/search">
              <SearchPage />
            </Route>

            <Route path="/tender/:itemId/:mode?/:isemail?">
              <TenderPage />
            </Route>
            <Route path="/smartagent">
              <SmartAgentPage showNotification={this.showNotification} />
            </Route>
            <Route path="/smartagentdef">
              <SmartAgentDefPage showNotification={this.showNotification} />
            </Route>
            <Route path="/favorites">
              <FavoritesPage showNotification={this.showNotification} />
            </Route>
            <Route path="/reminders">
              <RemindersPage showNotification={this.showNotification} />
            </Route>
            <Route path="/publish">
              <PublishPage showNotification={this.showNotification} />
            </Route>
            <Route path="/contact">
              <ContactPage showNotification={this.showNotification} />
            </Route>
            <Route path="/services">
              <ServicesPage showNotification={this.showNotification} />
            </Route>
            <Route path="/terms-of-use">
              <TermsOfUsePage />
            </Route>
            <Route path="/qanda">
              <QandAPage />
            </Route>
            <Route path="/thankyou">
              <ThankYouPage />
            </Route>
            <Route path="/articles">
              <ArticlesPage />
            </Route>
            <Route path="/article/:id">
              <ArticlePage />
            </Route>
            <Route path="/category/:id/:name/:mode?">
              <CategoryPage />
            </Route>
            <Route exact path="/radar/:tender?">
              <RadarPage />
            </Route>
            <Route exact path="/radar/info/:tender">
              <RadarInfoPage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/sitemap">
              <SiteMapPage />
            </Route>
            <Route path="/al/:token/:tender?">
              <LoginPage />
            </Route>
            <Route path="/distagent/:uid/:type?">
              <DistAgentPage showNotification={this.showNotification} />
            </Route>
            <Route path='/redirector/:where/:data'>
              <RedirectorPage />
            </Route>
            <Route path="/restore/:token/:u?">
              <RestorePage showNotification={this.showNotification} />
            </Route>
            <Route path="/feedback/:id">
              <FeedbackPage />
            </Route>
            <Route>
              <NotFound404 />
            </Route>
          </Switch>
          {/*<ContactAction />*/}
          <WhatsAppIcon />
          <GoUpIcon/>
          {accountStore.profile && <BottomBar />}
        </div>
      </section>
    );
  }
}
export default inject("accountStore")(observer(Pages));
