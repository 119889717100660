import React, {Component} from 'react'
import { withRouter } from 'react-router'
import {translate} from 'react-polyglot'
import {logClick} from 'common/services/apiService'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

export default
@withRouter
@translate()
@observer
class Redirector extends Component {

  @observable message = ''

  componentDidMount() {    
    const { match: {params: { where, data }} ,t } = this.props
    this.message = t('redirector.pleaseWait')
    //console.log('redirector', decodeURIComponent(where), JSON.parse(decodeURIComponent(data)))
    const goToUrl = decodeURIComponent(where)
    const bannerData = JSON.parse(decodeURIComponent(data))
    logClick(bannerData.bannerID).then(() => {
      //redirect to somewhere
      location.href = goToUrl
    }).catch(error => {
      this.message = t('redirector.clickError')
    })

  }


  render() {
    const {t} = this.props

    return (
      <div style={{marginTop: '50px'}}>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell large-12" style={{textAlign: 'center'}}>
              <h2 style={{paddingTop: '5rem'}}>{this.message}</h2>
            </div>
          </div>
        </div>
      </div>)
  }
}
