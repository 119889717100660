import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { translate } from "react-polyglot";
import { withRouter } from "react-router";
import Article from "./Items/Article";
import YouTubeTip from "./Items/YouTubeTip";
import Footer from "common/components/Footer";
import { getHomeJSON } from "common/services/apiService";
import { getMetaData } from "common/utils/meta";
//import { fixTopMenu } from "common/utils/topMenu";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import GTAG from "common/utils/gtag";
import Hero from "./ItemsNew/Hero";
import Instructions from "./ItemsNew/Instructions";
import Review from "./ItemsNew/Review";
import Sample from "./ItemsNew/Sample";
import Winner from "./ItemsNew/Winner";
import Slider from "../../common/components/Slider/Slider";
import SectionContainer from "./SectionContainer";
import {
  leadingIndustriesSliderConfig,
  sampleTendorsSliderConfig,
  lastWinnersSliderConfig,
  testinomialSliderConfig,
} from "../../common/constants";
import {
  subject1,
  subject2,
  subject3,
  subject4,
  subject5,
  subject6,
  subject7,
  subject8,
} from "common/style/icons";

import "./home.scss";
import "../../common/style/home.css";
import Adsense from "../../common/components/Adsense/Adsense";

const images = [
  subject3,
  subject2,
  subject1,
  subject7,
  subject5,
  subject6,
  subject4,
  subject8,
];

export default
@withRouter
@translate()
@inject("homeStore")
@inject("routingStore")
@inject("accountStore")
@observer
class Home extends Component {
  @observable allCats = false;
  @observable opportunities = [];
  @observable testemonials = [];
  @observable articles = [];
  @observable movies = [];

  constructor(props) {
    super(props);
    this.state = { width: 0};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const {
      homeStore,
      match: {
        params: { opencats },
      },
    } = this.props;
    homeStore.loadCatResults().then(() => {
      homeStore.loadSubCatResults();
    });
    homeStore.loadSampleTenders();
    homeStore.loadWinneres();

    //json data for hard-coded stuff:
    //const cache = randomNumber(100000, 1000000)
    const cache = 100001; //if needed, use the random number when articles change
    getHomeJSON("Articles", "articles-preview", cache).then((res) => {
      this.articles = res;
    });
    getHomeJSON("Opportunities", "opportunities").then((res) => {
      this.opportunities = res;
    });
    getHomeJSON("Testemonials", "testemonials").then((res) => {
      this.testemonials = res;
    });
    getHomeJSON("Movies", "movies", cache).then((res) => {
      this.movies = res;
    });
    //fixTopMenu();
    if (opencats && opencats == "open") {
      this.allCats = true; //open all cats by default
    }
    GTAG.trackPage("Home", "home");

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  showAllCats = () => {
    this.allCats = !this.allCats;
  };

  goTo = (nav) => {
    const {
      routingStore: { push },
    } = this.props;
    push(nav);
  };

  render() {
    const {
      homeStore,
      accountStore,
      homeStore: { resultsLoading },
      t,
    } = this.props;
    const catStyle = this.allCats ? "" : "hide";
    const catLabel = this.allCats ? t("home.hideAllCat") : t("home.showAllCat");
    const meta = getMetaData(
      t("meta.homeTitle"),
      t("meta.homeDesc"),
      t("meta.homeKeywords")
    );

    return (
      <div>
        <DocumentMeta {...meta} />
        <Hero t={t} />
        <Instructions t={t} />
        <section styleName="section-3">
          <div>
            <span styleName="heading-container">
              <span styleName="right-heading">
                {t("home.leadingIndustries")}
              </span>
              {/* <span styleName="left-heading">{t("home.viewAllAreas")}</span> */}
            </span>
            <Slider configuration={leadingIndustriesSliderConfig}>
              {homeStore.catResults.map((item, index) => {
                const url = `/results/publishDate/[{"I":${item.subsubjectId},"R":"s"}]/[]/true`;
                return (
                  <Link
                    to={url}
                    key={`cat_${index}`}
                    style={{ cursor: "pointer" }}
                  >
                    <div styleName="slide">
                      <div styleName="overlay" />
                      <img
                        src={images[index]}
                        // src="https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg"
                        styleName="slider-image"
                      />
                      <div styleName="content">
                        <div>{item.subsubjectName}</div>
                        <span styleName="bottom-content">
                          <span>{item.count}</span> מכרזים
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        </section>
        <SectionContainer
          title={t("home.recentTenders")}
          subTitle={t("home.latestTendersText")}
          containerClassName="section-4"
          type="link"
          // bottomComponent={
          //   <div styleName="bottom-text">{t("home.viewMoreTenders")}</div>
          // }
          sliderConfig={sampleTendorsSliderConfig}
        >
          {homeStore.sampleTenders.map((sampleData, index) => (
            <Sample t={t} data={sampleData} key={`sample_${index}`} />
          ))}
        </SectionContainer>
        <SectionContainer
          title={t("home.lastWinners")}
          subTitle={t("home.congratulationsText")}
          containerClassName="section-5"
          sliderConfig={lastWinnersSliderConfig}
        >
          {homeStore.lastWinners.map((winnerData, index) => (
            <Winner data={winnerData} key={`winner_${index}`} />
          ))}
        </SectionContainer>

        {this.testemonials && this.testemonials.length > 0 && (
          <SectionContainer
            title={t("home.ourCustomers")}
            subTitle={t("home.text")}
            containerClassName="section-7"
            type="review"
            sliderConfig={testinomialSliderConfig}
          >
            {this.testemonials.map((testimonial, index) => (
              <Review data={testimonial} key={`review_${index}`} />
            ))}
          </SectionContainer>
        )}

        {this.articles && this.articles.length > 0 && (
          <SectionContainer
            title={t("home.articles")}
            subTitle={t("home.articlesText")}
            containerClassName="section-6 grey-background"
            linkText={t("home.allArticles")}
            slide={false}
            bottomComponent={
              <Link to={"/articles"} style={{ cursor: "pointer" }}>
                <div styleName="bottom-text" onClick={() => {}}>
                  {t("home.allArticles")}
                </div>
              </Link>
            }
          >
            <div styleName="articles">
              {this.articles.map((article, index) => (
                <Article
                  key={`article_${index}`}
                  articleID={article.articleID}
                  title={article.title}
                  imgSrc={article.image}
                  goTo={this.goTo}
                />
              ))}
            </div>
          </SectionContainer>
        )}

        {this.movies && this.movies.length > 0 && (
          <SectionContainer
            title={t("home.movies")}
            slide={false}
            // subTitle={t("home.articlesText")}
            containerClassName="section-6 sm-pt-0"
          >
            <div styleName="movies">
              {this.movies.map((movie, index) => (
                <YouTubeTip
                  key={`youtube_${index}`}
                  title={movie.title}
                  thumbnail={movie.thumbnail}
                  url={movie.url}
                />
              ))}
            </div>
          </SectionContainer>
        )}
        <Footer />
        <div
          styleName={`adsense-container-mobile ${
            accountStore.profile ? "isLogin" : ""
          }`}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div style={{ position: "absolute" }}>
              <Adsense
                style={{
                  display: "block",
                  width: `${this.state.width}px`,
                  height: "100px !important",
                  alignSelf: "center",
                }}
                slot="3242243419"
                googleAdId="ca-pub-7408487462494237"
              />
            </div>
          </div>
        </div>
        {/*<ContactAction />*/}
      </div>
    );
  }
}
