import React from "react";
import Slider from "../../../common/components/Slider";
import { star } from 'common/style/icons'

import "../home.scss";
//const req = require.context("common/style/icons/", false);
//const star = req("./star.png").default; //mail_w.png

const SectionContainer = ({
  title,
  subTitle,
  containerClassName = "",
  children,
  type = "",
  slide = true,
  bottomComponent = <></>,
  sliderConfig,
}) => {
  return (
    <section styleName={containerClassName}>
      <div styleName="reviews-container">
        <div styleName="title">{title}</div>
        <div styleName="subTitle" style={{ marginBottom: "6rem" }}>
          {subTitle}
        </div>
        {type === "review" && (
          <div styleName="stars">
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
          </div>
        )}
        {slide ? (
          <Slider configuration={sliderConfig}>{children}</Slider>
        ) : (
          <>{children}</>
        )}
        {bottomComponent}
      </div>
    </section>
  );
};

export default SectionContainer;
