import React, { useState }  from "react";
import { inject, observer } from "mobx-react";
import { useTranslate } from "react-polyglot";
import { DownOutlined } from "@ant-design/icons";
import "./sort.scss";
import "antd/dist/antd.css";

const SortResults = ({ changeSort, routingStore }) => {
  const t = useTranslate();
  const [show,setShow] = useState(false)
  const { location } = routingStore;

  const sortBy = t(`results.${location.state?.sort || "publishDate"}`);


  return (
    <div styleName="dropdown-container" >
      הצגה לפי:
      <div styleName="dropdown" onClick={() => setShow(prev => !prev)}>
        {sortBy}
        <DownOutlined />
      </div>
      {
        show && (
          <div styleName="dropdown-modal" >
            <button onClick={() => changeSort("publishDate")} >{t("results.publishDate")}</button>
            <button onClick={() => changeSort("infoDate")} >{t("results.infoDate")}</button>
          </div>
        )
      }
    </div>
  );
};

export default inject("routingStore")(observer(SortResults));
