import React, { useState } from "react";
import "./checkboxes.scss";

const transformTo2DArray = (noOfColumns, data) => {
  const result = [];
  for (let index = 0; index < noOfColumns; index++) {
    result.push([]);
  }
  for (let row = 0; row < data.length; row++) {
    let columnIndex = row % noOfColumns;
    result[columnIndex].push(data[row]);
  }
  return result;
};

const Checkboxes = ({
  items,
  onCheck,
  selectedItems,
  type = "",
  categoryType = "",
  columns = 4,
}) => {
  const transformedItems = transformTo2DArray(columns,items)
  return (
    <div styleName="row_container">
      {transformedItems.map((columnItems, rowIndex) => {
        return (
          <div key={rowIndex} styleName="column_container" >
            {
              columnItems.map((item,columnIndex) => {
                return (
                  <CheckboxItem
                    key={columnIndex}
                    item={item}
                    selectedItems={selectedItems}
                    type={type}
                    categoryType={categoryType}
                    onCheck={onCheck}
                  />
                );
              })
            }
          </div>
        )
      })}
    </div>
  );
};

export default Checkboxes;

const CheckboxItem = ({ 
  categoryType, 
  item, 
  type, 
  onCheck,
  selectedItems,
}) => {
  const [show, setShow] = useState(false);
  let id,
    name,
    subSubjects = [];
  if (type === "multi1") {
    id = categoryType === "subsubjects" ? item.SubjectID : item.PublisherID;
    name = categoryType === "subsubjects" ? item.SubjectName : item.PublisherName;
    subSubjects = categoryType === "subsubjects" ? item.SubSubjects : []

  } else {
    id = item.SubjectID;
    name = item.SubjectName;
    subSubjects = item.SubSubjects.filter((subSubject) => subSubject.SubSubjectID !== -1);
  }

  const toggleSubSubject = (e, subSubjects) => {
    e.stopPropagation();
    if (subSubjects.length === 0) {
      return;
    }
    setShow((prev) => !prev);
  };

  const showSubSubjectsIfThereIsAny =  subSubjects.length > 0 && show

  return (
    <div>
      <div
        styleName={`checkbox ${subSubjects.length > 0 ? "sub-checkbox-container" : ""}`}
      >
        <div styleName="cb-label">
          <input
            type="checkbox"
            styleName="checkbox"
            checked={selectedItems.includes(id)}
            name={name}
            value={id}
            onChange={(e) => {
              onCheck(e,{type: categoryType === "publishers" ? "publisher" : "subject",subSubjects: subSubjects})
              if(e.target.checked) {
                setShow(true)
              }
            }}
          />
          <span 
            styleName={`label ${subSubjects.length > 0 ? "strong-label" : ""}`}
            onClick={(e) => toggleSubSubject(e, subSubjects)} 
          >
            {name}{subSubjects?.length > 0 ? ` (${subSubjects.length})` : ""}
          </span>
        </div>
      </div>
      {showSubSubjectsIfThereIsAny && (
        <div styleName="sub-checkbox">
          {subSubjects.map((subSubject, index) => {
            return (
              <label key={index} styleName="cb-label">
              <input
                type="checkbox"
                styleName="checkbox"
                checked={selectedItems.includes(subSubject.SubSubjectID)}
                name={subSubject.SubSubjectName}
                value={subSubject.SubSubjectID}
                onChange={(e) => onCheck(e,{type: "subsubject", subjectID: id})}
              />
              <span styleName="label" >{subSubject.SubSubjectName.slice(0,25)}</span>
            </label>
            )
          }
            
          )}
        </div>
      )}
    </div>
  );
};

