import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import { withRouter } from "react-router";
import { translate } from "react-polyglot";
import moment from 'moment';
import {observer, inject} from 'mobx-react'
import {observable} from 'mobx'
import Footer from "common/components/Footer";
import {getMetaData} from "common/utils/meta"
import './radarInfo.scss'
import { getRadarInfo } from '../../common/services/apiService';

const req = require.context('common/style/icons', false)
const unauthorizedImage = req('./unauthorized.png')
const badRequest = req('./bad-request.png')
const noDataFound = req('./no-data.png')

export default
@withRouter
@translate()
@inject("accountStore")
@observer
class RadarInfo extends Component {

  @observable items = [];
  @observable loading = true
  @observable error = ''

  componentDidMount(){
    const { match: { params: { tender } } } = this.props;
    getRadarInfo(tender).then((data) => {
      if(data.ok) {
        this.items = data.items
        this.loading = false
      }
    }).catch((err) => {
      this.error = err.message
    })
  }

  render() {
    const {accountStore: {profile}, t} = this.props
    const meta = getMetaData( t("meta.homeTitle"), t("meta.homeDesc"), t("meta.homeKeywords") );

    return (
      <div>
        <DocumentMeta {...meta} />
        <div styleName="radarInfoContainer">
          {
            !profile?.LoggedFromIfat && (
              <ErrorOrNoDataComponent image={unauthorizedImage} infoText={t('radarInfo.unAuthorized')} />
            )
          }
          {
            profile?.LoggedFromIfat && this.error && (
              <ErrorOrNoDataComponent image={badRequest} infoText={t('radarInfo.validTenderID')} />
            )
          }
          { //same title for all items as they result from same infoID.
            this.items.length > 0 && (
              <h1 styleName="itemsHeading" >{t('radarInfo.resultsFound', {count: this.items.length})}: "{this.items[0].Title}"</h1>
            )
          }
          {
            this.items.length > 0 && this.items.map((item, index) => {
              return (
                <div key={index} styleName="itemContainer">
                  <h3 styleName="title">{item?.CustomerName}</h3>
                  <ul styleName="subTitle">
                    <li>
                      <span styleName="label" >{t('radarInfo.userDate')}</span> : <span>{moment(item?.UserDate).format('DD/MM/YYYY')}</span>
                    </li>
                    <li>
                      <span styleName="label" >{t('radarInfo.publisherName')}</span> : <span>{item?.PublisherName}</span>
                    </li>
                    <li>
                      <span styleName="label" >{t('radarInfo.subSubjects')}</span> : <span>{item?.SubSubjects}</span>
                    </li>
                    {
                      item?.PersonName && (
                        <li>
                          <span styleName="label" >{t('radarInfo.personName')}</span> : <span>{item.PersonName}</span>
                        </li>
                      )
                    }
                    {
                      item?.CustomerPhone && (
                        <li>
                          <span styleName="label" >{t('radarInfo.customerPhone')}</span> : <span>{item.CustomerPhone}</span>
                        </li>
                      )
                    }
                    {
                      item?.Cellular && (
                        <li>
                          <span styleName="label" >{t('radarInfo.cellular')}</span> : <span>{item.Cellular}</span>
                        </li>
                      )
                    }
                    {
                      item?.CustomerEmail && (
                        <li>
                          <span styleName="label" >{t('radarInfo.customerEmail')}</span> : <span>{item.CustomerEmail}</span>
                        </li>
                      )
                    }
                    {
                      item?.ContactEmail && (
                        <li>
                          <span styleName="label" >{t('radarInfo.contactEmail')}</span> : <span>{item.ContactEmail}</span>
                        </li>
                      )
                    }
                  </ul>
                </div>
              )
            })
          }
          {
            this.items.length === 0 && !this.error && !this.loading && (
              <ErrorOrNoDataComponent image={noDataFound} infoText={t('radarInfo.noResultFound')} />
            )
          }
        </div>
        <Footer />
      </div>
    )
  }
}

const ErrorOrNoDataComponent = ({image, infoText}) => (
  <>
    <img src={image} alt={infoText} />
    <p>{infoText}</p>
  </>
)

