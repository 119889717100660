import React from "react";
import SlickSlider from "react-slick";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./Slider.scss";

const Arrow = ({ type, onClick }) => {
  const isNextArrow = type === "next";
  return (
    <div
      styleName={`arrow ${isNextArrow ? "next-arrow" : "prev-arrow"}`}
      onClick={onClick}
      id={isNextArrow ? "next-arrow" : "prev-arrow"}
    >
      {isNextArrow ? <RightOutlined /> : <LeftOutlined />}
    </div>
  );
};

const Slider = ({ children, className, configuration = {} }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    rtl: true,
    centerMode: false,
  };
  return (
    <div styleName="slider" className={className} id="slider">
      <SlickSlider {...settings} {...configuration}>
        {children}
      </SlickSlider>
    </div>
  );
};

export default Slider;
