import React, { Component } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { func } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { whenRouted } from "common/utils/withRouteHooks";
import { withRouter } from "react-router";
import { translate } from "react-polyglot";
import { searchStore, recordStore } from "stores";
import SearchInput from "common/components/SearchInput";
import List from "common/components/List";
import Filters from "./Filters/ResultFilter";
import Banners from "./Banners";
import NoData from "components/NoData";
import filter from "lodash/filter";
import DocumentMeta from "react-document-meta";
import { getMetaData } from "common/utils/meta";
import Modal from "../../common/components/Modal";
import SortResults from "./Sort";

import "./results.scss";

export default
@translate()
@inject("searchStore")
@inject("accountStore")
@inject("recordStore")
@inject("routingStore")
@withRouter
@whenRouted(({ params: { sort, tags, filters } }) => {
  searchStore.loadSubSubjects2().then(() => {
    //wait for subsubjects load.
    //when routed from url, if applyTags will not have subsubjects, tags array will be empty and results will not be correct
    searchStore.applySort(sort);
    searchStore.applyTags(tags);
    searchStore.clearFilterLabels();
    searchStore.applyFilters(filters);
    recordStore.cleanChecked();
    //searchStore.clearResults()
    searchStore.fromRoute = true; //raise route flag
    searchStore.loadNextResults();
    searchStore.loadNextFilters();
  });
})
@observer
class Results extends Component {
  static propTypes = {
    onCheck: func,
    onFav: func,
  };

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      showFiltersPopup: false,
    };
  }

  @observable isHomeResults = false;

  componentDidMount() {
    const {
      match: {
        params: { isHome },
      },
    } = this.props;
    //console.log('isHome', isHome)
    this.isHomeResults = isHome;
    if (isHome) {
      //fixTopMenu();
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { isHome },
      },
    } = nextProps;
    //console.log('isHome', isHome)
    this.isHomeResults = isHome;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  getMeta = () => {
    const { searchStore, t } = this.props;
    const tags = filter(searchStore.tags, (tag) => {
      return tag.ResType == "subsubject";
    });

    let metaTitle = t("meta.homeTitle");
    let metaDesc = t("meta.homeDesc");
    let metaKW = t("meta.homeKeywords");
    if (tags.length == 1) {
      const tag = tags[0].Name;
      metaTitle = t("meta.catResultsTitle", { tag });
      metaDesc = t("meta.catResultsDesc", { tag });
      metaKW = t("meta.catKeywords", { tag });
    }
    return getMetaData(metaTitle, metaDesc, metaKW);
  };

  openFiltersPopup = () =>
    this.setState({ ...this.state, showFiltersPopup: true });

  closeFiltersPopup = () =>
    this.setState({ ...this.state, showFiltersPopup: false });

  changeSort = (sort) => {
    const { searchStore, routingStore } = this.props;
    const payload = JSON.stringify(searchStore.tags);
    const filters = JSON.stringify(searchStore.filters);
    searchStore.sort = sort;
    routingStore.push(`/results/${sort}/${payload}/${filters}`, {
      sort: sort,
    });
  };

  render() {
    const {
      accountStore,
      searchStore,
      searchStore: { resultsLoading, resultsCount, tags },
      t,
    } = this.props;
    const { onCheck, onFav } = this.props;
    const {
      recordStore: { checkedItems },
    } = this.props;
    const divStyle = resultsLoading && searchStore.fromRoute ? "loading" : "";
    const meta = this.getMeta();
    const subSubject =
      this.isHomeResults && tags && tags.length > 0 ? tags[0].Name : "";
    const saleText = this.isHomeResults
      ? t("results.saleText", { subSubject })
      : "";

    return (
      <div styleName="container">
        <DocumentMeta {...meta} />
        <div styleName="left">
          <SearchInput tags={toJS(tags)} showAds={true} />
          <div style={{ marginTop: "7rem" }}>
            {/*resultsLoading && <div>Loading...</div>*/}
            {resultsCount == 0 && !resultsLoading && (
              <NoData error={searchStore.searchError} />
            )}
            {resultsCount > 0 && (
              <div styleName={divStyle}>
                {/* <ResultsActions /> */}
                <div styleName="results-header">
                  <div styleName="right-text">
                    {t("results.totalResults", { count: resultsCount })}
                  </div>
                  <SortResults changeSort={this.changeSort} />
                </div>
                <List
                  store={searchStore}
                  loadMore={searchStore.loadNextResults}
                  onCheck={onCheck}
                  onFav={onFav}
                  checkedItems={checkedItems}
                />
              </div>
            )}
            {this.state.width < 768 && <Banners />}
          </div>
        </div>

        <div styleName="right">
          {/* <Title
            store={searchStore}
            initial={searchStore.initialDate}
            isHome={this.isHomeResults}
            preTitle={subSubject}
          /> */}
          <div>
            <Filters />
            <Banners />
            {saleText != "" && <div styleName="promotext">{saleText}</div>}
          </div>
        </div>
        {this.state.width < 768 && (
          <div styleName="bottom-header">
            {/* <div styleName="rightPart">
              {t("results.totalOpportunitiesText", { count: "1,614" })}
            </div> */}
            <div styleName="leftPart">
              <div styleName="circle" onClick={this.openFiltersPopup}>
                <FilterOutlined />
              </div>
              {t("results.filters")}
            </div>
            <div styleName="border-bottom" />
          </div>
        )}
        {this.state.showFiltersPopup && (
          <Modal
            show={this.state.showFiltersPopup}
            title={t("results.filtersPopupTitle")}
            onClose={this.closeFiltersPopup}
          >
            <Filters isMobile={true} onClose={this.closeFiltersPopup} />
          </Modal>
        )}
      </div>
    );
  }
}
