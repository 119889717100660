import React, { Component } from "react";
import Table from "./Table";
import "./ResultsItemDetails.scss";

const Row = ({ label, data, html, dir, align, table,showLabel=true }) => {
  //console.log(label, html, data);
  //note changed the flow due to Can only set one of `children` or `props.dangerouslySetInnerHTML` error: check each seperately,
  //data, html and table will not come all together
  return (
    <div styleName="row">
      {showLabel && <div styleName="item_lable">{label}</div> }
      {html ? (
        <div styleName="item_key" dangerouslySetInnerHTML={html} />
      ) : data ? (
        <div styleName="item_key" style={{width:!showLabel?'100%':""}}>{data}</div>
      ) : table && table.length > 0 ? (
        <div styleName="item_key">
          <div>
            <Table rows={table} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Row;
