import React, { Component } from "react";
import SearchInput from "common/components/SearchInput";
import { bgLeft, bgRight } from "common/style/icons";

/*const req = require.context('common/style/icons/', false)
const bgLeft = req('./bgLeft.png').default
const bgRight = req('./bgRight.png').default*/

import "../home.scss";
import Adsense from "../../../common/components/Adsense/Adsense";

const Hero = ({ t }) => {
  return (
    <section styleName="hero">
      <div styleName="adsense-container-desktop" style={{height: `${0.75*window.innerHeight}px`}} >
        <Adsense
          style={{
            display: "block",
            width: "160px",
            height: "600px",
            // position: "absolute",
            // border:"1px solid black",
            // left: "0",
            // top: "20px",
            // zIndex: "99999999",
          }}
          slot="4313418986"
          googleAdId="ca-pub-7408487462494237"
        />
      </div>
      <img src={bgLeft} styleName="bg-left" />
      <img src={bgRight} styleName="bg-right" />
      <div>
        <div styleName="headlines-container">
          <h1 styleName="hero_txt">
            {t("home.mainTitle")}
            <br /> {t("home.mainTitle2")}!
          </h1>
          <p styleName="sub_head">
            המערכת שלנו מאתרת מתוך אלפי מכרזים את אלו שמתאימים בדיוק בשבילך!
          </p>
        </div>
      </div>
      <div styleName="input-row">
        {/* <div className="column large-9 large-centered"> */}
        <SearchInput showAds={false}/>
        {/* </div> */}
      </div>
      <div styleName="tags">
        <div styleName="tag">מחפשים מכרז</div>
        <div styleName="orange"></div>
        <div styleName="tag">בודקים התאמה</div>
        <div styleName="green"></div>
        <div styleName="tag">מנצלים הזדמנות</div>
      </div>
    </section>
  );
};

export default Hero;
