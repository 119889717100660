import React, {Component} from 'react'
import {inject /*,observer*/} from 'mobx-react'
import {translate} from 'react-polyglot'
//import SearchInput from 'common/components/SearchInput'
import DocumentMeta from 'react-document-meta'
import {getMetaData} from 'common/utils/meta'
import GTAG from 'common/utils/gtag'
import Adsense from "common/components/Adsense/Adsense";
//import CSSModules from 'react-css-modules'
import  './thankYou.scss'

export default 
@translate()
@inject('routingStore')
class ThankYou extends Component {

  componentDidMount() {
    GTAG.sendEvent()
  }

  goToHome = () => {
    const { routingStore: { push } } = this.props
    push('/')   //redirect to home
  }

  render() {
    const {t} = this.props
    const meta = getMetaData(t('meta.homeTitle'), t('meta.homeDesc'), t('meta.homeKeywords'))
    return (
      <div>
        <DocumentMeta {...meta} />       
        <div className="row" styleName='thanks-container' style={{width: '80%', maxWidth: '155rem'}}>
          <div styleName="adsense-container-desktop" style={{height: `${0.75*window.innerHeight}px`}} >
            <Adsense
              style={{
                  display: "block",
                  width: "160px",
                  height: "600px",
                  // position: "absolute",
                  // border:"1px solid black",
                  // left: "0",
                  // top: "20px",
                  // zIndex: "99999999",
              }}
              slot="4313418986"
              googleAdId="ca-pub-7408487462494237"
            />
          </div>
          <div className="column large-12" style={{marginTop: '5rem'}}>
            <div className="thankyou-page">
              <div styleName="wrapper" className="thankyou-page">
                <div styleName="sent">
                  <b>{t('contact.success')}</b><br />
                  <p>{t('contact.willCall')}</p>
                  <button className="left" styleName="button-submit" onClick={this.goToHome}>{t('contact.toHome')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
