import React, {Component} from 'react'
import LoginComponent from 'components/Login'

export default class Login extends Component {

  render(){
    return <div>
      <LoginComponent />
    </div>
  }
}
