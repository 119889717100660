import React, {Component} from 'react'
import { translate } from 'react-polyglot';
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import {clearCache} from 'common/services/apiService'
import moment from 'moment'
import LoginDialog from 'common/components/LoginDialog'

import  './login.scss'

export default
@translate()
@withRouter
@inject('accountStore')
@inject('routingStore')
@observer
class Login extends Component {

  //@observable itemID = -1
  @observable isLoginDialogOpened = false
  //@observable isOtpPending = false  // Consider adding a timer that will pop the login dialog again, thus triggerring otp (if user has closed) 
  @observable showNotMe = false

  componentDidMount() {
    const { accountStore } = this.props
    if (accountStore.profile) {
      accountStore.logout().then(() => {
        this.login()
      })
    }
    else {
      this.login()
    }
  }

  login = () => {
    const { accountStore, match: {params: { token, tender }}, routingStore: { push } } = this.props    
    accountStore.tokenLogin(decodeURIComponent(token)).then(res => {
      if (accountStore.error == null && accountStore.profile != null) {
        //successful login made
        localStorage.setItem('tenders_NeedOtp', res.NeedOtp)
        //console.log('tender', tender)
        if (accountStore.profile.LoggedFromIfat) {
          //if login was successful, set here the last otp also, to localStorage
          //(avoid logging out loop when otp is not needed e.g. from Ifat)
          const lastOtp = moment().format('YYYY-MM-DD HH:mm:ss')
          localStorage.setItem('tenders_lastOtpDate', lastOtp)
        }
        clearCache()
        //fixTopMenu()
        accountStore.checkIfatUser()
        if (!tender) {
          push('/main')
        }
        else {
          push(`/tender/${tender}`)
        }
      }
      else if (accountStore.error == null && accountStore.otpStatus?.ok) {
        //this.isOtpPending = true  //set flag so timer will not continue sending sms messages
        this.isLoginDialogOpened = true
      }
    }).catch(error => {
      console.error('[Login] Error:', error)
      //notifyMessage(error)
    })
  }

  continueUnlogged = () => {
    this.isLoginDialogOpened = false
    //this.isOtpPending = false
  }

  render() {
    const { accountStore, t } = this.props
    return (
      <div>
        {
          accountStore.error != null && <div styleName="error">{accountStore.error}</div>
        }
        {this.isLoginDialogOpened && <LoginDialog onCancel={this.continueUnlogged} isOtp={true} />}
        <div styleName="login-message">{t('login.pleaseLogin')}</div>
      </div>
    )
  }
}
