import React, {Component} from 'react'
import SearchComponent from 'components/Search'
////import CSSModules from 'react-css-modules'
//import  './Search.scss'

//
export default class Search extends Component {


  //componentWillMount() {}
  //componentWillReceiveProps = (nextProps, nextState) => {};

  render(){
    return <div><SearchComponent/></div>
  }
}
