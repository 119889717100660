import React from "react";
import { number, string, func, bool } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import { Link } from 'react-router-dom'
import {
  DownloadOutlined,
  HeartOutlined,
  BellOutlined,
  MailOutlined,
  PrinterOutlined,
  PrinterFilled,
  HeartFilled,
  BellFilled,
} from "@ant-design/icons";
import { setDateLabel, isDateInRange } from "common/utils/item";
import {
  createUrl,
  getEmailData,
  clearCache,
} from "common/services/apiService";
import moment from "moment";
//import ImageView from 'common/components/ImageView'
import Row from "./Row";
import Reminder from "common/components/Reminder";
import Feedback from "./Feedback";
import Loading from "common/components/Loading/Loading";
import Table from "./Table";
import Button from "common/components/Button";
import { post } from "common/style/icons";
import {randomNumber} from 'common/utils/util'
import ReactTooltip from "react-tooltip";

import "./ResultsItemDetails.scss";

//const req = require.context('common/style/icons/', false)
/*const thumbSrc = req('./preview.svg').default
const docSrc = req('./doc.svg').default
const printSrc = req('./print_gray.svg').default
const mailSrc = req('./mail_gray.svg').default
const alertSrc = req('./alert.svg').default
const alertActSrc = req('./alert_on.svg').default
const favSrc = req('./fav.svg').default
const favActSrc = req('./action_fav.svg').default*/

export default
@translate()
@inject("accountStore")
@inject("itemStore")
@inject("routingStore")
@observer
class ResultsItemDetails extends React.Component {
  static propTypes = {
    itemID: number,
    encryptedID: string,
    onClose: func,
    showViewer: func,
    setReminderData: func,
    mode: string,
    onFav: func,
    isEmail: bool,
  };

  @observable itemID = -1;
  @observable loadError = false;
  @observable remindMe = false;
  @observable IsFavorite = false;
  @observable reminderID = null;
  @observable newReminderDate = "";
  @observable currentToken = ''

  componentDidMount() {
    this.loadItem(this.props);
    this.currentToken = this.props.accountStore.profile.Token
  }

  componentWillReceiveProps(nextProps, nextState) {
    // Fix: do not loadItem again if only the token changed (resulted by Me() call) - avoid uneeded calls to loadItem.
    const tokenChange = (this.currentToken !== nextProps.accountStore.profile.Token)
    //console.log('currentToken', this.currentToken);
    //console.log('newToken', nextProps.accountStore.profile.Token);
    this.currentToken = nextProps.accountStore.profile.Token
    if (!tokenChange) {
      this.loadItem(nextProps);
    }
  }

  loadItem = (props) => {
    const { itemStore, encryptedID, mode, isEmail } = props;
    itemStore
      .loadTender(decodeURIComponent(encryptedID), mode, isEmail)
      .then(() => {
        if (!itemStore.item.TenderID) {
          //something went wrong
          this.loadError = true;
        } else {
          this.itemID = itemStore.item.TenderID;
          this.IsFavorite = itemStore.item.IsFavorite || false;
          this.reminderID = itemStore.item.ReminderID || null ;
        }
      })
      .catch((error) => {
        console.error("[loadTender] Error:", error);
        this.loadError = true;
      });
  };

  email = () => {
    const { t } = this.props;
    const item = [this.itemID];
    getEmailData(item).then((res) => {
      //console.log('email', uid)
      location.href = `mailto:someone@email.com?subject=${t(
        "toolbar.emailSubject"
      )}&body=${encodeURIComponent(t("toolbar.emailBody", {uid: res.uid}))}`;
    });
  };

  print = (isBig) => {
    const item = [this.itemID];
    const exportType = isBig ? 2 : 1;
    window.open(
      createUrl(
        "Export/ExportData",
        {
          ExportType: exportType,
          InfoList: item,
        },
        false
      ),
      "_blank"
    );
  };

  fav = () => {
    const {
      itemStore: { item },
      onFav,
    } = this.props;
    if (onFav) {
      onFav(item.TenderID, !this.IsFavorite);
      clearCache();
      this.IsFavorite = !this.IsFavorite;
      //console.log('added', this.IsFavorite)
    }
  };

  remind = (open) => {
    this.remindMe = open;
  };

  setReminderData = (id, date) => {
    //when reminder data changes (created\updated\deleted),
    //need to update the date label and current reminderID
    const { setReminderData } = this.props;
    this.reminderID = id;
    this.newReminderDate = date;
    if (setReminderData) setReminderData(id, date);
  };

  formatText = (text) => {
    /* <a> tag fix for text */
    const { t } = this.props;
    if (text) {
      let title = "$&"; //regexp default
      const arr = text.split("##URL##");
      if (arr.length > 1 && arr[1] != "") {
        //if originalUrl has passed to this method, need to set it here
        //const link = arr[1].split("[[SEP]]"); //it is built as ID[[SEP]]Title
        //title = link[1]; //set the title
        //concat the url as is (regexp will fix it to be a link)
        //text = `${arr[0]}<br />${t(
        //  "tender.originalTitle"
        //)}<br />http://www.tenders.co.il/tender/${link[0]}`;
        
        //change 05/22
        title = t('tender.clickHere')
        text = `${arr[0]}https://www.tenders.co.il/tender/${arr[1]}`;
      }

      //with http
      let fixedText = text.replace(
        /((https|http):\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        `<a target="_blank" href="\$&">${title}</a>`
      );

      //without http - not working (non-http links)
      //fixedText = fixedText.replace(/(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      //  `<a target="_blank" href="http://\$&">${title}</a>`)

      //mailto
      fixedText = fixedText.replace(
        /([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)/,
        '<a href="mailto:$&">$&</a>'
      );

      //tel
      fixedText = fixedText.replace(
        /0((5[012345678]){1}|[23456789]{1}|(7[5678]){1})(\-)?[^0\D]{1}\d{6}/,
        '<a href="tel:$&">$&</a>'
      );

      return { __html: fixedText };
    } else {
      return { __html: "" };
    }
  };

  htmlDirection = (text, type) => {
    const filter = /[א-ת]/gi;
    if (!filter.test(text)) return type == "dir" ? "ltr" : "left";
    else return type == "dir" ? "rtl" : "right";
  };

  goTo = (link) => {
    window.open(link, "_blank");
  };

  render() {
    const { itemStore, encryptedID, showViewer, onClose, t, accountStore } = this.props;
    const item = toJS(itemStore.item);
    const { profile } = accountStore
    const LoggedFromIfat = profile?.LoggedFromIfat
    //console.log('render', this.IsFavorite)
    //for display
    const publishDate = setDateLabel(
      item.PublishDate,
      "DD/MM/YYYY",
      t("tender.noDate")
    );
    const infoDateChk = moment(item.InfoDate);
    const format =
      infoDateChk.hour() == 0 && infoDateChk.minute() == 0
        ? "DD/MM/YYYY"
        : "DD/MM/YYYY HH:mm";
    const infoDate = setDateLabel(item.InfoDate, format, t("tender.noDate"));
    const titleDir = this.htmlDirection(item.Title, "dir");
    const titleStyle =
      titleDir == "ltr" ? "item_title title_left" : "item_title";
    //
    //infoDate
    const twoDaysLeft = isDateInRange(item.InfoDate, 2);
    const oneDayLeft = isDateInRange(item.InfoDate, 1);
    const noDaysLeft = isDateInRange(item.InfoDate, 0);
    //tourDate
    const twoDaysLeftTour = isDateInRange(item.TourDate, 2);
    const oneDayLeftTour = isDateInRange(item.TourDate, 1);
    const tourToday = isDateInRange(item.TourDate, 0);
    const mustDoTourLabel =
      (twoDaysLeftTour || oneDayLeftTour || tourToday) && item.MustDoTour
        ? ` - ${t("tender.mustTour")}`
        : "";
    //fileName
    const fileName = item.File ? item.File.FileName : "";
    //publisher site
    let publisherSite =
      item.PublisherSite && item.PublisherSite.trim() != ""
        ? item.PublisherSite
        : "";
    if (publisherSite != "" && publisherSite.substring(0, 4) != "http")
      publisherSite = `http://${publisherSite}`;
    //original tender
    //const originalUrl = item.OriginalID
    //  ? `##URL##${item.OriginalID}[[SEP]]${item.OriginalTitle}`
    //  : "";
    const originalUrl = item.OriginalID
      ? `##URL##${item.OriginalID}`
      : "";
    const comment =
      item.Comment && item.Comment.trim() != ""
        ? item.Comment
        : item.OriginalID
        ? " "
        : null; //if comment is null, original tender will not be printed out
    const commentFix = comment == " " ? "א" : comment; //for htmlDirection
    //for scroll pos of item
    const divTop = document.documentElement.scrollTop
      ? document.documentElement.scrollTop
      : document.body.scrollTop;


    return (
      <div>
        {!itemStore.resultsLoading && !this.loadError && (
          <div styleName="view-details-wrapper" style={{ top: divTop + 10 }}>
            <div>
              <div>
                {item.TenderType == t("tender.exclusive") && (
                  <span styleName="label" className="label">
                    {t("tender.exclusive")}
                  </span>
                )}
                {twoDaysLeft && !oneDayLeft && !noDaysLeft && (
                  <span styleName="label alert">{t("tender.twoDaysLeft")}</span>
                )}
                {oneDayLeft && !noDaysLeft && (
                  <span styleName="label alert">{t("tender.oneDayLeft")}</span>
                )}
                {noDaysLeft && (
                  <span styleName="label alert">{t("tender.noDaysLeft")}</span>
                )}
                {twoDaysLeftTour && !oneDayLeftTour && !tourToday && (
                  <span styleName="label alert">{`${t(
                    "tender.twoDaysLeftTour"
                  )}${mustDoTourLabel}`}</span>
                )}
                {oneDayLeftTour && !tourToday && (
                  <span styleName="label alert">{`${t(
                    "tender.oneDayLeftTour"
                  )}${mustDoTourLabel}`}</span>
                )}
                {tourToday && (
                  <span styleName="label alert">{`${t(
                    "tender.noDaysLeftTour"
                  )}${mustDoTourLabel}`}</span>
                )}
                {item.MustDoTour &&
                  !twoDaysLeftTour &&
                  !oneDayLeftTour &&
                  !tourToday && (
                    <span styleName="label alert">
                      {t("tender.mustDoTour")}
                    </span>
                  )}
                <h1 styleName={titleStyle}>{item.Title}</h1>
                {
                  <h6 styleName="item_meta">
                    {t("tender.publishedAt")}: {publishDate}{" "}
                    {/*&middot; {item.TenderType}*/} &middot; {item.InfoCode}
                  </h6> /* TODO needs design */
                }
              </div>
            </div>
            <div styleName="details-row">
              <div styleName="col">
                <div styleName="title">{t("tender.publisherTitle")}</div>
                <Row label={t("tender.publisher")} data={item.Publisher} />
                {publisherSite != "" && (
                  <Row
                    label={t("tender.publisherSite")}
                    html={this.formatText(publisherSite)}
                    dir={this.htmlDirection(publisherSite, "dir")}
                  />
                )}
                {item.InfoDate && (
                  <Row label={t("tender.delivery")} data={infoDate} dir="ltr" />
                )}
                {item.PresentationPlace &&
                  item.PresentationPlace.trim() != "" && (
                    <Row
                      label={t("tender.presentationPlace")}
                      html={this.formatText(item.PresentationPlace)}
                      dir={this.htmlDirection(item.PresentationPlace, "dir")}
                      align={this.htmlDirection(
                        item.PresentationPlace,
                        "align"
                      )}
                    />
                  )}
              </div>
              <div styleName="col">
                <div styleName="title">{t("tender.extraData")}</div>
                {item.TenSubjects && item.TenSubjects.trim() != "" && (
                  <Row
                    label={t("tender.subjects")}
                    data={item.TenSubjects}
                  />
                )}
                {item.TenSubSubjects && item.TenSubSubjects.trim() != "" && (
                  <Row
                    label={t("tender.subSubjects")}
                    data={item.TenSubSubjects}
                  />
                )}
                {<Row label={t("tender.tenderType")} data={item.TenderType} />}
                {item.OriginalID && <Row label={t("tender.originalTitle")} html={this.formatText(`${comment}${originalUrl}`)} />}
              </div>
            </div>
            {item.TourDetails && item.TourDetails.trim() != "" && (
              <>
                <div styleName="title mt3">{t("tender.tourDetails")}</div>
                <div styleName="summary">{item.TourDetails}</div>
              </>
            )}
            {/* <div styleName="line" /> */}
            {item.TenderConditions && item.TenderConditions.trim() != "" && (
              <>
                <div styleName="title mt3">{t("tender.tenderConditions")}</div>
                <div styleName="summary">{item.TenderConditions}</div>
              </>
            )}
            {/* <div styleName="line" /> */}

            {item.Summery && item.Summery.trim() != "" && (
              <>
                <div styleName="title mt3 sm-mt2">{t("tender.details")}</div>
                <div
                  styleName="summary"
                  dangerouslySetInnerHTML={this.formatText(item.Summery)}
                ></div>
                {item.GT && item.GT.length > 0 && (
                  <div>
                    <Table rows={item.GT} />
                  </div>
                )}
              </>
            )}

            {/* <div styleName="line" /> */}
            {item.TD && item.TD.length > 0 && (
              <div style={{ marginTop: "2rem" }}>
                <div styleName="title mt3">{t("tender.links")}</div>
                {item.TD.map((link, index) => (
                  <div key={index}>
                    <div className="grid-x">
                      <div className="medium-12 cell">
                        <div styleName="item_key">
                          <a href={link.DocumentLink} target="_blank">
                            {link.DocumentName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {comment && comment.length > 0 && comment.trim() != "" && (
              <>
                <div styleName="title">{t("tender.extraData")}</div>
                <Row
                  label={t("tender.commentLabel")}
                  html={this.formatText(`${comment}${originalUrl}`)}
                  dir={this.htmlDirection(commentFix, "dir")}
                  align={this.htmlDirection(commentFix, "align")}
                  showLabel={false}
                />
              </>
            )}
            {/* <div styleName="continue-reading">המשך קריאה</div>  */}
            <div styleName="button-outer-container">
              <div styleName="button-inner-container">
                {fileName != "" && (
                  <Button
                    onClick={() => showViewer(fileName, item.Title)}
                    text={t("tender.viewImage")}
                    image={<img src={post} alt="post" styleName="post" />}
                  />
                )}
                {item.TenderLink && (
                  <Button
                    onClick={() => this.goTo(item.TenderLink)}
                    text={t("tender.toTenderDetails")}
                    icon={<DownloadOutlined />}
                  />
                )}
              </div>
              {
                LoggedFromIfat &&
                <Link to={`/radar/info/${this.props.encryptedID}`} target="_blank" rel="noreferrer noopener">{t("tender.openRadarInfo")}</Link>
              }
            </div>
            <div styleName="line" />
            <div styleName="action-container">
              {item.TenderType == t("tender.tenderPublicLabel") && (
                <Button
                  text={t("tender.radarNew")}
                  overrideClassName="personal-info-btn"
                  style={{ fontFamily: "Atlas Bold" }}
                  onClick={() => this.goTo(`/radar/${encryptedID}`)}
                />
              )}

              <div styleName="icon-container">
                <div styleName="icon heart">
                  <a onClick={this.fav}>
                    {!this.IsFavorite ? (
                      <HeartOutlined styleName="ant-icon" />
                    ) : (
                      <HeartFilled styleName="ant-icon" />
                    )}
                  </a>
                  {/* <HeartOutlined onClick={this.fav} />{" "} */}
                  {/* TODO no sign that fav is done or not */}
                </div>
                <div styleName="icon bell">
                  {" "}                  
                  <a onClick={() => this.remind(true)}>
                    {this.reminderID ? (
                      <BellFilled styleName="ant-icon" />
                    ) : (
                      <BellOutlined styleName="ant-icon" />
                    )}
                  </a>
                </div>
                <div
                  styleName="icon mail"
                  onClick={() => this.print(false)}
                  data-tip={t("toolbar.printTooltip")}>
                  <PrinterOutlined />
                </div>
                <ReactTooltip />
                {fileName !== "" && 
                  <>
                    <div
                      styleName="icon mail"
                      onClick={() => this.print(true)}
                      data-tip={t("toolbar.printBigTooltip")}
                    >
                      <PrinterFilled />
                    </div>
                    <ReactTooltip />
                  </>
                }
                <div styleName="icon mail" onClick={this.email}>
                  <MailOutlined />
                </div>
              </div>
            </div>
            <div>
              {item.InfoButtons?.length > 0 && 
                <>
                  <div styleName="line" />
                  {/*<p styleName="info-buttonsContainerTitle">{t("toolbar.infoButtonsTitle")}</p>*/}
                  <div styleName="info-buttonContainer" style={{marginTop: '3.5rem'}}>
                  {item.InfoButtons.map((button,index) => 
                    {
                      const installedProductID = accountStore.profile ? accountStore.profile.ID : -1
                      const cache = randomNumber(100000, 1000000);
                      //console.log(`${button.Url.replace(/xINSTx/g, installedProductID)}&cache=${cache}`);
                      return <Button
                        key={index}
                        dataTip="" //{button.Title}
                        text={button?.Text.length>0 ? button.Text:button.Title}
                        overrideClassName="info-btn"
                        onClick={() => this.goTo(`${button.Url.replace(/xINSTx/g, installedProductID)}&cache=${cache}`)}
                      />
                    }
                    )}
                  </div>
                </>
              }
            </div>
            <Feedback feedback={item.Feedback} />
          </div>
        )}
        {this.loadError && (
          <div>
            <div styleName="errors">
              {itemStore.searchError.statusCode == 401
                ? t("login.subscribeTitle")
                : t("tender.errors")}
            </div>
            {itemStore.searchError.statusCode == 401 && (
              <div styleName="errors-sub">
                {t("tender.subscribeText1")}
                <br />
                {t("tender.subscribeText2")}
                <br />
                <br />
                <Link to="/contact/" target="_blank" styleName="contact-link">
                  {t("tender.contact")}
                </Link>
              </div>
            )}
          </div>
        )}
        {itemStore.resultsLoading && <Loading />}
        {this.remindMe && (
          <Reminder
            tenderID={item.TenderID}
            encryptedID={encryptedID}
            onClose={() => this.remind(false)}
            setReminderData={this.setReminderData}
            title={item.Title}
            infoDate={item.InfoDate}
            reminderID={this.reminderID}
          />
        )}
      </div>
    );
  }
}
