import React from "react";
import ReactTooltip from "react-tooltip";
import "./button.scss";

const Button = ({
  onClick,
  icon,
  image,
  text,
  overrideClassName = "",
  style = {},
  dataTip=""
}) => {
  return (
    <>
      <div
        styleName="button"
        style={style}
        onClick={onClick}
        className={overrideClassName}
        data-tip={dataTip}
      >
        {icon && <div styleName="icon">{icon}</div>}
        {image ?? null}
        {text}
      </div>
      {dataTip.length>0 && <ReactTooltip/>}
    </>
  );
};

export default Button;
