import React, {Component} from 'react'
import {translate} from 'react-polyglot'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { observable, toJS } from 'mobx'
import {getMetaData} from 'common/utils/meta'
import { checkEmail } from 'common/utils/validation'
import { sendFeedback, contactUs } from 'common/services/apiService'
import { Input } from 'antd'
import DocumentMeta from 'react-document-meta'
import Question from './Question'

import './feedback.scss'

const req = require.context('common/style/icons', false)
const zoomPro = req('./zoomPro.png')
const zoomLogo = req('./zoomLogo.png')
const iconEmail = req('./iconEmail.png')
export default 
@translate()
@withRouter
@observer
class Feedback extends Component {
    
    @observable answers = {
        encId: '',
        question1: '',
        answer1: true,
        question2: '',
        answer2: true,
        question3: '',
        answer3: true,
        answer3Ex: '',
        email: ''
    }
    @observable textEx = ''
    @observable email = ''
    @observable showExDiv = false
    @observable sent = false
    @observable sentRegister = false
    @observable errors = ''
    @observable isEmailValid = true
    @observable isPending = false

    componentDidMount() {
        const { match: { params: { id } }, t} = this.props
        this.answers.encId = id
        this.answers.question1 = t('feedback.question1')
        this.answers.question2 = t('feedback.question2')
        this.answers.question3 = t('feedback.question3')

        setTimeout(() => {
            //remove the side scroll;
           document.body.style.height = 'auto'; 
        }, 50);
    }

    setAnswer = (index, answer) => {
        this.answers[`answer${index}`] = answer
        //console.log(toJS(this.answers));
        if (index === 3) {
            this.showExDiv = !answer
        }
    }

    onTextChange = (e) => {
        this.textEx = e.target.value
    }

    onEmailChange = (e) => {
        this.email = e.target.value
    }

    sendFeedback = () => {
        const {t} = this.props
        this.sent = false
        this.errors = ''
        if (!this.answers.q3) {
            this.answers.answer3Ex = this.textEx
        }
        this.isEmailValid = true
        if (!checkEmail(this.email, true)) {
            this.isEmailValid = false
            return
        }
        this.answers.email = this.email
        this.isPending = true
        sendFeedback(toJS(this.answers)).then(res => {
            this.isPending = false
            this.sent = true
            console.log('success', res);
        }).catch(err => {
            this.isPending = false
            this.sent = true
            this.errors = t('feedback.errors')
            console.log('error', err);
        })
    }

    /*sendRegistration = () => {
        const {t} = this.props
        this.isEmailValid = true
        if (!checkEmail(this.email)) {
            this.isEmailValid = false
            return
        }
        contactUs('', this.email, '', t('feedback.registration'), t('feedback.registration'), 'registration').then(res => {
            //show a message
            console.log('success', res);
            this.sentRegister = true
        })
    }*/

    render() {
        const {t} = this.props
        const pageName = t('meta.feedback')
        const meta = getMetaData(t('meta.pageTitle', {pageName}), t('meta.pageDesc', {pageName}), t('meta.pageKW', {pageName}))
        const { TextArea } = Input

        return (
            <div style={{backgroundColor: '#fff'}}>
                <DocumentMeta {...meta} />
                <div className="row" styleName='card-container' style={{maxWidth: '96%'}}>
                    <div className="column large-12 small-12">
                        <div styleName="wrapper">
                            <div styleName="contact">
                                <a href="mailto: boaz_a@ifat.com" style={{color: '#212129', textDecoration: 'underline'}}>boaz_a@ifat.com</a> | 
                                <div onClick={() => window.open('https://bit.ly/3NGu9AO', '_blank')} style={{cursor: 'pointer', display: 'flex'}}>
                                    <div><i className="fa fa-whatsapp" aria-hidden="true"></i></div> 
                                    <div style={{textDecoration: 'underline', paddingRight: '0.5rem'}}>03-5635060</div>
                                </div>
                            </div>
                            <div styleName="title-container">
                                <h1 styleName="title">{t('feedback.title')} {t('feedback.subTitle')}</h1>
                            </div>
                            <div styleName="subtitle-container">
                                <h2 styleName="subtitle">{t('feedback.secSubTitle')}</h2>
                            </div>
                            <div styleName="questions-container">
                                <div styleName="questions">
                                    <h3 styleName="questions-title">{t('feedback.questions')}</h3>
                                    <hr styleName="divider" style={{marginTop: '3rem'}}/>
                                    <Question 
                                        index={1}
                                        question={t('feedback.question1')}
                                        setAnswer={this.setAnswer}
                                        t={t}
                                    />
                                    <hr styleName="divider" style={{marginTop: '2rem'}}/>
                                    <Question 
                                        index={2}
                                        question={t('feedback.question2')}
                                        setAnswer={this.setAnswer}
                                        t={t}
                                    />
                                    <hr styleName="divider" style={{marginTop: '2rem'}}/>
                                    <Question 
                                        index={3}
                                        question={t('feedback.question3')}
                                        setAnswer={this.setAnswer}
                                        t={t}
                                    />
                                    <hr styleName="divider" style={{marginTop: '2rem'}}/>
                                    {this.showExDiv && <div>
                                        <div styleName="question" style={{marginBottom: '1rem'}}>{t('feedback.question3Ex')}</div>
                                        <TextArea 
                                            rows={2} 
                                            placeholder={t('feedback.maxLength')} 
                                            maxLength={200} 
                                            style={{resize: 'none'}}
                                            showCount={true} 
                                            onChange={this.onTextChange}
                                        />
                                        <hr styleName="divider" style={{marginTop: '3.5rem'}}/>
                                    </div>}
                                    <div styleName="pros-container">
                                        <div styleName="pros-img">
                                            <img src={zoomPro} style={{width: 150, height: 195}} />
                                        </div>
                                        <div>
                                            <div styleName="pros-title">
                                                {t('feedback.prosTips')}
                                            </div>
                                            <div styleName="pros-subtitle">
                                                {t('feedback.prosSub1')}
                                                <img src={zoomLogo} styleName="zoom-logo" />
                                                {t('feedback.prosSub2')}
                                            </div>                                            
                                            <div styleName="pros-email">
                                                <div styleName="email-image">
                                                    <img src={iconEmail} />
                                                </div>
                                                <div styleName="email-divider">
                                                <hr styleName="divider" style={{height: 45, width: 1, backgroundColor: '#99A3AD'}} />
                                                </div>
                                                <Input 
                                                    placeholder={t('feedback.emailPlaceholder')}
                                                    styleName="email-input"
                                                    onChange={this.onEmailChange}
                                                />
                                            </div>
                                            {!this.isEmailValid &&
                                            <div style={{width:'80%', display: 'flex'}}>
                                                <div styleName="email-error">{t('feedback.mailNotValid')}</div>
                                            </div>}
                                        </div>
                                    </div>
                                    <hr styleName="divider" style={{marginTop: '3.5rem'}}/>
                                    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                        {!this.isPending ? <div styleName="submit-button" onClick={this.sendFeedback}>
                                            {t('feedback.submit')}
                                        </div> : <div styleName="submit-pending">{t('feedback.pleaseWait')}</div>}
                                    </div>
                                    <div style={{marginTop: '3rem', marginBottom: '3rem'}}>
                                        {this.sent ? 
                                            this.errors === '' ? 
                                            <div styleName="success">{t('feedback.success')}</div> : 
                                            <div styleName="errors">{this.errors}</div> 
                                            : 
                                            <></>}
                                    </div>
                                </div>
                            </div>    
                            <div styleName="mobile-footer">
                                <a href="mailto: boaz_a@ifat.com" style={{color: '#212129', textDecoration: 'underline', fontSize: '2rem'}}>boaz_a@ifat.com</a> | 
                                <div onClick={() => window.open('https://bit.ly/3NGu9AO', '_blank')} style={{cursor: 'pointer', display: 'flex'}}>
                                    <div><i className="fa fa-whatsapp" aria-hidden="true"></i></div> 
                                    <div style={{textDecoration: 'underline', paddingRight: '0.5rem'}}>03-5635060</div>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}