import React, { useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./modal.scss";

const Modal = ({
  title,
  show,
  onClose,
  children,
  modalBodyOverrideStyle = {},
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [show]);

  return (
    <>
      {show ? (
        <div styleName="modal">
          <div styleName="modal-content">
            <div styleName="modal-header">
              {title}
              <ArrowLeftOutlined onClick={onClose} />
            </div>
            <div styleName="modal-content" style={modalBodyOverrideStyle}>
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
