import { action, computed, observable, toJS } from "mobx";
import {
  getMainSubjects,
  getAllSubjects,
  getSampleTenders,
  getLastWinners,
} from "common/services/apiService";

class Home {
  @observable resultsLoading = false;
  @observable request = {};
  @observable requestSamp = {};
  @observable catResults = [];
  @observable subCatResults = [];
  @observable sampleTenders = [];
  @observable lastWinners = [];
  // @observable sampleTenders = [
  //   {
  //     releaseDate: "2022-03-31T12:33:26.81",
  //     subsubjectName: "בניה",
  //     title: "פריצת דרכים, עבודות עפר, מערכות רטובות, קירות תמך וסלילה חלקית",
  //     infoId: 11336205,
  //   },
  //   {
  //     releaseDate: "2022-03-31T13:06:38.233",
  //     subsubjectName: "מחשבים",
  //     title:
  //       'מתן שירותי תכנון, הקמה ותחזוקה של מופעי מולטימדיה ומערכת בקרה ב"מתחם הרכס" וברחבי העיר ירושלים.\r\n',
  //     infoId: 11336215,
  //   },
  //   {
  //     releaseDate: "2022-03-31T13:05:42.28",
  //     subsubjectName: "ייעוץ/ניהול",
  //     title:
  //       "לאיתור ספק לכתיבת והפעלת תוכנית פיתוח יזמות לצוותי הוראה בירושלים. \r\n",
  //     infoId: 11336720,
  //   },
  //   {
  //     releaseDate: "2022-03-31T12:46:02.223",
  //     subsubjectName: "נכס",
  //     title: "ההצעות להפעלת סוכנויות דואר באזור הצפון. \r\n",
  //     infoId: 11336748,
  //   },
  //   {
  //     releaseDate: "2022-03-31T14:30:58.863",
  //     subsubjectName: "כח אדם",
  //     title: "מנהל/ת מחלקת תכנון עיר ובודק/ת תוכניות.\r\n",
  //     infoId: 11337092,
  //   },
  // ];
  // @observable lastWinners = [
  //   {
  //     InfoID: 11349028,
  //     Publisher: "המרכז הרפואי ברזילי אשקלון",
  //     Title: "החלפת גגון מעל כביש הגישה לבניין מיון ישן.\r\n",
  //     Summery:
  //       "במכרז שפירסם המרכז הרפואי ברזילי אשקלון בנושא החלפת גגון מעל כביש הגישה לבניין מיון ישן.\r\nזוכה: א.חן טל, טל': 08-6746661.\r\n",
  //     Winner: "א.חן טל",
  //   },
  //   {
  //     InfoID: 11349025,
  //     Publisher: "המרכז הרפואי ברזילי אשקלון",
  //     Title: "פרויקט שיפוץ ובינוי אגף חדש של בניין פגייה.\r\n",
  //     Summery:
  //       "במכרז שפירס המרכז הרפואי ברזילי אשקלון בנושא פרויקט שיפוץ ובינוי אגף חדש של בניין פגייה.\r\nזוכה: דנזיו בניין ופיתוח בע\"מ, טל': 08-6723229.\r\n",
  //     Winner: 'דנזיו בניין ופיתוח בע"מ',
  //   },
  //   {
  //     InfoID: 11349017,
  //     Publisher: "המרכז הרפואי ברזילי אשקלון",
  //     Title: "ניהול ותפעול חנות פארם במתחם המסחרי במרכז הרפואי. \r\n",
  //     Summery:
  //       "במכרז שפירסם המרכז הרפואי ברזילי אשקלון בנושא ניהול ותפעול חנות פארם במתחם המסחרי במרכז הרפואי. \r\nזוכה: חברת פאמרדיל, טל': 050-3696981\r\n",
  //     Winner: "חברת פאמרדיל",
  //   },
  //   {
  //     InfoID: 11349016,
  //     Publisher: "המרכז הרפואי ברזילי אשקלון",
  //     Title: "מכרז לאספקת מעטפות במרכז הרפואי.\r\n",
  //     Summery:
  //       "במכרז שפירסם המרכז הרפואי ברזילי אשקלון בנושא מכרז לאספקת מעטפות במרכז הרפואי.\r\nזוכה: גברעם מעטפות, טל': 08-6770418.\r\n",
  //     Winner: "גברעם מעטפות",
  //   },
  //   {
  //     InfoID: 11349012,
  //     Publisher: "המרכז הרפואי ברזילי אשקלון",
  //     Title: "רכישת ניידת שיקופים דיגיטאלית עבור חדר ניתוח.\r\n",
  //     Summery:
  //       "במכרז שפירסם המרכז הרפואי ברזילי אשקלון בנושא רכישת ניידת שיקופים דיגיטאלית עבור חדר ניתוח.\r\nזוכה: חברת בסטק בע\"מ, טל': 03-9616714.\r\n",
  //     Winner: 'חברת בסטק בע"מ',
  //   },
  // ];
  @observable resultsCount = 0;

  @action.bound
  async loadCatResults() {
    if (!this.resultsLoading) {
      this.resultsLoading = true;
      let error = null; //if needed, make an observable
      try {
        this.request = await getMainSubjects();
      } catch (e) {
        //an error occured on search
        console.error(
          `[loadCatResults] search error: ${e.message} http status code ${e.error.status}`
        );
        error = e.message;
      }

      if (error == null) {
        const data = this.request;
        this.catResults = [...data.map((d) => ({ ...d, key: d.subsubjectId }))];
        //console.log(toJS(this.catResults))
      } else {
        this.catResults = [];
      }
      this.resultsLoading = false;
    }
  }

  @action.bound
  async loadSubCatResults() {
    if (!this.resultsLoading) {
      this.resultsLoading = true;
      let error = null; //if needed, make an observable
      try {
        this.request = await getAllSubjects();
      } catch (e) {
        //an error occured on search
        console.error(
          `[loadSubCatResults] search error: ${e.message} http status code ${e.error.status}`
        );
        error = e.message;
      }

      if (error == null) {
        const data = this.request;
        this.subCatResults = [
          ...data.map((d) => ({ ...d, key: d.subsubjectId })),
        ];
      } else {
        this.subCatResults = [];
      }
      this.resultsLoading = false;
    }
  }

  @action.bound
  async loadSampleTenders() {
    let error = null; //if needed, make an observable
    try {
      this.requestSamp = await getSampleTenders();
    } catch (e) {
      //an error occured on search
      console.error(
        `[loadSampleTenders] search error: ${e.message} http status code ${e.error.status}`
      );
      error = e.message;
    }

    if (error == null) {
      const data = this.requestSamp;
      this.sampleTenders = [...data.map((d) => ({ ...d, key: d.infoId }))];
    } else {
      this.sampleTenders = [];
    }
  }

  @action.bound
  async loadWinneres() {
    let error = null; //if needed, make an observable
    try {
      this.requestSamp = await getLastWinners();
    } catch (e) {
      //an error occured on search
      console.error(
        `[loadlastwinners] search error: ${e.message} http status code ${e.error.status}`
      );
      error = e.message;
    }

    if (error == null) {
      const data = this.requestSamp;
      this.lastWinners = [...data.map((d) => ({ ...d, key: d.infoId }))];
    } else {
      this.lastWinners = [];
    }
  }
}

export const homeStore = new Home();
