import { clearCache } from 'common/services/apiService'
//import { fixTopMenu } from 'common/utils/topMenu'
import { checkPhone } from 'common/utils/validation'
import { setCookie, getCookie } from 'common/utils/cookies'
import moment from 'moment';

export function getLoginState(stateName) {
  let stateObj = {};
  switch (stateName) {
    case "login":
      stateObj = {
        login: true,
        forgotPassword: false,
        addDetails: false,
        notMe: false,
        otp: false,
      };
      break;
    case "forgot":
      stateObj = {
        login: false,
        forgotPassword: true,
        addDetails: false,
        notMe: false,
        otp: false,
      };
      break;
    case "addDetails":
      stateObj = {
        login: false,
        forgotPassword: false,
        addDetails: true,
        notMe: false,
        otp: false,
      };
      break;
    case "notMe":
      stateObj = {
        login: false,
        forgotPassword: false,
        addDetails: false,
        notMe: true,
        otp: false,
      };
      break;
    case "otp":
      stateObj = {
        login: false,
        forgotPassword: false,
        addDetails: false,
        notMe: false,
        otp: true,
      };
      break;
  }

  return stateObj;
}

export function setField(evt, thisContext) {
  switch (evt.target.name) {
    case "userName":
      thisContext.userName = evt.target.value;
      break;
    case "password":
      thisContext.password = evt.target.value;
      break;
    case "rememberMe":
      thisContext.rememberMe = evt.target.checked;
      break;
    case "email":
      thisContext.email = evt.target.value;
      break;
    case "cellNum":
      thisContext.cellNum = evt.target.value;
      break;
    case "authCode":
      thisContext.authCode = evt.target.value;
      break;
  }
}

export function keyDownEvt(evt, which, thisContext) {
  if (evt.keyCode === 13) {
    evt.stopPropagation();
    switch (which) {
      case "login":
        thisContext.login();
        break;
      case "restore":
        thisContext.restorePassword();
        break;
      case "addDetails":
        thisContext.addDetails();
        break;
      case "notMe":
        thisContext.notMe();
        break;
      case "sendOtp":
        thisContext.sendOtp();
        break;
    }
  }
}

export function doLogin(props, userName, password, rememberMe, doSetState) {
  const { accountStore, onCancel } = props;
  const {
    routingStore: { push },
    fromItem,
  } = props;
  if (!accountStore.profile) {
    accountStore.login(userName, password, rememberMe)
      .then(res => {
        if (accountStore.error == null && accountStore.profile != null) {
          localStorage.setItem('tenders_NeedOtp', res.NeedOtp)
          //successful login made
          if (rememberMe) {
            //save auto login cookie
            setCookie('Tenders_Autologin', `${userName}|||${password}`, { expires: 365, sameSite: 'lax' })
          }
          if (accountStore.profile.LoggedFromIfat) {
            //if login was successful, set here the last otp also, to localStorage
            //(avoid logging out loop when otp is not needed e.g. from Ifat)
            const lastOtp = moment().format('YYYY-MM-DD HH:mm:ss')
            localStorage.setItem('tenders_lastOtpDate', lastOtp)
          }
          successfulLoginMade(accountStore, fromItem, push, onCancel);
        } 
        else if (accountStore.error == null && accountStore.otpStatus?.ok) {
          let stateObj = {};
          if (rememberMe) {
            //save a short expiration for the otp view. if login will be successful there, it will save real Tenders_Autologin cookie
            setCookie('Tenders_Autologin_Otp', `${userName}|||${password}`, { expires: 1, sameSite: 'lax' })
          }
          if (accountStore.otpStatus.AuthStatus === 99) {
            //99 = missing details
            stateObj = getLoginState("addDetails");
          } else if (accountStore.otpStatus.AuthStatus === 2) {
            //2 = send otp
            stateObj = getLoginState("otp");
          }
          doSetState(stateObj);
        } 
        else if (accountStore.error == null && !accountStore.otpStatus?.ok) {
          //some error occured (flows to subcomponents from accountStore)
        }
      })
      .catch((error) => {
        console.error("[Login] Error:", error);
      });
  }
}

export function doRestorePassword(props, userName, email, setSentMessage) {
  const { accountStore, t } = props;
  setSentMessage("", false);
  accountStore.requestRestorePassword(userName, email).then((res) => {
    //console.log(res)
    if (res.sent) {
      setSentMessage(t("login.sent", { email }), false);
    } else {
      setSentMessage(t("login.noSuchUser"), true);
    }
  });
}

export function addMissingDetails(
  props,
  cellNum,
  doSetState,
  setValidationError
) {
  const { accountStore, t } = props;

  setValidationError("");
  const errors = validatePhone(
    cellNum,
    `${t("otp.enterPhone")}; `,
    `${t("otp.phoneNotValid")}; `
  );

  if (errors != "") {
    setValidationError(errors);
  } else {
    accountStore.addOTPDetails(cellNum).then((res) => {
      if (res.ok) {
        const stateObj = getLoginState("otp");
        doSetState(stateObj);
      } else {
        //some error occured (should be handled on accountStore - errors will flow to sub components)
      }
    });
  }
}

export function notMyDetails(
  props,
  email,
  cellNum,
  setValidationError,
  setSentMessage
) {
  const { accountStore, t } = props;

  setValidationError("");
  const errors = validatePhone(
    cellNum,
    `${t("otp.enterPhone")}; `,
    `${t("otp.phoneNotValid")}; `
  );

  if (errors != "") {
    setValidationError(errors);
  } else {
    setSentMessage("", false);
    accountStore.requestDetailsChange(cellNum).then((res) => {
      if (res.ok) {
        //notify user that the request was sent. details will be changed manually on backend
        setSentMessage(t("otp.sentSuccessfully", { email }), false);
      } else {
        //some error occured (should be handled on accountStore - errors will flow to sub components)
        setSentMessage(t("otp.sentError"), true);
      }
    });
  }
}

export function resendOtpCode(props, setSentMessage) {
  const { accountStore, t } = props;
  setSentMessage("", false);
  accountStore.getNewAuthCode().then((res) => {
    if (res.ok) {
      //notify user that the request was sent. details will be changed manually on backend
      setSentMessage(t("otp.otpSentAgain"), false);
    } else {
      //some error occured (should be handled on accountStore - errors will flow to sub components)
      setSentMessage(t("otp.otpSentError"), true);
    }
  });
}

export function checkOtp(props, authCode, setValidationError, setSentMessage) {
  const {
    accountStore,
    routingStore: { push },
    fromItem,
    onCancel,
    t,
  } = props;
  setValidationError("");
  if (authCode == "") {
    setValidationError(t("otp.enterCode"));
  } else {
    setSentMessage("", false);
    accountStore.checkOtp(authCode).then((res) => {
      if (accountStore.error == null && accountStore.profile != null) {
        //successful login made
        const cook = getCookie('Tenders_Autologin_Otp')
        if (cook && cook !== '') {
          setCookie('Tenders_Autologin', cook, { expires: 365, sameSite: 'lax' })
        }
        //save last good otp to localStorage
        const lastOtp = moment().format('YYYY-MM-DD HH:mm:ss')
        localStorage.setItem('tenders_lastOtpDate', lastOtp)
        localStorage.setItem('tenders_NeedOtp', res.NeedOtp)
        successfulLoginMade(accountStore, fromItem, push, onCancel);
      }
    });
  }
}

function validatePhone(cellNum, addPhoneLabel, validPhoneLabel) {
  let errors = "";
  if (cellNum == "") {
    errors = addPhoneLabel;
  } else if (!checkPhone(cellNum, false)) {
    errors += validPhoneLabel;
  }
  return errors;
}

function successfulLoginMade(accountStore, fromItem, push, onCancel) {
  clearCache();
  //fixTopMenu();
  accountStore.checkIfatUser()
  //push('/main')
  if (fromItem) {
    push(`/tender/${fromItem}`);
  } else {
    push("/main");
  }
  onCancel(); //close modal
}
