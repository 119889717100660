import React from 'react'
import { inject, observer } from 'mobx-react'
import { observable, toJS } from 'mobx'

import { translate } from 'react-polyglot'

import { doFilter } from 'common/utils/filter'
import find from 'lodash/find'

import './excludeFilter.scss'

export default
@translate()
@inject("searchStore")
@observer
class ExcludeFilter extends React.Component {

  @observable excludeHR = false
  @observable excludeMod = false

  componentDidMount() {
    //set the state - needed for mobile
    const { searchStore } = this.props
    const _excludeHR = find(searchStore.filters, (filter) => {
      return filter.field == 'excludeHR'
    })
    const _excludeMod = find(searchStore.filters, (filter) => {
      return filter.field == 'excludeMOD'
    })
    
    this.excludeHR = _excludeHR && _excludeHR.values.includes(1)
    this.excludeMod = _excludeMod && _excludeMod.values.includes(1)
  }

  onCheck = (type) => {
    if (type === 'excludeHR') {
      this.excludeHR = !this.excludeHR
      this.doFilter(type, this.excludeHR)
    }
    else if (type === 'excludeMOD') {
      this.excludeMod = !this.excludeMod
      this.doFilter(type, this.excludeMod)
    }    
    //console.log(this.excludeHR, this.excludeMod);
  }

  doFilter = (name, checked) => {
    const { searchStore } = this.props;
    doFilter(searchStore, name, [checked ? 1 : 0]);
  }

  render() {
    const { t } = this.props
    return (
      <div styleName="exclude">
        <h4>{t('filter.excludeTitle')}</h4>
        <div styleName="filter_container">
          <div
            styleName="filter"
            onClick={() => this.onCheck('excludeHR')}
          >
            {t('filter.excludeHR')}
            {this.excludeHR && <div styleName="checked">
              <div styleName="tick" />
            </div>}
          </div>

          <div
            styleName="filter"
            onClick={() => this.onCheck('excludeMOD')}
          >
            {t('filter.excludeMod')}
            {this.excludeMod && <div styleName="checked">
              <div styleName="tick" />
            </div>}
          </div>
        </div>
      </div>
    )
  }  
}