import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./SidePopupHOC.module.scss";

const SidePopupHOC = ({
  children,
  isOpen,
  sidePopCustomClassName = "",
  isClosedBtn = false,
  onClose,
}) => {
  return (
    <div
      className={styles.sidePopupHOCWrapper}
      style={{ transform: isOpen ? "" : "translateX(-100%)" }}
    >
      <div
        className={`${styles.sidePopupHocContent} ${sidePopCustomClassName}`}
      >
        {isClosedBtn ? (
          <div className={styles.closedBtn} onClick={onClose}>
            <CloseOutlined />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        ) : (
          " "
        )}
        {children}
      </div>
      <div className={styles.blurContainer} onClick={onClose}></div>
    </div>
  );
};

export default SidePopupHOC;
