import React, {Component} from 'react'
import RedirectorComponent from 'components/Redirector'

export default class Redirector extends Component {

  render(){
    return <div>
      <RedirectorComponent />
    </div>
  }
}
