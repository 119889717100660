import { action, computed, observable, toJS } from 'mobx'
import { deleteCookie } from 'common/utils/cookies'
import {
  me,
  login,
  logout,
  tokenLogin,
  requestRestorePassword,
  addOTPDetails,
  requestDetailsChange,
  getNewAuthCode,
  checkOtp,
  isIfatUser,
  checkVersion,
} from 'common/services/apiService'

class Account {
  @observable loading = false;
  @observable otpStatus = {};
  @observable profile = null;
  @observable isIfatUser = false;
  @observable userDataLoading = false;
  @observable error = null;
  @observable errorMessage = null;

  constructor() {
    this.loadProfile();
  }

  clearErrors() {
    this.error = null;
    this.errorMessage = null;
  }

  @action.bound
  loadProfile() {
    //this.me = await me()
    me().then((profile) => {
      this.profile = profile;
      //console.log('Me', this.profile)
    });
  }

  /*@action.bound
  validateAccount() {
    validateAccount().then(profile => {
      this.profile = profile
      //console.log('validated', profile)
    }).catch(error => {
      const {error: {status}} = error
      if (status == 401) {
        //unauthorized: clear the profile. this will raise 'not-logged' flag all over site
        this.profile = null
      }
    })
  }*/

  @action.bound
  async login(userName, password, rememberMe) {
    /*
    return new Promise((resolve, reject) => {
      login(userName, password, rememberMe).then(profile => {
        this.profile = profile
        this.error = null
        console.log('login', this.profile)
        resolve()
      }).catch(error => {
        this.error = error
        this.profile = null
      })
    })*/
    try {
      this.clearErrors();
      const response = await login(userName, password, rememberMe);
      if (response.userName) {
        this.profile = response;
        localStorage.setItem('token', this.profile.Token); //save the token to localStorage.
        this.checkIfatUser();
        return response
      } else {
        this.otpStatus = response;
        if (!response.ok) {
          this.error = response.error;
          this.errorMessage = response.error;
        }
      }
    } catch (e) {
      this.error = `[accountStore] login error: ${e.message} http status code ${e.error.status}`;
      this.errorMessage = e.message; //friendly message returns from api
      deleteCookie('Tenders_Autologin')
    }

    if (this.error != null) {
      console.error(this.error);
    }
  }

  @action.bound
  async tokenLogin(token) {
    try {
      this.loading = true;
      this.clearErrors();
      const response = await tokenLogin(token);
      if (response.userName) {
        this.profile = response
        localStorage.setItem("token", this.profile.Token); //save the token to localStorage.
        this.loading = false;
        return response;
      }
      else {
        this.otpStatus = response;
        if (!response.ok) {
          this.error = response.error;
          this.errorMessage = response.error;
        }
      }
    } catch (e) {
      this.error = `[accountStore] login error: ${e.message} http status code ${e.error.status}`;
      this.errorMessage = e.message; //friendly message returns from api
      this.loading = false;
    }

    if (this.error != null) {
      console.error(this.error);
    }
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.clearErrors();
      me()
        .then((profile) => {
          localStorage.setItem("token", profile.Token);
          this.profile = profile;
          this.clearErrors();
          this.loading = false;
        })
        .catch((error) => {
          //not 200 OK http status (ex. 401)
          this.error = `[accountStore] refreshToken error: ${error.message} http status code ${error.error.status}`;
          this.profile = null;
          this.loading = false;
        });
    });
  }

  @action.bound
  async logout(isAutomated = false) {
    try {
      await logout(isAutomated); //this is used for logging on the backend
    }
    catch(e) {
      console.log('[logout]error', e)
    }
    localStorage.removeItem('token')
    this.clearErrors()
    this.profile = null
  }

  requestRestorePassword(userName, email) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.clearErrors();
      requestRestorePassword(userName, email)
        .then((res) => {
          this.loading = false;
          resolve(res);
        })
        .catch((e) => {
          this.error = `[accountStore] requestRestorePassword error: ${e.message} http status code ${e.error.status}`;
          this.errorMessage = e.message;
          this.loading = false;
        });
    });
  }

  addOTPDetails(cellNum) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.clearErrors();
      addOTPDetails(this.otpStatus.UserData, cellNum)
        .then((status) => {
          if (status.ok) {
            this.otpStatus = status;
          } else {
            this.error = status.error;
            this.errorMessage = status.error;
          }
          this.loading = false;
          resolve(status);
        })
        .catch((e) => {
          this.error = `[accountStore] addOTPDetails error: ${e.message} http status code ${e.error.status}`;
          this.errorMessage = e.message;
          this.loading = false;
        });
    });
  }

  requestDetailsChange(cellNum) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.clearErrors();
      requestDetailsChange(this.otpStatus.UserData, cellNum)
        .then((status) => {
          if (status.ok) {
            this.otpStatus = status;
          } else {
            this.error = status.error;
            this.errorMessage = status.error;
          }
          this.loading = false;
          resolve(status);
        })
        .catch((e) => {
          this.error = `[accountStore] requestDetailsChange error: ${e.message} http status code ${e.error.status}`;
          this.errorMessage = e.message;
          this.loading = false;
        });
    });
  }

  getNewAuthCode() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.clearErrors();
      getNewAuthCode(this.otpStatus.UserData)
        .then((status) => {
          if (status.ok) {
            this.otpStatus = status;
          } else {
            this.error = status.error;
            this.errorMessage = status.error;
          }
          this.loading = false;
          resolve(status);
        })
        .catch((e) => {
          this.error = `[accountStore] getNewAuthCode error: ${e.message} http status code ${e.error.status}`;
          this.errorMessage = e.message;
          this.loading = false;
        });
    });
  }

  checkOtp(authCode) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.clearErrors();
      checkOtp(this.otpStatus.UserData, authCode, this.otpStatus.AuthCode)
        .then((profile) => {
          if (profile.userName) {
            this.otpStatus = {};
            this.profile = profile;
            localStorage.setItem("token", this.profile.Token); //save the token to localStorage.
          } else {
            this.error = profile.error;
            this.errorMessage = profile.error;
          }
          this.loading = false;
          resolve(profile);
        })
        .catch((e) => {
          this.error = `[accountStore] checkOtp error: ${e.message} http status code ${e.error.status}`;
          this.errorMessage = e.message;
          this.loading = false;
        });
    });
  }

  @action.bound
  async checkIfatUser() {
    if (!this.userDataLoading) {
      this.userDataLoading = true;
      let error = false;

      try {
        const res = await isIfatUser();
        this.isIfatUser = res?.ifat || false 
      } catch (e) {
        //an error occured on search
        console.error(
          `[checkUser] search error: ${e.message} http status code ${e.error.status}`
        );
        error = true;
      }

      if (!error) {
        console.info("[checkUser]");
      } else {
        this.isIfatUser = false;
      }
      this.userDataLoading = false;
    }
  }

  @action.bound
  async checkVersion() {
    //this.me = await me()
    try {
      const request = await checkVersion()      
      return request
    } catch (e) {
      console.log('[checkVersion]error', e)
      return null
    }    
  }

}

export const accountStore = new Account();
