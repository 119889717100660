import React, {Component} from 'react'
import {observer} from 'mobx-react'
import {observable} from 'mobx'
import {translate} from 'react-polyglot'
import take from 'lodash/take'
import takeRight from 'lodash/takeRight'
import {getHomeJSON} from 'common/services/apiService'
import ServiceItem from './ServiceItem'
import Footer from 'common/components/Footer'
import Adsense from "common/components/Adsense/Adsense";
//import ContactAction from 'common/components/ContactAction'
import DocumentMeta from 'react-document-meta'
import {getMetaData} from 'common/utils/meta'
import GTAG from 'common/utils/gtag'
//import CSSModules from 'react-css-modules'
import  './services.scss'

export default 
@translate()
@observer
class Services extends Component {

  @observable services = []

  componentDidMount() {
    getHomeJSON('Services', 'services').then(res => {
      this.services = res
    })
    const {showNotification} = this.props
    showNotification(true)
    GTAG.trackPage('Services', 'services')
  }


  render() {
    const {t} = this.props
    const pageName = t('meta.services')
    const meta = getMetaData(t('meta.pageTitle', {pageName}), t('meta.pageDesc', {pageName}), t('meta.pageKW', {pageName}))

    return (
      <div>
        <DocumentMeta {...meta} />
        <section id="services">
          <div styleName="adsense-container-desktop" style={{height: `${0.75*window.innerHeight}px`}} >
            <Adsense
              style={{
                  display: "block",
                  width: "160px",
                  height: "600px",
                  // position: "absolute",
                  // border:"1px solid black",
                  // left: "0",
                  // top: "20px",
                  // zIndex: "99999999",
              }}
              slot="4313418986"
              googleAdId="ca-pub-7408487462494237"
            />
          </div>
          <div className="row" style={{width: '80%', maxWidth: '155rem'}}>
            <div className="large-12 columns" >
              <h1 styleName="title" >{t('services.title')}</h1>
            </div>
          </div>
          <div className="row" style={{width: '80%', maxWidth: '155rem'}}>
            {
              this.services && take(this.services, 2).map((service, index) =>
                <ServiceItem
                  key={index}
                  title={service.title}
                  text={service.text}
                  image={service.image}
                />)
            }
          </div>
          <div className="row" style={{width: '80%', maxWidth: '155rem'}}>
            {
              this.services && takeRight(this.services, 2).map((service, index) =>
                <ServiceItem
                  key={index}
                  title={service.title}
                  text={service.text}
                  image={service.image}
                />)
            }
          </div>
        </section>
        {this.services && this.services.length > 0 &&
          <Footer />
        }
        {/*<ContactAction />*/}
      </div>
    )
  }
}
