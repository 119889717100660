import React, { Component } from 'react'
import { array, object, func, bool } from 'prop-types'
import { inject, observer } from 'mobx-react'
import { observable, toJS } from 'mobx'
import { translate } from 'react-polyglot'
import find from 'lodash/find'
import Select from 'react-select'
import Loading from 'common/components/Loading/Loading'
import { Include, Exclude } from 'common/style/icons'
//import CSSModules from 'react-css-modules'
import './Definition.scss'

export default 
@translate()
@inject('smartAgentStore')
@observer
class Definition extends Component {

  static propTypes = {
    isNew: bool,
    query: object,
    allQueries: array,
    onError: func,
    onSave: func,
    onClear: func,
    onDelete: func
  }

  @observable selectedValues = null
  @observable selectedSubSubject = null
  @observable words = ''
  @observable edit = false
  @observable editExclude = false

  componentDidMount() {
    this.initComponent(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.initComponent(nextProps)
  }

  initComponent = (props) => {
    const { isNew } = props
    if (!isNew) {
      const { query: { TenSubjectID, TenSubjectName, TenSubsubjectID, TenSubsubjectName, SearchWords } } = props
      //initialize the <Select> with the selected value:
      const query = {
        SubjectID: TenSubjectID,
        SubjectName: TenSubjectName
      }
      this.selectedValues = query
      this.selectedSubSubject = {
        SubSubjectID: TenSubsubjectID,
        SubSubjectName: TenSubsubjectName
      }
      //set the words
      this.words = SearchWords
    }
  }

  updateField = e => {
    this.words = e.target.value
  }

  onChange = values => {
    this.selectedValues = values
    this.selectedSubSubject = null
  }

  onSubSubjectChange = (values) => {
    this.selectedSubSubject = values
  }

  onInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      //e.preventDefault()  //fucks up the search.
      e.stopPropagation()
    }
  }

  onEdit = () => {
    this.edit = true
  }

  onSave = () => {
    const { isNew, onError, onSave, onClear, query, allQueries } = this.props
    if (this.selectedValues.length === 0 && this.words.trim().length === 0) {
      return;
    }
    onClear()
    const found = find(allQueries, current => {
      return current.TenSubjectID === this.selectedValues?.SubSubjectID || current.TenSubsubjectID === this.selectedSubSubject?.SubSubjectID
    })
    if (found && isNew) {
      onError(true)
    }
    else {
      const TenSubjectID = this.selectedValues != null ? this.selectedValues.SubjectID : null
      const TenSubjectName = this.selectedValues != null ? this.selectedValues.SubjectName : null
      const TenSubsubjectID = this.selectedSubSubject != null ? this.selectedSubSubject.SubSubjectID : null
      const TenSubsubjectName = this.selectedSubSubject != null ? this.selectedSubSubject.SubSubjectName : null
      const newQuery = {
        TenSubjectID,
        TenSubjectName,
        TenSubsubjectID,
        TenSubsubjectName,
        SearchWords: this.words || '',
        Exclude: this.editExclude
      }
      onSave(query, newQuery)
      this.edit = false
      this.editExclude = false
      this.selectedValues = null
      this.words = ''
    }

  }

  onCancel = () => {
    const { onClear } = this.props
    onClear()
    this.edit = false
    this.editExclude = false
  }

  onDelete = () => {
    const { onDelete, query } = this.props
    onDelete(query)
  }

  render() {
    //const selectedValues = toJS(this.selectedValues)  //for multiple option - an array
    const { smartAgentStore, query, isNew, t } = this.props
    const options = smartAgentStore.subSubjects
    let subSubjectsOptions = []

    const selectedSubjects = smartAgentStore?.subSubjects?.find((subject) => subject.SubjectID === this.selectedValues?.SubjectID)
    if (selectedSubjects) {
      subSubjectsOptions = toJS(selectedSubjects.SubSubjects).filter((subSubject) => subSubject.SubSubjectID !== -1)
    }

    return (
      <div styleName="line" >
        {(this.edit || this.editExclude) ?
          <div className="grid-x">

            {!smartAgentStore.subSubjectsLoading && (
              <>
                <div styleName="fields" className="medium-3 cell">
                  <Select
                    styleName="branch"
                    className="search-select"
                    menuContainerStyle={{ overflowY: 'visible', height: '200px' }}
                    name="searchbox"
                    placeholder={t('agent.placeHolder')}
                    noResultsText={null}
                    searchPromptText=""
                    rtl={true}
                    multi={false}
                    cache={false}
                    clearable={false}
                    options={toJS(options)}
                    onChange={this.onChange}
                    onInputKeyDown={this.onInputKeyDown}
                    value={this.selectedValues}
                    labelKey={'SubjectName'}
                    valueKey={'SubjectID'}
                  />
                </div>
                {
                  this.selectedValues && subSubjectsOptions.length > 0 && (
                    <div styleName="fields" className="medium-3 cell">
                      <Select
                        styleName="branch"
                        className="search-select"
                        menuContainerStyle={{ overflowY: 'visible', height: '200px' }}
                        name="subSubjects"
                        placeholder={t('agent.placeHolder')}
                        noResultsText={null}
                        searchPromptText=""
                        rtl={true}
                        multi={false}
                        cache={false}
                        clearable={false}
                        options={subSubjectsOptions}
                        onChange={this.onSubSubjectChange}
                        onInputKeyDown={this.onInputKeyDown}
                        value={this.selectedSubSubject}
                        labelKey={'SubSubjectName'}
                        valueKey={'SubSubjectID'}
                      />
                    </div>
                  )
                }
              </>
            )
            }
            {this.edit && <div styleName="fields" className="medium-9 cell">
              <input type="text" name="words" styleName="word-input" defaultValue={this.words} onChange={this.updateField} />
            </div>}
            <div styleName="links" className="medium-2 cell">
              <a onClick={this.onCancel}>{t('agent.cancel')}</a>&nbsp;
              <a onClick={this.onSave}>{t('agent.save')}</a>
            </div>

            {smartAgentStore.subSubjectsLoading && <Loading />}
          </div>
          :
          isNew ?
            <div>
              <a styleName="add" onClick={() => this.edit = true}>{t('agent.add')}</a>
              <a styleName="exclude" onClick={() => this.editExclude = true}>{t('agent.exclude')}</a>
            </div>
            :
            <div className="grid-x">
              <div styleName='includeExcludeIcon'>
                <img src={query.Exclude ? Exclude : Include} />
              </div>
              <div styleName="fields" className="medium-3 cell">
                <span>{`${query.TenSubjectName}${query.TenSubsubjectName?.length > 0 ? " . " + query.TenSubsubjectName : ""}`}</span>
              </div>
              <div styleName="fields" className="medium-5 cell">
                {query.SearchWords}
              </div>
              <div styleName="links" className="medium-3 cell">
                <a onClick={this.onEdit}>{t('agent.edit')}</a>
                <a onClick={this.onDelete}>{t('agent.delete')}</a>
              </div>

            </div>
        }
      </div>
    )
  }
}
