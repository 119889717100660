import React, {Component} from 'react'
import TermsOfUse from 'components/TermsOfUse'


export default class TermsOfUsePage extends Component {

  render(){
    return (
      <div>
        <TermsOfUse />
      </div>
    )
  }
}