import React, { Component } from "react";
import { star } from 'common/style/icons'

import "../home.scss";

//const req = require.context("common/style/icons/", false);
//const star = req("./star.png").default;

const Review = ({ t, data, key }) => {
  return (
    <div styleName="review-container" key={`testimonial-${key}`}>
      <div styleName="top">
        <div styleName="right">
          <div styleName="img">
            <img 
            src={data.profileImage}
            alt='profile'/>
          </div>
          <div>
            <div styleName="title">{data.title}</div>
            <div styleName="subTitle">{data.subtitle}</div>
          </div>
        </div>
        <img
          src={data.logoImage}
          styleName="image"
          alt="image"
        />
      </div>
      <div styleName="para">
        {data.text}
      </div>
      <div styleName="bottom">
        <div styleName="rating-container">
          <div styleName="rating">5/5</div>
          <div>
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
            <img src={star} />
          </div>
        </div>
        <div styleName="time">{data.date}</div>
      </div>
    </div>
  );
};

export default Review;
