import React from "react";
import { object } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import filter from "lodash/filter";
import remove from "lodash/remove";
import find from "lodash/find";
import { doFilter } from "common/utils/filter";
//import CSSModules from 'react-css-modules'
import "./TenderTypeFilter.scss";

export default
@translate()
@inject("searchStore")
@observer
class TenderTypeFilter extends React.Component {
  static propTypes = {
    items: object,
  };

  @observable items = [];
  @observable selected = [];
  searching = false;

  componentDidMount() {
    const { items, searchStore } = this.props;
    this.items = items;
    this.addSelected(searchStore.filters);
  }

  componentWillReceiveProps(nextProps) {
    const { items, searchStore } = nextProps;
    this.items = items;
    this.addSelected(searchStore.filters);
  }

  addSelected = (filters) => {
    //get relevant tendertype filter (if any)
    const tenderTypes = find(filters, (filter) => {
      return filter.field == "tendertype";
    });
    //iterate on items. add to selected the ones that were already filtered (or all, if none was) -
    //to check relevant on open\after filter action
    this.items &&
      this.items.map((item) => {
        if (
          !this.selected.includes(item.TenderTypeID) &&
          (tenderTypes == undefined ||
            tenderTypes.values.includes(item.TenderTypeID))
        ) {
          this.selected.push(item.TenderTypeID);
        }
      });
  };

  doFilter = () => {
    const { searchStore } = this.props;
    doFilter(searchStore, "tendertype", this.selected);
  };

  onCheck = (itemId) => {
    if (!this.selected.includes(itemId)) {
      this.selected.push(itemId);
    } else {
      remove(this.selected, (id) => {
        return id === itemId;
      });
    }
    //console.log(toJS(this.selected))
    //solve performance problem: raise search flag and setTimeout for filter commit action
    if (!this.searching) {
      this.searching = true;
      setTimeout(() => {
        this.searching = false;
        //commit:
        this.doFilter();
      }, 1000);
    }
  };

  render() {
    const {
      searchStore,
      searchStore: { resultsLoading },
      t,
    } = this.props;
    return (
      <div styleName="tender_type">
        <h4>{t('filter.tenderTypesTitle')}</h4>
        {
          /*!searchStore.resultsLoading &&*/
          <div styleName="filter_container">
            {this.items &&
              this.items.map(
                (item) => (
                  <div
                    key={item.TenderTypeID}
                    styleName="filter"
                    onClick={() => this.onCheck(item.TenderTypeID)}
                  >
                    {item.TenderTypeName}
                    {this.selected.includes(item.TenderTypeID) && (
                      <div styleName="checked">
                        <div styleName="tick" />
                      </div>
                    )}
                  </div>
                ),
                this
              )}
          </div>
        }
        {searchStore.resultsLoading && ( //mask the checkboxes when loading - for opacity loader
          <div styleName="loading-mask">&nbsp;</div>
        )}
      </div>
    );
  }
}
