import React, {Component} from 'react'
import RadarInfoComponent from 'components/RadarInfo'

//
export default class RadarInfoPage extends Component {

  render(){
    return <div><RadarInfoComponent/></div>
  }
}
