import React from "react";
import { logoSrc } from "common/style/icons";

import "./LoginDialog.scss";

/*const req = require.context('common/style/icons/', false)
const logo = req('./logo.png').default
*/

const ForgotPassword = ({
  accountStore,
  userName,
  email,
  updateField,
  onKeyDown,
  restore,
  onStateChange,
  sentMessage,
  sentError,
  t,
}) => {
  return (
    <div styleName="loginForm forget-password">
      <div styleName="logo">
        <img src={logoSrc} />
      </div>
      <div styleName="innerContainer">
        <div>
          <div styleName="heading">{t("forgotPassword.title")}</div>
          <div styleName="divider"></div>
        </div>
        <div>
          {accountStore.error != null && accountStore.profile == null && (
            <div styleName="error_box">{accountStore.errorMessage}</div>
          )}
          {sentMessage !== "" && (
            <div styleName={sentError ? "error_box" : "error_box green"}>
              {sentMessage}
            </div>
          )}
          <div styleName="inputContainer">
            <div styleName="label">{t("forgotPassword.userName")}</div>
            <input
              styleName="input"
              type="text"
              name="userName"
              value={userName}
              onChange={updateField}
              onKeyDown={(e) => onKeyDown(e, "restore")}
            />
          </div>
          <div styleName="inputContainer">
            <div styleName="label">{t("forgotPassword.emailLabel")}</div>
            <input
              styleName="input"
              type="email"
              name="email"
              value={email}
              onChange={updateField}
              onKeyDown={(e) => onKeyDown(e, "restore")}
            />
          </div>
          <div styleName="bottom">
            <div styleName="link">
              <a onClick={() => onStateChange("login")}>
                {t("forgotPassword.backToLogin")}
              </a>
            </div>
          </div>
        </div>
        <button styleName="btn" onClick={restore}>
          {t("forgotPassword.restore")}
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
