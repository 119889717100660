import React from 'react'
import { number, string, func } from 'prop-types'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { translate } from 'react-polyglot'
import moment from 'moment'
import { setReminder, clearCache } from 'common/services/apiService'
import Confirm from 'common/components/Confirm'
import { trash } from 'common/style/icons'

import './ReminderItem.scss'

/*const req = require.context('common/style/icons/', false)
const alertSrc = req('./alert.svg').default
const trash = req('./trashnew.svg').default*/

export default
@translate()
@observer
class ReminderItem extends React.Component {
  static propTypes = {
    reminderID: number,
    title: string,
    date: string,
    infoDate: string,
    selectItem: func,
    reload: func
  }

  @observable reminderID = -1
  @observable deleteMe = false

  componentDidMount() {
    const { reminderID } = this.props
    this.reminderID = reminderID
  }

  delReminder = (reminderID) => {
    this.deleteMe = true
  }

  deleteConfirm = (del) => {
    if (del && typeof (del) !== 'object') { //typeof(del) === 'object' means that user has cancelled dialog without choosing
      setReminder('Delete', this.reminderID, -1, '', '', '', '1970-01-01').then(deleted => {
        console.log('delete status:', deleted) //implement if user should know something about delete op
        clearCache()
        this.props.reload()
      })
    }
    this.deleteMe = false
  }

  render() {
    const { reminderID, title, date, infoDate, selectItem, isSelected, t } = this.props
    //determine the date tabel
    const timeVal = moment(date, 'YYYY-MM-DD HH:mm').format('HH:mm')
    const reminderDate = moment(date, 'YYYY-MM-DD HH:mm').startOf('day').isSame(moment().startOf('day')) ?
      `${t('reminders.today')} ${timeVal}` :
      moment(date, 'YYYY-MM-DD HH:mm').format('DD/MM/YY')

    const infoDateVal = infoDate ? moment(infoDate, 'YYYY-MM-DD HH:mm').format('DD/MM/YY') : t('reminder.noDate')
    return (
      <div styleName="record">
        <div className="grid-x">

          <div className="large-10 cell">

            <div styleName="record-title" onClick={() => selectItem(reminderID)}>{title}</div>
            <span> {`${t('reminders.infoDate')} ${infoDateVal}`}</span>

          </div>
        </div>
        <div styleName='hline' />
        <div styleName='bottom-container'>
          <div styleName='actions'>
            {/* <a onClick={() => selectItem(reminderID)}>{t('reminders.edit')}</a> */}
            <a onClick={() => this.delReminder(reminderID)} style={{ paddingLeft: '10px' }}>
              <img src={trash} />
              {/* {t('reminders.delete')} */}
            </a>
          </div>
          <div styleName='date-time'>
            <h3 styleName="record-infodate">{reminderDate}</h3>
            <span styleName="time">{timeVal}</span>
          </div>
        </div>
        {
          this.deleteMe &&
          <Confirm
            title={t('reminder.deleteTitle')}
            subTitle={t('reminder.deleteSubTitle')}
            actLabel={t('reminder.delete')}
            onClose={this.deleteConfirm}
          />
        }
      </div>
    )
  }
}
