import React from 'react'
import './whatsAppIcon.scss'

const WhatsAppIcon = ({ }) => {
    return <div styleName="whatsapp-container" onClick={() => window.open('https://bit.ly/3NGu9AO', '_blank')}>
        <div styleName="whatsapp-icon">
            <span></span>
            <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </div>
    </div>
}

export default WhatsAppIcon
