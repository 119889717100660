import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import "./mobileSubSearch.scss";

const MobileSubSearch = ({
  items,
  t,
  selected,
  onChange,
  onCheckAll,
  selectedItemsLabels,
}) => {
  return (
    <div styleName="subSearchContainer">
      {selected.length > 0 && (
        <div styleName="selected-items-container">
          <div styleName="content">
            <div>{t("filter.activeFilter")}</div>
            <div>{t("filter.selectAll")}</div>
          </div>
          <div styleName="tags">
            {selectedItemsLabels.map((item) => (
              <div styleName="tag" key={item}>
                <CloseOutlined />
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        styleName={`checkboxesContainer ${
          selected.length ? "checkboxesContainer2" : ""
        }`}
      >
        <div styleName="select-all" onClick={onCheckAll}>
          {t("filter.selectAll")}
        </div>
        {items.map((item) => (
          <div key={item.SubSubjectID} styleName="checkbox">
            <input
            styleName='checkbox'
              type="checkbox"
              checked={selected.includes(item.SubSubjectID)}
              onChange={onChange}
              value={item.SubSubjectID}
              name={item.SubSubjectName}
            />
            <span>{item.SubSubjectName}</span>
          </div>
        ))}
      </div>
      <div styleName="bottom-container">
        <div styleName="text">{t("results.totalFilters", { count: 15 })}</div>
        <div styleName="btn">{t("results.closeFiltersPopup")}</div>
      </div>
    </div>
  );
};

export default MobileSubSearch;
