import React, { Component } from "react";
import "./Topbar.scss";
import { translate } from "react-polyglot";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  clearCache,
  getRemindersCount,
  resetReminders,
  welcomeMessage,
} from "common/services/apiService";
import LoginDialog from "common/components/LoginDialog";
//import ReactInterval from "react-interval";
import { getCookie, setCookie } from "common/utils/cookies";
import Welcome from "./Welcome";
import mobile from "is-mobile";
import { Drawer, Dropdown } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { accountStore } from "../../stores/accountStore";
import {
  logoSrc,
  accountSrc,
  bellSrc,
  bellRemindedSrc,
  heartSrc,
  logoutSrc,
  manuIco,
} from "common/style/icons";
import SmartAgentView from "../../components/SmartAgent/SmartAgentView";
import CustomerCareDetails from "./CustomerCareDetails";
import { deleteCookie } from "../../common/utils/cookies";
import TopMenu from "./TopMenu";

// const req = require.context("common/style/icons/", false);
// const logoSrc = req("./logo.png").default;
// const userSrc = req("./user.svg").default;
// const manuIco = req("./nav_icon.svg").default;
// const accountSrc = req("./accountsmall.svg").default;
// const bellSrc = req("./bellsmall.svg").default;
// const heartSrc = req("./heartsmall.svg").default;
// const logoutSrc = req("./logoutsmall.svg").default;

const navbar = [
  /*{
  title: 'subscriptions',
  link: '/subscriptions'
},*/
  {
    title: "home",
    link: "/home",
  },
  /*{ title: "articles", link: "/" },
  { title: "siteMap", link: "/" },*/
  {
    title: "about",
    link: "/about",
  },
  {
    title: "services",
    link: "/services",
  },
  {
    title: "contactus",
    link: "/contact",
  },
  {
    title: "termsOfUse",
    link: "/terms-of-use"
  },
  {
    title: 'qanda',
    link: '/qanda'
  }
];

const menuItems = [
  {
    name: "smartagent",
    link: "smart",
    src: accountSrc,
  },
  {
    name: "favorites",
    link: "/favorites",
    src: heartSrc,
  },
  {
    name: "reminders",
    link: "/reminders",
    src: bellSrc,
  },
  {
    name: "logout",
    link: "/logout",
    src: logoutSrc,
  },
];

const loginMenuItems = [
  {
    name: "home",
    link: "/home",
  },
  /*{ name: "nav.articles", link: "/" },
  { name: "nav.siteMap", link: "/" },*/
  {
    name: "about",
    link: "/about",
  },
  {
    name: "services",
    link: "/services",
  },
  {
    name: "contactus",
    link: "/contact",
  },
  {
    name: "termsOfUse",
    link: "/terms-of-use"
  },
  {
    name: "logout",
  },
];

export default
@translate()
@inject("routingStore")
@inject("accountStore")
@observer //note if class is not an observer, it will not be affected from changes in other classes observables...
class Topbar extends Component {
  @observable showLoginDialog = false;
  @observable messageCount = 0;
  @observable isMobile = false;
  @observable isWelcomeOpen = false;
  @observable welcome = {};
  @observable cookName = "";
  @observable showMenu = false;
  @observable showMobileMenu = false;

  state = { showSmartAgent: false };
  cookVal;

  constructor(props) {
    super(props);
    this.state = {
      isDrawerVisible: false,
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    //fix top nav foundation creation bug
    //fixTopMenu()
    //handle cookie for 'Welcome' component...
    window.addEventListener("resize", this.updateDimensions);
    this.isMobile = mobile();
    if (!this.isMobile) {
      welcomeMessage().then((res) => {
        if (res.active) {
          this.welcome = res;
          this.cookName = `WelcomeShown-${res.timeStamp}`;
          this.cookVal = getCookie(this.cookName);
          //console.log(this.cookVal)
          if (this.cookVal && this.cookVal != "" && parseInt(this.cookVal) >= 2)
            this.isWelcomeOpen = false;
          else {
            this.isWelcomeOpen = true;
          }
        } else {
          this.isWelcomeOpen = false;
        }
      });
    }
    accountStore.checkIfatUser();
  }

  componentWillReceiveProps(nextProps) {
    //console.log('receive', nextProps)
    if (nextProps.notify) {
      getRemindersCount().then((res) => (this.messageCount = res));
    } else {
      clearCache();
      resetReminders().then(() => (this.messageCount = 0));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  navigate = (route) => () => {
    const {
      routingStore: {
        push,
        location: { pathname: path },
      },
    } = this.props;
    if (route === "smart") {
      this.setState({ showSmartAgent: true });
    } else {
      if (path !== route) {
        this.showMobileMenu = false;
        push(route);
        this.setState({ showSmartAgent: false });
      }
    }

    this.state.isDrawerVisible && this.closeDrawer();
  };

  goToHome = () => {
    const {
      accountStore,
      routingStore: { push },
    } = this.props;
    const homeLink = accountStore.profile ? "/main" : "/home";
    this.showMobileMenu = false;
    push(homeLink);
  };

  login = () => {
    this.showLoginDialog = true;
    this.showMenu = false;
    this.showMobileMenu = false;
    this.state.isDrawerVisible && this.closeDrawer();
  };

  logout = () => {
    const {
      accountStore,
      routingStore: { push },
    } = this.props;
    accountStore.logout();
    clearCache();
    deleteCookie('Tenders_Autologin') 
    deleteCookie('Tenders_Autologin_Otp')
    this.showMenu = false;
    this.showMobileMenu = false;
    push("/");
  };

  register = () => {
    const {
      routingStore: {
        push,
        location: { pathname: path },
      },
    } = this.props;
    if (path !== "/contact") {
      push("/contact");
    }
    this.showLoginDialog = false;
  };

  continueUnlogged = () => {
    this.showLoginDialog = false;
  };

  closeWelcomeDialog = () => {
    //console.log('closeWelcomeDialog')
    this.isWelcomeOpen = false;
    const cnt = this.cookVal || 0;
    if (this.cookName != "") {
      setCookie(this.cookName, parseInt(cnt) + 1, { expires: 365, sameSite: 'lax' });
    }
  };

  showLeftMenu = (visible) => {
    //console.log('left', visible)
    this.showMenu = visible;
  };

  handleMobileMenu = (visible) => {
    //console.log('mobile', visible)
    this.showMobileMenu = visible;
    if (!visible) this.showMenu = visible; //showMenu fix
  };

  closeDrawer = () => {
    this.setState({ ...this.state, isDrawerVisible: false });
  };

  openDrawer = () => {
    this.setState({ ...this.state, isDrawerVisible: true });
  };

  updateDimensions = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  closeSmartAgent = () => {
    this.setState({ showSmartAgent: false });
  };

  render() {
    const { accountStore, routingStore, t } = this.props;
    //const homeLink = accountStore.profile ? '/main' : '/home'
    //console.log(accountStore.profile, homeLink)
    return (
      <>
        <div
          styleName={`header ${accountStore.profile ? "is-logged" : ""}`}
          style={{ display: "flex" }}
        >
          {/* <div styleName="upper-part"> */}
          <div
            styleName={`right-part ${
              accountStore.profile ? "is-logged" : "width65"
            }`}
          >
            {
              !accountStore.profile && (
                <a styleName="logo" onClick={this.goToHome}>
                  <img
                    src={logoSrc}
                    alt={t("nav.logoAlt")}
                    title={t("nav.logoAlt")}
                    id="logo"
                  />
                </a>
              )
            }
            {accountStore.profile && (
              <>
                <div styleName="dropdown" >
                  <Dropdown
                    overlay={
                      <TopMenu
                        items={loginMenuItems}
                        visibleChange={this.handleMobileMenu}
                        logout={this.logout}
                      />
                    }
                    overlayStyle={{minWidth:'14.5rem'}}
                    trigger={["hover"]}
                    overlayClassName={'topmenu-dropdown'}
                    visible={this.showMenu}
                    onVisibleChange={this.showLeftMenu}
                  >
                    <a styleName="logo" onClick={this.goToHome}>
                      <img
                        src={logoSrc}
                        alt={t("nav.logoAlt")}
                        title={t("nav.logoAlt")}
                        id="logo"
                      />
                      {
                        this.showMenu ?
                        <UpOutlined style={{ marginTop: "14px" }} /> :
                        <DownOutlined style={{ marginTop: "14px" }} />
                      }
                    </a>
                  </Dropdown>
                </div>
                <a styleName="manu_icon mr-2" onClick={this.openDrawer}>
                  <img src={manuIco} alt="" />
                </a>
                <Drawer
                  title={
                    <img
                      src={logoSrc}
                      alt={t("nav.logoAlt")}
                      title={t("nav.logoAlt")}
                      styleName="drawer-logo"
                    />
                  }
                  placement="right"
                  visible={this.state.isDrawerVisible}
                  onClose={this.closeDrawer}
                >
                  {loginMenuItems.map((menu, index) => (
                    <div
                      key={`menu_${index}`}
                      styleName={`drawer-menu ${
                        location.pathname === menu.link ? "active" : ""
                      }`}
                      onClick={() => {
                        this.closeDrawer();
                        if (!menu.link) {
                          this.logout();
                        } else {
                          routingStore.push(menu.link);
                        }
                      }}
                    >
                      <span>{t(`nav.${menu.name}`)}</span>
                    </div>
                  ))}
                  <CustomerCareDetails 
                    routingStore={routingStore}
                    closeDrawer={this.closeDrawer}
                  />
                </Drawer>
              </>
            )}

            {!accountStore.profile ? (
              this.state.width >= 768 ? (
                <ul>
                  {navbar.map((nav, index) => {
                    //const style = nav.title == 'publish' ? 'publish-link' : ''
                    return (
                      <li key={`nav_${index}`} styleName="menu-link">
                        <a onClick={this.navigate(`${nav.link}`)}>
                          {t(`nav.${nav.title}`)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <a styleName="manu_icon" onClick={this.openDrawer}>
                  <img src={manuIco} alt="" />
                </a>
              )
            ) : null}
            <Drawer
              title={
                <img
                  src={logoSrc}
                  alt={t("nav.logoAlt")}
                  title={t("nav.logoAlt")}
                  styleName="drawer-logo"
                />
              }
              placement="right"
              visible={!accountStore.profile && this.state.isDrawerVisible}
              onClose={this.closeDrawer}
            >
              {navbar.map((nav, index) => {
                //const style = nav.title == 'publish' ? 'publish-link' : ''
                return (
                  <div
                    key={`nav_2_${index}`}
                    onClick={this.navigate(`${nav.link}`)}
                    styleName={`drawer-menu ${
                      location.pathname === nav.link ? "active" : ""
                    }`}
                  >
                    <span>{t(`nav.${nav.title}`)}</span>
                  </div>
                );
              })}
              {!accountStore.profile && (
                <div styleName="drawer-menu" onClick={this.login}>
                  <span>{t("nav.pleaseLog")}</span>
                </div>
              )}
              <CustomerCareDetails
                routingStore={routingStore}
                closeDrawer={this.closeDrawer}
              />
            </Drawer>
          </div>

          <div
            styleName={`left-part ${accountStore.profile ? "is-logged" : ""}`}
          >
            {accountStore.profile ? (
              <>
                {this.state.width > 767 ? (
                  <ul
                    styleName={`is-logged${
                      accountStore.isIfatUser ? "-ifat" : ""
                    }`}
                  >
                    {menuItems.map((item, index) => {
                      return (
                        <li key={`menu_2_${index}`} styleName="menu-link">
                          <a
                            onClick={
                              item.link === "/logout"
                                ? () => this.logout()
                                : this.navigate(item.link)
                            }
                          >
                            {item.src ? (
                              item.name === "reminders" &&
                              this.messageCount !== 0 ? (
                                <img src={bellRemindedSrc} />
                              ) : (
                                <img src={item.src} />
                              )
                            ) : null}
                            {t(`nav.${item.name}`)}
                          </a>
                          <span>|</span>
                        </li>
                      );
                    })}
                    <li>
                      <a
                        onClick={this.navigate("/publish")}
                        styleName="publish-button"
                      >
                        {t("nav.publish")}
                      </a>                     
                    </li>
                    {accountStore.isIfatUser && (
                      <>
                      <li style={{padding: '0 10px 0 10px'}}>|</li>
                      <li>
                        <a
                          styleName="publish-button"
                          style={{ backgroundColor: '#42a573'}}
                          onClick={this.navigate("/smartagentdef")}
                        >
                          {t("nav.ifatAgent")}
                        </a>
                      </li>
                      </>
                    )}
                  </ul>
                ) : (
                  <div styleName="menu-link">
                    <a onClick={this.navigate("/smartagent")}>
                      <img src={accountSrc} />
                      {t("nav.smartagent")}
                    </a>
                  </div>
                )}
              </>
            ) : (
              <ul styleName="column-gap">
                <li>
                  <a onClick={this.login} styleName="login-link">
                    {t("nav.pleaseLog")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={this.navigate("/publish")}
                    styleName="publish-button"
                  >
                    {t("nav.publish")}
                  </a>
                </li>
              </ul>
            )}
          </div>
          {/* </div> */}

          <Welcome
            imageUrl={this.welcome.url}
            landingPage={this.welcome.landingPage}
            isDialogOpened={this.isWelcomeOpen}
            closeDialog={this.closeWelcomeDialog}
          />

          {this.showLoginDialog && (
            <LoginDialog
              onCancel={this.continueUnlogged}
              register={this.register}
              t={t}
            />
          )}
          {this.state.showSmartAgent && (
            <SmartAgentView closeSmartAgent={this.closeSmartAgent} />
          )}
        </div>
      </>
    );
  }
}
