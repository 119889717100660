export const leadingIndustriesSliderConfig = {
  responsive: [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 8,
      },
    },
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 970,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const sampleTendorsSliderConfig = {
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 790,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const lastWinnersSliderConfig = {
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const testinomialSliderConfig = {
  responsive: [
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const termsOfUseLists = [
  {
    title: "כללי",
    list: [
      'הוראות התקנון שלהלן מגדירות את זכויות וחובות המשתמש בקשר עם השימוש באתר האינטרנט (להלן: "התקנון"). ',
      'התקנון מהווה חוזה התקשרות מחייב בין המשתמשת לבין חברת יפעת (להלן: "החברה ו/או מנהלי האתר"), לכל דבר ועניין. ',
      'החברה רשאית לבצע שינויים ו/או עדכונים בתנאי שימוש אלה בכל זמן או עת, ללא הודעה מוקדמת ו/או עדכון.  ',
      'המשתמש מתחייבת לחזור ולעיין בתנאי השימוש מידי פעם על מנת להתרענן ולהתעדכן בתנאים ובשינויים אם היו כאילו. השימוש באתר מהווה הסכמה להיות כפופים לאמור בתנאי השימוש בכל עת ולפעול באתר בהתאם. ',
      'אין לעשות באתר או באמצעותו כל שימוש למטרות בלתי חוקיות.',
      'בכל מקרה של סתירה בין הוראות תקנון זה להוראות כל מסמך אחר, תגברנה הוראות תקנון זה. הרישום במחשבי החברה יהווה ראיה מכרעת לנכונות רישומים אשר תנהל החברה ע"פ תקנון זה. ',
      'התקנון מנוסח בלשון זכר אך מתייחס לשני המינים. '
    ]
  },
  {
    title: "התכנים והמידע המוצגים באתר",
    list: [
      "יתכן ותכנים מסוימים חסרים ו/או נפלו טעויות כאלה ואחרות בתוכן האתר, לרבות ברכיבי תוכנה. יפעת לא תישא בכל אחריות לכל נזק, הוצאה ו/או אובדן שיגרמו למשתמשים עקב טעויות ו/או אי דיוקים במידע כאמור.",
      "ידוע ומוסכם על המשתמש שהגישה לחלקים מן האתר עשויה להיות מותנה בהתקנת רכיבי חומרה שונים במחשבו ובכלל זאת: דפדפנים ותוספים לאותם הדפדפנים. על המשתמש חלה האחריות לברר ולהתקין כל רכיב נדרש לצורך השימוש באתר, על חשבונו ואחריותו.",
      "החברה רשאית להגביל את הגישה לאתר או לחלקים ממנו לסוגי משתמשים שונים ו/או ללקוחותיה ו/או לחלקם ו/או להתנות את הגישה אליהם למשתמשים שירשמו באתר או שיאשרו את הסכמתם לתנאים מיוחדים מסוימים.אין בפרסומות לאתר או ממנו כדי ליצור בין המשתמש לבין יפעת יחסים משפטיים כלשהם, למעט חובותיו המשפטיות של המשתמש כמפורט בתקנון זה. זכויות הצדדים הן אלו המפורטות בתנאי שימוש אלו, ואין בשימוש באתר או בפרסומות כאמור כדי להקנות כל זכויות נוספות.",
      "במידה ותתגלה אי התאמה ו/או סתירה בין התוכן באתר לבין מידע הקיים במסמכיה הרשמיים של החברה - יחשב כנכון המידע הנמצא במסמכים הרשמיים של החברה."
    ]
  },
  {
    title: "שמירה על פרטיות ",
    list: [
      "לחברה חשובה פרטיותך והיא מחויבת לשמור על כללי הגנת הפרטיות החלים בישראל, שימוש במידע אישי אשר יימסר על-ידך או ייאסף אודותייך ייעשה אך ורק בהתאם למפורט במדיניות פרטיות זו או על-פי חובות החלות כלפי החברה לפי כל דין .",
      'המשתמש מאשר בזאת ומסכים, כי ככל ומולאו על ידיו פרטים אישיים לצורך שימוש באתר האינטרנט, הם נמסרו על ידו מתוך הסכמה, בחירה ורצון חופשי וכן לא חלה עליו חובה חוקית למסור את הנתונים הנ"ל.',
      "כאמור, חברת דפני עושה כל שביכולתה, וכנדרש בחוק, להגן על פרטי המשתמשים השמורים במאגריה, כמו כן במקרה בו יעלה בידי צד שלישי לשבש ו/או להפריע ו/או לחדור ו/או לעשות שימוש לרעה ו/או לעשות כל פעולה אחרת שלא כדין בפרטי המשתמשת, לא תהיינה למשתמשת ו/או מי מטעמו כל טענה ו/או דרישה לחברה.",
      "החברה רשאית לבצע שימוש בפרטיו האישיים של המשתמש, ללא זיהוי ספציפי, לצורך ניתוח מידע סטטיסטי והצגתו ו/או מסירתו לגורמים שונים וצדדים שלישיים.",
      "יובהר כי מאחר והפעולות מתבצעות בסביבה מקוונת אין החברה יכולה להבטיח חסינות מוחלטת מפני חדירות למחשביה, או חשיפת המידע האמור בידי מבצעי פעולות בלתי חוקיות. אם יעלה בידי צד שלישי לחדור למידע אשר שמור בידי החברה ו/או להשתמש בו לרעה, לא תהיה למשתמש כל טענה, תביעה או דרישה כלפי החברה.",
      "החברה תהא רשאית לעשות שימוש ב – “עוגיות” (“cookies”) על מנת לספק למשתמש שירות מהיר.",
      'החברה וספקים שונים של צדדים שלישיים משתמשת ב"עוגיות" ("cookies") על מנת להציג מודעות עבורך בהתאמה אישית, על פי ביקוריך הקודמים באתרים שונים. ',
      'המשתמש רשאי לבטל הסכמתו לפרסום מותאם אישית האמור באמצעות הגדרות המודעות שבדפדפו האינטרנט שבו הנך עושה שימוש, או לחלופין באמצעות אתר  www.aboutads.info.'

    ]
  },
  {
    title: "פרסומות של צדדים שלישיים",
    list: [
      "החברה משתמשת בצדדים שלישיים לניהול הצגת הפרסומות באתר וכן משתמשת במערכות מטעם חברות אחרות כדי לנהל את מערך הצגת הפרסומות באתר.",
      'לצורך ניהול הפרסומות, יכול והצדדים השלישיים ישתמשו  במחשבך באובייקטים שונים כגון Cookies ומשבצות "משואות רשת" (web beacons) במודעות הפרסומות או בדפי האתר, כאמור לעיל.  המידע הנאסף אינו מזהה אותך אישית אלא רק מתאים את הפרסומות שיוצגו בפניך כמפורט לעיל. השימוש שחברות אלה עושות ב-Cookies כפוף למדיניות הפרטיות שלהן בלבד והנך מוזמן לבדוק את מדיניות הפרטיות של אותן חברות באמצעות אתרי האינטרנט שלהן.',
      "כמו כן באפשרותך לבטל הסכמתך להצגת מודעות אלו באופן המתואר לעיל."
    ]
  },
  {
    title: "קניין רוחנ",
    list: [
      "עיצוב האתר, המידע המופיע בו, התוכנה, סימני המסחר, יישום, טקסט, קובץ גרפי, תוכן וכל חומר אחר, הינם קניינה הבלעדי של אזורים או של צדדים שלישיים שהתירו לאזורים להשתמש בהם והם מוגנים על ידי הדין, לרבות חוקי מדינת ישראל, אמנות בינלאומיות ודיני מדינות זרות. שימוש בקניין הרוחני באתר מחייב הסכמה בכתב ומראש של החברה וככל שלא נתקבלה ההסכמה האמורה נאסר עליו לגרום לכל שינוי או סילוף במידע, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות או למכור או להשכיר כל חלק מן המידע הכלול באתר ו/או לעשות שימוש בשמות ובסימנים המסחריים המוצגים באתר."
    ]
  },
  {
    title: "אחריות",
    list: [
      "המשתמש מצהיר ומתחייב לשאת ו/או לשפות ו/או לפצות את החברה בגין כל נזק שיגרם לה ו/או שותפיה ו/או סוכניה ו/או עובדיה ו/או נושאי המשרה ו/או המנהלים ו/או הדירקטורים ו/או בעלי המניות ו/או משתמשים אחרים וכו', כולל, אך לא רק, כל אחריות שהיא, תביעה, נזק ו/או הפסד (כולל הוצאות משפטיות), שינבעו כתוצאה משימוש, שימוש בלתי ראוי ו/או מוטעה, הפרעה ו/או כשלון ו/או כל פעולה אסורה על פי חוק המחשבים, התשנ'ה-1995, חוק הגנת הפרטיות, התשמ'א -1981, חוק איסור לשון הרע, התשכ'ה - 1965, חוק עוולות מסחריות, התשנ'ט - 1999, פקודת הנזיקין, התשכ'ח - 1968 ו/או על פי כל דין, שיעשה באתר ו/או באמצעותו, במידע, תכניו ו/או בשירותים בו."
    ]
  },
  {
    title: "הדין החל וסמכות שיפוט",
    list: [
      "תנאי שימוש אלה כפופים אך ורק לחוקי מדינת ישראל. כל טענה ו/או דרישה ו/או תביעה הנוגעת לתנאי שימוש אלה ו/או לשימוש באתר יהיו בסמכות שיפוט בלעדית של בית המשפט המוסמך בתל-אביב, ישראל."

    ]
  }
]

export const qanda = [
  {
    title: 'באיזה אופן מקבלים את המכרזים?',
    answer: 'מכרזים מגיע בדחיפה באמצעות סוכן חכם למייל על פי הגדרת התחום ותתי ענפים.'
  },
  {
    title:'האם המכרזים במגוון נושאים?',
    answer:'מכרזים מתפרסמים בכל ענפי המשק, מקוטלגים אצלנו על פי 70 ענפים.'
  },
  {
    title:'האם יש מספר מסלולים למנויים?',
    answer:'יש מסלולו שנתי ויש מסלול חודשי.'
  },
  {
    title:'האם השרות מתאים לכל בית עסק?',
    answer:'השרות מתאים לכל עסק שמחפש להגדיל את מקורות העבודה שלו.'
  },
  {
    title:'האם יש עזרה בהגשת מכרזים?',
    answer:'אנו מסייעים בהתאמת המכרז ואף נותנים ליווי מלא ללקוח במידת הצורך.'
  },
  {
    title:'אילו שירותים נוספים ניתן לקבל מיפעת מכרזים?',
    answer:" יש לנו מסלול של מידע מגוון בעיקר בענף הבנייה והשירותים הנילווים לענף: מאגר פרויקטים,  אנשים שבונים בתים פרטיים.מכרזי בנייה עם תמצית כתבי כמויות."
  }
]