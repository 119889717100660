import React, { Component } from "react";
import { string, number } from "prop-types";
class Adsense extends Component {
  googleInit = null;

  componentDidMount() {
    const { timeout } = this.props;
    this.googleInit = setTimeout(() => {
      if (typeof window !== "undefined") {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    }, timeout);
  }
  componentWillUnmount() {
    if (this.googleInit) {
      clearTimeout(this.googleInit);
    }
  }

  render() {
    const { slot, googleAdId, style } = this.props;
    return (
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-client={googleAdId}
        data-ad-slot={slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    );
  }
}
Adsense.propTypes = {
  className: string,
  slot: string,
  timeout: number,
  googleAdId: string,
};
Adsense.defaultProps = {
  timeout: 200,
};
export default Adsense;
