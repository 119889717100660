import React from "react";
import OtpInputComponent from "react-otp-input";
import "./otpInput.scss";

class OtpInput extends React.Component {
  handleChange = (otp) => {
    console.log(otp, "kl");
  };

  render() {
    return (
      <div styleName="otpInputContainer">
        <OtpInputComponent
          // value={this.state.otp}
          onChange={this.handleChange}
          numInputs={5}
          containerStyle="inputContainer"
        />
      </div>
    );
  }
}

export default OtpInput;
