import React, {Component} from 'react'
import { PulseLoader } from 'react-spinners'
//import CSSModules from 'react-css-modules'
import  './Loading.scss'


const Loading = ({}) => {

  return <div styleName="loading_continer" className='sweet-loading'>
    <PulseLoader
      color="#3ECD5A"
      loading={true}
    />
  </div>
}

export default Loading
