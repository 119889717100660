import React, { useState } from "react";
import "./auctionFilter.scss";

const tabs = [
  {
    text: "כל המכרזים",
  },
  { text: "תוצאות מכרזים" },
];

const AuctionFilter = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div styleName="auctionFilter">
      <div styleName="tab-container">
        {tabs.map((tab, index) => (
          <div
            styleName={`tab ${index == activeTab ? "active-tab" : ""} `}
            onClick={() => setActiveTab(index)}
          >
            {tab.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AuctionFilter;
