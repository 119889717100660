import React from "react";
import { object, func, bool } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import {
  HeartOutlined,
  HeartFilled,
  BellOutlined,
  BellFilled,
} from "@ant-design/icons";
import { setDateLabel, isDateInRange } from "common/utils/item";
import { getImageUrl } from "common/utils/util";
import moment from "moment";
import find from "lodash/find";
import replace from "lodash/replace";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import Checkbox from "common/components/Checkbox";
import SidePopupHOC from "common/components/SidePopupHOC";
import ResultsItemDetails from "common/components/ResultsItemDetails";
import ImageView from "common/components/ImageView";
import Reminder from "common/components/Reminder";
import LoginDialog from "common/components/LoginDialog";
import { Link } from "react-router-dom";
import { newTabSrc, Delete1 } from "common/style/icons";

import "./ResultsItem.scss";

/*const req = require.context("common/style/icons/", false);
const timeSrc = req("./Time.svg").default;
const timeActSrc = req("./alert_on.svg").default;
const favSrc = req("./fav.svg").default;
const favActSrc = req("./action_fav.svg").default;
const newTabSrc = req("./new_tab.svg").default;*/

export default
@translate()
@inject("accountStore")
@inject("searchStore")
@observer
class ResultsItem extends React.Component {
  static propTypes = {
    item: object,
    onCheck: func,
    onFav: func,
    setReminder: func,
    checked: bool,
    fav: bool,
  };

  @observable IsFavorite = false;
  @observable viewBig = false;
  @observable viewed = false;
  @observable showLoginMsg = false;
  @observable showImage = false;
  @observable imageUrl = "";
  @observable imageTitle = "";
  @observable remindMe = false;
  @observable showLoginMsg = false;
  @observable reminderID = null;
  @observable newReminderDate = "";
  @observable width = window.innerWidth;

  componentDidMount() {
    //set favorite state from props
    const {
      fav,
      item: { ReminderID, Visited },
    } = this.props;
    this.IsFavorite = fav;
    this.reminderID = ReminderID;
    this.viewed = Visited;
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentWillReceiveProps(nextProps, nextState) {
    //set favorite state from nextProps - ex. when Toolbar changes the item fav state
    const {
      fav,
      item: { ReminderID, Visited },
    } = nextProps;
    if (this.IsFavorite !== fav) this.IsFavorite = fav;
    this.reminderID = ReminderID;
    this.viewed = Visited;
  }

  updateDimensions = () => {
    this.width = window.innerWidth;
  };

  addFav = () => {
    const { item, onFav, accountStore } = this.props;
    if (accountStore.profile) {
      //callee + local fav state
      onFav(item.TenderID, !this.IsFavorite);
      this.IsFavorite = !this.IsFavorite;
    } else {
      this.showLoginMsg = true;
    }
  };

  // viewDetails = (id) => {
  //   console.log("hii");
  //   const { accountStore } = this.props;
  //   // if (accountStore.profile) {
  //   this.viewBig = true;
  //   this.viewed = true;
  //   // } else {
  //   //   this.showLoginMsg = true;
  //   // }
  // };

  viewDetails = (id) => {
    const { accountStore } = this.props;
    if (accountStore.profile) {
      this.viewBig = true;
      this.viewed = true;
    } else {
      this.showLoginMsg = true;
    }
  };

  closeDetails = () => {
    this.viewBig = false;
  };

  showViewer = (fileName, title) => {
    const { accountStore } = this.props;
    if (accountStore.profile) {
      const url = getImageUrl(fileName);
      this.imageUrl = url;
      this.imageTitle = title;
      this.showImage = true;
      document.body.style.overflowY = "hidden";
    }
  };

  closeViewer = () => {
    this.showImage = false;
    document.body.style.overflowY = "visible";
  };

  markUpText = (text) => {
    /* highlight text if text search\text filter was made */
    const { searchStore } = this.props;
    //get text filter or text tag
    const filtered = find(searchStore.filters, (filter) => {
      return filter.field == "searchtext";
    });
    /*
    const tag = find(searchStore.tags, tag => {
      return tag.ResType == 'tender_partial'
    })*/
    const tags = filter(searchStore.tags, (tag) => {
      return tag.ResType == "tender_partial";
    });
    //alter the text to inject as html
    let fixedText =
      filtered && filtered.values[0] && filtered.values[0].length > 2
        ? replace(
            text,
            new RegExp(filtered.values[0], "g"),
            `<span style="background-color: yellow;font-size:1.6rem !important;font-family:'Atlas Regular'">${filtered.values[0]}</span>`
          )
        : text;
    //fixedText = tag ? replace(fixedText, new RegExp(tag.Name, 'g'), `<span style="background-color: yellow">${tag.Name}</span>`): fixedText
    forEach(tags, (tag) => {
      fixedText =
        tag.Name.length > 2
          ? replace(
              fixedText,
              new RegExp(tag.Name, "g"),
              `<span style="background-color: yellow;font-size:1.6rem !important;font-family:'Atlas Regular'">${tag.Name}</span>`
            )
          : fixedText;
      //fixedText = replace(fixedText, new RegExp(`(\s|^)${tag.Name}(?=\s|$)`, 'g'), `<span style="background-color: yellow">${tag.Name}</span>`)
    });
    return { __html: fixedText };
  };

  remind = (open) => {
    const { accountStore } = this.props;
    if (accountStore.profile) {
      this.remindMe = open;
    } else {
      this.showLoginMsg = true;
    }
  };

  setReminderData = (id, date) => {
    //when reminder data changes (created\updated\deleted),
    //need to update the date label and current reminderID
    this.reminderID = id;
    this.newReminderDate = date;
  };

  notlogged = () => {
    this.showLoginMsg = true;
  };

  continueUnlogged = () => {
    this.showLoginMsg = false;
  };

  hasReminder =
    this.newReminderDate &&
    this.newReminderDate != null &&
    this.newReminderDate != "";

  render() {
    const { accountStore, item, onCheck, checked, onFav, t } = this.props;
    const cbItem = Object.assign({}, item, {
      checked,
      IsFavorite: this.IsFavorite,
    }); //merge this.IsFavorite to current item
    //if logged:
    const logged = accountStore.profile ? true : false;
    //display issues
    const publishDate = setDateLabel(
      item.PublishDate,
      "DD-MM-YYYY",
      t("tender.noDate")
    );
    const tourDate = item.TourDate
      ? setDateLabel(item.TourDate, "DD-MM-YYYY", t("tender.noDate"))
      : null;
    const infoDate = item.InfoDate
      ? setDateLabel(item.InfoDate, "DD-MM-YYYY", t("tender.noDate"))
      : null;
    const tenderStyle = checked ? "tender_summery checked" : "tender_summery";
    //infoDate
    const twoDaysLeft = isDateInRange(item.InfoDate, 2);
    const oneDayLeft = isDateInRange(item.InfoDate, 1);
    const noDaysLeft = isDateInRange(item.InfoDate, 0);
    //tourDate
    const twoDaysLeftTour = isDateInRange(item.TourDate, 2);
    const oneDayLeftTour = isDateInRange(item.TourDate, 1);
    const tourToday = isDateInRange(item.TourDate, 0);
    const mustDoTourLabel =
      (twoDaysLeftTour || oneDayLeftTour || tourToday) && item.MustDoTour
        ? ` - ${t("tender.mustTour")}`
        : "";
    //visited
    const visitedStyle = this.viewed ? " visited" : "";

    const actions = (
      <div styleName="tender_action_wraper">
        {this.props.onFav && (
          <a onClick={this.addFav}>
            {window.location.pathname === "/favorites" ? (
              <img src={Delete1} alt="delete" />
            ) : !this.IsFavorite ? (
              <HeartOutlined styleName="icon" />
            ) : (
              <HeartFilled styleName="icon" />
            )}
          </a>
        )}
        <a onClick={() => this.remind(true)}>
          {this.reminderID ? (
            <BellFilled styleName="icon" />
          ) : (
            <BellOutlined styleName="icon" />
          )}
        </a>
      </div>
    );

    return (
      <div styleName={tenderStyle}>
        <div styleName="item_continer">
          {onCheck && (
            <Checkbox checked={checked} item={cbItem} onChange={onCheck} />
          )}
          <div styleName="content_continer">
            <div styleName="tender_txt_wraper">
              <div styleName="title-subtitle">
                <div>
                  {item.TenderType == t("tender.exclusive") && (
                    <span styleName="label" className="label">
                      {t("tender.exclusive")}
                    </span>
                  )}
                  {twoDaysLeft && !oneDayLeft && !noDaysLeft && (
                    <span styleName="label alert">
                      {t("tender.twoDaysLeft")}
                    </span>
                  )}
                  {oneDayLeft && !noDaysLeft && (
                    <span styleName="label alert">
                      {t("tender.oneDayLeft")}
                    </span>
                  )}
                  {noDaysLeft && (
                    <span styleName="label alert">
                      {t("tender.noDaysLeft")}
                    </span>
                  )}
                  {twoDaysLeftTour && !oneDayLeftTour && !tourToday && (
                    <span styleName="label alert">{`${t(
                      "tender.twoDaysLeftTour"
                    )}${mustDoTourLabel}`}</span>
                  )}
                  {oneDayLeftTour && !tourToday && (
                    <span styleName="label alert">{`${t(
                      "tender.oneDayLeftTour"
                    )}${mustDoTourLabel}`}</span>
                  )}
                  {tourToday && (
                    <span styleName="label alert">{`${t(
                      "tender.noDaysLeftTour"
                    )}${mustDoTourLabel}`}</span>
                  )}
                  {item.MustDoTour &&
                    !twoDaysLeftTour &&
                    !oneDayLeftTour &&
                    !tourToday && (
                      <span styleName="label alert">
                        {t("tender.mustDoTour")}
                      </span>
                    )}
                  <h3
                    onClick={() => this.viewDetails(item.TenderID)}
                    styleName={`item-title${visitedStyle}`}
                    dangerouslySetInnerHTML={this.markUpText(item.Title)}
                  ></h3>
                  <Link
                    to={`/tender/${item.EncID}`}
                    target="_blank"
                    styleName="new_tab"
                  >
                    <img src={newTabSrc} />
                  </Link>
                </div>
                <div styleName="subTitle">{item.Publisher}</div>
              </div>
              {logged && (
                <div styleName="tender_desc">
                  <p
                    dangerouslySetInnerHTML={this.markUpText(item.Summery)}
                  ></p>
                </div>
              )}
              {/* {this.IsFavorite && <div styleName="hline"></div>} */}
              <div styleName="actions_meta">
                <div styleName="tender_meta">
                  <div>
                    {tourDate && (
                      <span>
                        <span>
                          {t("tender.tourAt")}: {tourDate}
                        </span>
                        <span styleName="divider">•</span>
                      </span>
                    )}
                    {logged && (
                      <span>
                        {infoDate && (
                          <span>
                            <span>
                              {t("tender.deliveryAt")}: {infoDate}
                            </span>
                            {/* <span styleName="divider">•</span> */}
                          </span>
                        )}
                        {/* <span>{item.Publisher}</span> */}
                        <span styleName="divider">•</span>
                      </span>
                    )}
                    <span>{item.TenderType}</span>
                  </div>
                  {/* {this.IsFavorite && (
                    <div
                      style={{ padding: 0 }}
                      styleName="tender_action_wraper"
                    >
                      {onFav && (
                        <a onClick={this.addFav}>
                          {!this.IsFavorite ? (
                            <HeartOutlined styleName="icon" />
                          ) : (
                            <HeartFilled styleName="icon" />
                          )}
                        </a>
                      )}
                      <a onClick={() => this.remind(true)}>
                        {hasReminder ? (
                          <BellFilled styleName="icon" />
                        ) : (
                          <BellOutlined styleName="icon" />
                        )}
                      </a>
                    </div>
                  )} */}

                  {/*<span styleName="divider">•</span>
              <span>#{item.TenderID}</span>*/}
                </div>
                {this.width <= 767 && actions}
              </div>
            </div>

            {this.width > 767 && actions}
          </div>
        </div>
        {this.viewBig && !this.showImage && (
          <SidePopupHOC
            isOpen={true}
            onClose={this.closeDetails}
            isClosedBtn={true}
            sidePopCustomClassName="resultDetailsPopUp"
          >
            <ResultsItemDetails
              itemID={item.TenderID}
              encryptedID={item.EncID}
              showViewer={this.showViewer}
              setReminderData={this.setReminderData}
              onFav={onFav}
              isEmail={false}
            />
          </SidePopupHOC>
        )}
        {this.viewBig && this.showImage && logged && (
          <ImageView
            onClose={this.closeViewer}
            url={this.imageUrl}
            title={this.imageTitle}
            tenderID={item.TenderID}
          />
        )}
        {this.remindMe && logged && (
          <Reminder
            tenderID={item.TenderID}
            encryptedID={item.EncID}
            onClose={() => this.remind(false)}
            setReminderData={this.setReminderData}
            title={item.Title}
            infoDate={item.InfoDate}
            reminderID={this.reminderID}
          />
        )}
        {this.showLoginMsg && <LoginDialog onCancel={this.continueUnlogged} />}
      </div>
    );
  }
}
