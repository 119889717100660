import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from 'react-player'
import { PlayCircleTwoTone } from "@ant-design/icons";
import "../home.scss";
import { playButton } from "common/style/icons";

const YouTubeTip = ({ title, thumbnail, url }) => {

  const [currentUrl, setCurrentUrl] = useState('')

  const YouTubePlayer = ({currentUrl, setCurrentUrl}) => {

    const wrapperRef = useRef(null);

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
    }, []);

    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCurrentUrl('');
      }
    };


    return <div styleName="youtube-video-container" ref={wrapperRef}>
      <ReactPlayer url={currentUrl} width={'100%'} playing={true} controls={true} />
    </div>
  }

  return (
    <div styleName="movie-item">
      <div
        onClick={() => setCurrentUrl(url)}
      // href={url} target="_blank"
      >
        <div styleName="image-container">
          {/* <div styleName="bg-icon">
            <img src={playButton} />
          </div> */}
          <PlayCircleTwoTone />
          <img src={thumbnail} />
        </div>
        <h3>{title}</h3>
      </div>
     {currentUrl && <YouTubePlayer currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} />}
    </div>
  );
};

//export default CSSModules(Opportunity, styles)
export default YouTubeTip;
