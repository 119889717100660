import React from "react";
import { closeModal, blueCheckbox } from "common/style/icons";
import "./LoginDialog.scss";
/*const req = require.context('common/style/icons/', false)
const closeModal = req('./closeModal.svg').default
const blueCheckbox = req('./blueCheckbox.svg').default*/

const LoginLeft = ({ onCancel, register, t, isMobile }) => {
  const itemsData = [
    t("loginSubscribe.listItem1"),
    t("loginSubscribe.listItem2"),
    t("loginSubscribe.listItem3"),
    t("loginSubscribe.listItem4"),
  ];

  return (
    <div styleName="leftContainer">
      <div styleName="close" onClick={onCancel}>
        <img src={closeModal} />
      </div>
      <div styleName="innerContainer">
        <div>
          <div styleName="heading heading1">{t("loginSubscribe.title")}</div>
          {!isMobile && <div styleName="divider"></div>}
        </div>
        <div>
          <div
            styleName="subHeading"
            dangerouslySetInnerHTML={{
              __html: t("loginSubscribe.subTitle", {
                a: "<span>לכם</span>",
              }),
            }}
          />

          {itemsData.map((item, index) => {
            return (
              <div key={`item--${index}`} styleName="item">
                <div styleName="label">{item}</div>
                <div styleName="icon">
                  <img src={blueCheckbox} />
                </div>
              </div>
            );
          })}
        </div>
        <button styleName="btn" onClick={register}>
          {t("loginSubscribe.subscribe")}
        </button>
          {/* <div styleName="footer">
            מעוניינים בפרטים נוספים?
            <span> צרו איתנו קשר</span>
          </div> */}
      </div>
    </div>
  );
};

export default LoginLeft;
