import React, {Component} from 'react'
import {translate} from 'react-polyglot'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

import './feedback.scss'

const req = require.context('common/style/icons', false)
const blueCheckbox = req('./blueCheckbox.svg')
const grayCheckboxUnchecked = req('./grayCheckboxUnchecked.svg')

export default 
@translate()
@observer
class Question extends Component {

    @observable isChecked = true
    
    setStatus = (yes) => {
        const { index, setAnswer } = this.props
        if (yes) {
            this.isChecked = true
        }
        else {
            this.isChecked = false
        }
        setAnswer(index, this.isChecked)
    }

    render() {
        const { question, t } = this.props
        return <div styleName="questions-div">
            <div styleName="question">{question}</div>
            <div styleName={`button ${this.isChecked ? 'selected' : ''}`} onClick={() => this.setStatus(true)}>
                <div>{this.isChecked ? <img src={blueCheckbox} /> : <img src={grayCheckboxUnchecked} />}</div>
                <div styleName="button-text">{t('feedback.yes')}</div>
            </div>
            <div styleName={`button ${!this.isChecked ? 'selected' : ''}`} style={{ marginRight: '1rem'}} onClick={() => this.setStatus(false)}>
                <div>{!this.isChecked ? <img src={blueCheckbox} /> : <img src={grayCheckboxUnchecked} />}</div>
                <div styleName="button-text">{t('feedback.no')}</div>
            </div>
        </div>
    }
    
}