import React from "react";
import moment from "moment";

import "../home.scss";

//const req = require.context("common/style/icons/", false);
//const bg = req("./demo.jpg").default;

const Sample = ({ t, data, key }) => {
  return (
    <div styleName="sample-container" key={`sample-${key}`}>
      <div styleName="ribbon-wrapper">
        <div styleName="ribbon">{t('home.newOnSite')}</div>
      </div>
      <div>
        <div styleName="title">{data.tenderType}</div>
        <div styleName="heading">{data.title}</div>
      </div>
      <div>
        <div styleName="subHeading">{data.subsubjectName}</div>
        <div styleName="value">
          {moment(data.releaseDate).format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
};

export default Sample;
