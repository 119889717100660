import React from "react";
import { string } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
/*import filter from 'lodash/filter'
import remove from 'lodash/remove'
import find from 'lodash/find'*/
import { doFilter } from "common/utils/filter";
import { searchIcon } from "common/style/icons";

import "./SearchTextFilter.scss";

/*const req = require.context('common/style/icons/', false)
const searchIcon = req('./searchIcon.svg').default*/

export default
@translate()
@inject("searchStore")
@observer
class SearchTextFilter extends React.Component {
  static propTypes = {
    text: string,
  };

  @observable text = "";

  componentDidMount() {
    const { text } = this.props;
    this.text = text;
  }

  componentWillReceiveProps(nextProps) {
    const { text } = nextProps;
    this.text = text;
  }

  doFilter = () => {
    const { searchStore } = this.props;
    //if (!this.text || this.text == null) this.text = ''
    doFilter(searchStore, "searchtext", [this.text]);
  };

  onChange = (e) => {
    this.text = e.target.value;
    //console.log(toJS(this.text))
    //this.doFilter()  //too slow
  };

  onKeyDown = (e) => {
    this.text = e.target.value; //may cause null if it does not happen here
    if (e.keyCode === 13) {
      setTimeout(() => {
        this.doFilter();
      }, 150); //to allow action to complete
    }
  };

  onBlur = (e) => {
    this.text = e.target.value; //may cause null if it does not happen here
    //console.log(toJS(this.text))
    setTimeout(() => {
      this.doFilter();
    }, 150); //to allow action to complete
  };

  render() {
    const { t, isMobile } = this.props;
    return (
      <div styleName={`free_search ${isMobile ? "padding-bottom" : ""}`}>
        <h4>{t("filter.searchText")}</h4>
        <div styleName="input-container">
          <input
            type="text"
            placeholder={t("filter.search")}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
          />
          <div styleName="icon">
            <img src={searchIcon} />
          </div>
        </div>
      </div>
    );
  }
}
