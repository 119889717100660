import React, {Component} from 'react'
import QandA from 'components/QandA'


export default class QandAPage extends Component {

  render(){
    return (
      <div>
        <QandA />
      </div>
    )
  }
}