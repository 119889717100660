import React, { Component, useState } from 'react'
import SearchInput from 'common/components/SearchInput'
import { profile1, profile2, profile3, playIcon, appleIcon, roboIcon, instLogo } from 'common/style/icons'
import { Link } from 'react-router-dom'

import "../home.scss";
/*const req = require.context('common/style/icons/', false)
const profile1 = req('./profile1.png').default
const profile2 = req('./profile2.png').default
const profile3 = req('./profile3.png').default
const playIcon = req('./playStoreIcon.png').default
const appleIcon = req('./appleIcon.png').default
const roboIcon = req('./roboIcon.png').default*/

const Instructions = ({ t }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    { id: 0, name: t("instructions.app") },
    { id: 1, name: t("instructions.experts") },
    { id: 2, name: t("instructions.helper") },
    { id: 3, name: t("instructions.escort") },
  ];

  const persons = [
    {
      name: t("instructions.person1"),
      subName: t("instructions.person1Sub"),
      src: profile2,
    },
    {
      name: t("instructions.person2"),
      subName: t("instructions.person2Sub"),
      src: profile3,
    },
    {
      name: t("instructions.person3"),
      subName: t("instructions.person3Sub"),
      src: profile1,
    },
  ];

  const Content1 = () => {
    const onAppleIconClick = () =>
      window.open(
        "https://itunes.apple.com/il/app/tendersmobi/id924910356?mt=8"
      );

    const onPlayIconClick = () =>
      window.open(
        "https://play.google.com/store/apps/details?id=com.ifat.tenders"
      );

    return (
      <div styleName="content-1">
        <div>
          <div styleName="para">{t("instructions.appDownloadTitle")}</div>
          {/* <div styleName="link" style={{ textDecoration: "none" }}>{t("instructions.appDownload")}</div> */}
        </div>
        {/* <div styleName="icons">
          <img styleName="button" src={appleIcon} onClick={onAppleIconClick} />

          <img styleName="button" src={playIcon} onClick={onPlayIconClick} />
        </div> */}
      </div>
    );
  };

  const Content2 = () => {
    return (
      <div styleName="content-2">
        <div styleName="heading">{t("instructions.consultants")}</div>
        <div style={{ marginBottom: "2.5rem" }}>
          {persons.map((person, index) => {
            return (
              <div key={`person-${index}`} styleName="box">
                <div styleName="content-2">
                  <div styleName="title">{person.name}</div>
                  <div styleName="subTitle">{person.subName}</div>
                </div>
                <div>
                  <img styleName="img" src={person.src} />
                </div>
              </div>
            );
          })}
        </div>

        {/* <div styleName="link"> */}
        <Link to={'/contact'} style={{ cursor: "pointer" }} styleName='link'>
          {t("instructions.freeCall")}
        </Link>
        {/* </div> */}
      </div>
    );
  };

  const Content3 = () => {
    return (
      <div styleName="content-1">
        <div>
          <div styleName="para">{t("instructions.helperEx")}</div>
          <Link to={'/contact'} style={{ cursor: "pointer" }} styleName='link'>
            {t("instructions.helperJoin")}
          </Link>
        </div>
        <div styleName='robo-icon'>
          <img src={roboIcon} />
        </div>
      </div>


    );
  };

  const Content4 = () => {
    return <div styleName='content-1'>
      <div>
        <img styleName='inst-logo' src={instLogo} />
        <div styleName='para'>
          {t('instructions.helperEx')}
        </div>
      </div>
    </div>
  }

  const returnContent = () => {
    switch (currentTab) {
      case 0:
        return <Content1 />
      case 1:
        return <Content2 />
      case 2:
        return <Content3 />
      case 3:
        return <Content4 />
      default:
        return <Content1 />
    }
  };

  return <div styleName='instructions-container'>
    <div styleName='left'>
      <div styleName='content'>
        {returnContent()}
      </div>
      <div styleName='tabs'>
        {tabs.map((item, index) => {
          return <div onClick={() => { setCurrentTab(item.id) }} styleName={`tab ${item.id === currentTab ? 'active' : ''}`} key={`tab-${index}`}>
            {item.id == 2 && <div styleName='texts-container'>
              <div styleName='left-text'>Smart</div>
              <div styleName='right-text'>Assistant</div>
            </div>}
            {item.name}
          </div>
        })}
      </div>
    </div>
    <div styleName='right'>
      <div styleName='heading'>
        {t('instructions.opportunities')}
      </div>
      <div styleName='subHeading'>
        {t('instructions.opportunitiesSub')}
      </div>
      <div styleName='links'>
        <Link to={'/contact'} style={{ cursor: "pointer" }}>
          <div styleName='button'>{t('instructions.click')}</div>
        </Link>
        <Link to={'/contact'} style={{ cursor: "pointer" }}>
          <div styleName='link'>{t('instructions.moreDetails')}</div>
        </Link>
      </div>
    </div>
  </div>

};

export default Instructions;
