import React from "react";
import { string, object } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import { PlusOutlined } from "@ant-design/icons";
import filter from "lodash/filter";
import find from "lodash/find";
import remove from "lodash/remove";
//import forEach from 'lodash/forEach'
import take from "lodash/take";
import { doFilter } from "common/utils/filter";
import { searchIcon } from 'common/style/icons'
import Checkboxes from "../../../../common/components/Checkboxes";

import "./MultipleFilter.scss";

/*const req = require.context("common/style/icons/", false);
//const editSrc = req("./icon_edit.svg").default;
const searchIcon = req('./searchIcon.svg').default
*/

export default
@translate()
@inject("searchStore")
@inject("routingStore")
@observer
class MultipleFilter extends React.Component {
  /* component for multiple values filter selection */
  //note: implemented for subsubjects and publishers only. need to find a more generic way to check the types
  //(such as item.SubSubjectName vs. item.PublisherName)

  static propTypes = {
    type: string,
    items: object,
    label: string,
  };

  @observable open = false;
  @observable type = "";
  @observable items = [];
  @observable selectedItems = [];
  @observable label = "";

  componentDidMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init = (props) => {
    const { type, items, label } = props;
    this.type = type;
    this.label = label;
    this.items = items || []; //init for checkSubsubjects()...
    this.checkSubsubjects()   //subsubject -> tag crap
    this.sortChecked(items);
  };

  sortChecked = (items=[]) => {
    //place checked items on top:
    const selectedItemsSet = new Set(this.selectedItems.map(item => item.id))
    const checkedItems = items.filter((item) => {
      if(this.type === "publishers") {
        return selectedItemsSet.has(item.PublisherID)
      }
      return selectedItemsSet.has(item.SubjectID) || item.SubSubjects.some(subSubject => selectedItemsSet.has(subSubject.SubSubjectID))
    })
    const unCheckedItems = items.filter((item) => {
      if(this.type === "publishers") {
        return !selectedItemsSet.has(item.PublisherID)
      }
      return !selectedItemsSet.has(item.SubjectID) && !item.SubSubjects.some(subSubject => selectedItemsSet.has(subSubject.SubSubjectID))
    })
    this.items =  [...checkedItems,...unCheckedItems]
  };

  checkSubsubjects = () => {
    //check if store filters contain subsubject filter
    if(this.type !== "subsubjects") {
      return;
    }
    const { searchStore, t } = this.props;
    let newSelectedItems = []
    const tags = filter(searchStore.tags, (tag) => {
      return tag.ResType == "subsubject" || tag.ResType == "subject";
    });
    if(tags.length === 0) {
      return;
    }
    newSelectedItems = tags.map((tag)=> {
      return { id: tag.ID, name: tag.Name, type: tag.ResType }
    })
    const labels = newSelectedItems.map((item) => item.name).join(",")
    searchStore.setSelectedFilters(
      "subsubject",
      labels,
      t("filter.more")
    );
    this.selectedItems = newSelectedItems
  };

  openModal = () => {
    this.open = true;
  };

  closeModal = () => {
    this.open = false;
  };

  handleFilter = () => {
    //commit filters
    const { searchStore, t } = this.props;
    if (this.type == 'subsubjects') {  //subsubject -> tag crap
      //subsubjects: act like a search, not like a filter ...
      const subjectAndSubSubjectsTags = this.selectedItems
        .filter(item => item.type === "subject" || item.type === "subsubject")
        .map((item) => {
          return { I: item.id, R: item.type, U: parseFloat(`${item.id}.1`) }
        })
      //route list SearchInput, to enable a new search
      const { routingStore } = this.props
      const sort = 'publishDate'  //default sort. note, means that on every search action, sort will reset here
      remove(searchStore.tags, (tag) => {
        return tag.ResType === "subsubject" || tag.ResType === "subject";
      })
      const newTags = [...toJS(searchStore.tags), ...toJS(subjectAndSubSubjectsTags)]
      const payload = JSON.stringify(newTags)
      const filters = JSON.stringify([]) //...(searchStore.filters)
      routingStore.push(`/results/${sort}/${payload}/${filters}`)
      this.closeModal()
    }
    
    else { 
    //normal filter behavior
    const selectedPulisherIds = this.selectedItems.map(item => item.id)
    const selectedPulisherName = this.selectedItems.map(item => item.name)
      doFilter(
        searchStore,
        "publisher",
        selectedPulisherIds,
        selectedPulisherName,
        true,
        this.closeModal,
        t("filter.more")
      );
    }
  };

  filterItems = (e) => {
    //filter the checkboxes by text field value
    const { items } = this.props;
    const reduced = filter(
      items,
      (item) => {
        if (this.type == "subsubjects") {
          const subjectFound =  item.SubjectName.includes(value);
          const subSubjectFound = toJS(item.SubSubjects).some((subSubject) => subSubject.SubSubjectName.includes(value))
          return subjectFound || subSubjectFound
        } else {
          return item.PublisherName.indexOf(e.target.value) > -1;
        }
      },
      this
    );
    this.items = reduced;
  };

  onInputCheck = (e, options) => {
    const {name,value,checked} = e.target
    let newSelectedValues = toJS(this.selectedItems)
    newSelectedValues = checked ? [...newSelectedValues,{ name, id: +value, type: options.type }] : newSelectedValues.filter(item => item.id !== +value)
    if(options.type === "subject") {
        const subSubjectsSet = new Set(options.subSubjects.map(subSubject => subSubject.SubSubjectID))
        newSelectedValues = newSelectedValues.filter((selectedValue) => !subSubjectsSet.has(selectedValue.id))
    } else if(options.type === "subsubject") {
        newSelectedValues = newSelectedValues.filter((value) => value.id !== options.subjectID)
    }
    this.selectedItems = newSelectedValues
  }

  onCheckAll = (checked) => {
    if(!checked) {
      this.selectedItems.clear()
      return;
    }
    const selectedItemsSet = new Set(this.selectedItems.map(item => item.id))
    const newSelectedItems = [...toJS(this.selectedItems)]
    this.items.forEach((item) => {
      if(this.type === "publishers" && !selectedItemsSet.has(item.PublisherID)) {
        newSelectedItems.push({id: item.SubjectID,name: item.SubjectName, type: "publisher"})
        this.selectedItems = newSelectedItems
        return;
      }
      if(!selectedItemsSet.has(item.SubjectID)) {
        newSelectedItems.push({id: item.SubjectID,name: item.SubjectName, type: "subject"})
      }
      if(item.SubSubjects.length === 0) {
        return;
      }
      item.SubSubjects.forEach((subSubject) => {
        if(!selectedItemsSet.has(subSubject.SubSubjectID)) {
          newSelectedItems.push({id: subSubject.SubSubjectID,name: subSubject.SubSubjectName, type: "subsubject"})
        }
      })
    })
    this.selectedItems = newSelectedItems
  };

  render() {
    const { t } = this.props;
    const title =
      this.type == "subsubjects"
        ? t("filter.subSubjectsTitle")
        : t("filter.publishersTitle");
    const tileStyle = this.type != "subsubjects" ? { marginBottom: "3px" } : {};
    const selectedItemsWithIds = this.selectedItems?.map(item => item.id)
    return (
      <div styleName="cb-wrapper">
        {this.open && (
          <div className="reveal-overlay" style={{ display: "block" }}>
            <div
              className="reveal"
              styleName="multiple-selection"
              style={{ display: "block" }}
            >
              <div styleName="">
                <h2>
                  {title}
                  {this.type == "subsubjects" && (
                    <div styleName="selectAll_links">
                      {/* <a onClick={() => this.onCheckAll(true)}>
                        {t("filter.selectAll")}
                      </a> */}
                      {/* <span styleName="sep">|</span> */}
                      <a onClick={() => this.onCheckAll(false)}>
                        {t("filter.clearAll")}
                      </a>
                    </div>
                  )}
                </h2>

                <div styleName='inputs-container'>
                  <input type="text"
                    placeholder={t("filter.search")}
                    onChange={this.filterItems}

                  />
                  <div styleName='icon'>
                    <img src={searchIcon} />
                  </div>
                </div>
                {this.type == "subsubjects" && <div></div>}
                <div style={{ height: "300px", overflow: "auto", marginTop: '2.5rem' }}>
                  <Checkboxes 
                    items={this.items} 
                    selectedItems={selectedItemsWithIds} 
                    onCheck={this.onInputCheck} 
                    type='multi1' 
                    categoryType={this.type} 
                    columns={3}
                  />

                  {/* {this.items &&
                    this.items.map((item, index) => {
                      const id =
                        this.type == "subsubjects"
                          ? item.SubSubjectID
                          : item.PublisherID;
                      const name =
                        this.type == "subsubjects"
                          ? item.SubSubjectName
                          : item.PublisherName;
                      return (
                        <div styleName="checkbox" key={index}>
                          <label styleName="cb-label">
                            <input
                              type="checkbox"
                              styleName="checkbox"
                              checked={this.selected.includes(id)}
                              name={name}
                              value={id}
                              onChange={this.onCheck}
                            />
                            <span>{name}</span>
                          </label>
                        </div>
                      );
                    }, this)} */}
                </div>
                <div styleName="selected">
                  {this.selectedItems &&
                    take(this.selectedItems, 2).map((item, index) => (
                      <div
                        key={`item_${index}`}
                        styleName="selected-tile"
                        style={tileStyle}
                      >
                        {item.name}
                      </div>
                    ))}
                  {this.selectedItems && this.selectedItems.length > 2 && (
                    <div styleName="selected-tile">
                      {`${t("filter.more")} ${this.selectedItems.length - 2}`}
                    </div>
                  )}
                </div>
                <div styleName="button-container">
                  <a
                    className="button"
                    styleName="button-submit"
                    onClick={this.handleFilter}
                  >
                    {t("filter.choose")}
                  </a>
                  <a styleName="button-cancel" onClick={this.closeModal}>
                    {t("filter.cancel")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div styleName="container">
          <div styleName='inner-cont'>
            {title}
            <div styleName="add_button" onClick={this.openModal}>
              <PlusOutlined />
            </div>
          </div>
          <span styleName='selected-labels'>{this.label}</span>
        </div>

      </div>
    );
  }
}
