import React from "react";
import { string, object } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import filter from "lodash/filter";
import find from "lodash/find";
import remove from "lodash/remove";
import take from "lodash/take";
// import { doFilter } from "common/utils/filter";
import Checkboxes from "../../Checkboxes";
import Modal from "../../../components/Modal";
import { searchIcon } from "common/style/icons";
import "./SubSearch.scss";
import mobile from "is-mobile";
import MobileSubSearch from "./MobileSubSearch";
/*
const req = require.context('common/style/icons/', false)
const editSrc = req('./icon_edit.svg').default
*/
export default
@translate()
@inject("searchStore")
@inject("routingStore")
@observer
class SubSearch extends React.Component {
  /* component for multiple values filter selection */

  static propTypes = {
    items: object,
  };

  @observable open = false;
  @observable items = [];
  @observable selectedItems = [];
  @observable isMobile = false;

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    this.isMobile = mobile();
    this.init(this.props);
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  init = (props) => {
    const { items } = props;
    this.items = items;
    this.onCheckAll(false);
    this.checkSubsubjects(); //subsubject -> tag crap
    this.sortChecked(items);
  };

  sortChecked = (items = []) => {
    const selectedItemsSet = new Set(this.selectedItems.map(item => item.id))
    const checkedItems = items.filter((item) => {
      return selectedItemsSet.has(item.SubjectID) || item.SubSubjects.some(subSubject => selectedItemsSet.has(subSubject.SubSubjectID))
    })
    const unCheckedItems = items.filter((item) => !selectedItemsSet.has(item.SubjectID) && !item.SubSubjects.some(subSubject => selectedItemsSet.has(subSubject.SubSubjectID)))
    this.items =  [...checkedItems,...unCheckedItems]
  };

  checkSubsubjects = () => {
    //check if store filters contain subsubject filter
    const { searchStore } = this.props;
    const tags = filter(searchStore.tags, (tag) => {
      return tag.ResType == "subsubject" || tag.ResType == "subject";
    });
    if(tags.length === 0) {
      return;
    }
    this.selectedItems = tags.map((tag)=> {
      return { id: tag.ID, name: tag.Name, type: tag.ResType }
    })
  };

  openModal = () => {
    this.open = true;
  };

  closeModal = () => {
    this.open = false;
  };

  doFilter = () => {
    //commit filters
    const { searchStore } = this.props;

    const subjectAndSubSubjectsTags = this.selectedItems.map((item) => {
      return { I: item.id, R: item.type, U: parseFloat(`${item.id}.1`) }
    })
    
    //route list SearchInput, to enable a new search
    const { routingStore } = this.props;
    const sort = "publishDate"; //default sort. note, means that on every search action, sort will reset here
    remove(searchStore.tags, (tag) => {
      return tag.ResType === "subsubject" || tag.ResType === "subject";
    });
    //const newTags = [...searchStore.tags, ...tags]
    const newTags = [].concat(...searchStore.tags,subjectAndSubSubjectsTags);
    const payload = JSON.stringify(newTags);
    const filters = JSON.stringify([]);
    routingStore.push(`/results/${sort}/${payload}/${filters}`);
    this.closeModal();
  };

  filterItems = (e) => {
    //filter the checkboxes by text field value
    const {value} = e.target
    const { items } = this.props;
    const reduced = filter(
      items,
      (item) => {
        const subjectFound =  item.SubjectName.includes(value);
        const subSubjectFound = toJS(item.SubSubjects).some((subSubject) => subSubject.SubSubjectName.includes(value))
        return subjectFound || subSubjectFound
      },
      this
    );
    this.items = reduced;
  };

  onInputCheck = (e, options) => {
    const {name,value,checked} = e.target
    let newSelectedValues = toJS(this.selectedItems)
    newSelectedValues = checked ? [...newSelectedValues,{ name, id: +value, type: options.type }] : newSelectedValues.filter(item => item.id !== +value)
    if(options.type === "subject") {
        const subSubjectsSet = new Set(options.subSubjects.map(subSubject => subSubject.SubSubjectID))
        newSelectedValues = newSelectedValues.filter((selectedValue) => !subSubjectsSet.has(selectedValue.id))
    } else if(options.type === "subsubject") {
        newSelectedValues = newSelectedValues.filter((value) => value.id !== options.subjectID)
    }
    this.selectedItems = newSelectedValues
  }

  unCheck = (itemId) => {
    remove(this.selectedItems, (item) => {
      return item.id === itemId;
    });
  };

  onCheckAll = (checked) => {
    if(!checked) {
      this.selectedItems.clear()
      return;
    }
    const selectedItemsSet = new Set(this.selectedItems.map(item => item.id))
    const newSelectedItems = [...toJS(this.selectedItems)]
    this.items.forEach((item) => {
      if(!selectedItemsSet.has(item.SubjectID)) {
        newSelectedItems.push({id: item.SubjectID,name: item.SubjectName, type: "subject"})
      }
      if(item.SubSubjects.length === 0) {
        return;
      }
      item.SubSubjects.forEach((subSubject) => {
        if(!selectedItemsSet.has(subSubject.SubSubjectID)) {
          newSelectedItems.push({id: subSubject.SubSubjectID,name: subSubject.SubSubjectName, type: "subsubject"})
        }
      })
    })
    this.selectedItems = newSelectedItems
  };

  showSelectedLabels = () => {
    const { t } = this.props;
    const tileStyle = {};
    return (
      <div styleName="selected">
        {take(this.selectedItems, 2).map((item, index) => (
          <div key={index} styleName="selected-tile" style={tileStyle}>
            {item.name}
          </div>
        ))}
        {this.selectedItems.length > 2 && (
          <div styleName="selected-tile">
            {`${t("filter.more")} ${this.selectedItems.length - 2}`}
          </div>
        )}
      </div>
    );
  };

  getColumns = () => {
    if(this.state.width <= 576) {
      return 1;
    }
    if(this.state.width <= 768) {
      return 2;
    }
    return 3;
  }

  commonComponents = () => {
    const { t } = this.props;
    const title = t("filter.subSubjectsTitle");
    const selectedItemsWithIds = this.selectedItems?.map(item => item.id)
    return (
      <div>
        {
          this.open && (
            // (this.state.width > 767 ? (
            <div className="reveal-overlay" style={{ display: "block" }}>
              <div
                className="reveal"
                styleName="multiple-selection"
                style={{ display: "block" }}
              >
                {this.state.width < 768 && (
                  <div styleName="top-container">
                    {t("filter.subSearchPopupTitle")}
                    <ArrowLeftOutlined onClick={this.closeModal} />
                  </div>
                )}
                <div>
                  <h2>
                    {title}
                    <div styleName="selectAll_links">
                      <a onClick={() => this.onCheckAll(false)}>
                        {t("filter.clearAll")}
                      </a>
                    </div>
                  </h2>
                  <div styleName="input-container">
                    <input
                      type="text"
                      placeholder={t("filter.search")}
                      onChange={this.filterItems}
                    />
                    <div styleName="icon">
                      <img src={searchIcon} />
                    </div>
                  </div>
                  <div></div>
                  {this.state.width < 768 && this.selectedItems.length > 0 && (
                    <div styleName="selected-items-container">
                      <div styleName="content">
                        <div>{t("filter.activeFilter")}</div>
                        <div onClick={() => this.onCheckAll(false)}>
                          {t("filter.clearAll")}
                        </div>
                      </div>
                      <div styleName="tags">
                        {this.selectedItems.map((item) => (
                          <div styleName="tag" key={item.id}>
                            <CloseOutlined
                              onClick={() =>
                                this.unCheck(item.id)
                              }
                            />
                            <div>{item.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div
                    styleName={`checkboxes-inner-container ${this.state.width < 768 && this.selectedItems.length
                      ? "checkboxesContainer"
                      : ""
                      }`}
                    style={{
                      height: "300px",
                      overflow: "auto",
                      marginTop: "2.5rem",
                    }}
                  >
                    <Checkboxes
                      items={this.items}
                      onCheck={this.onInputCheck}
                      columns={this.getColumns()}
                      selectedItems={selectedItemsWithIds}
                    />
                  </div>
                  {!this.isMobile && this.showSelectedLabels()}

                  <div styleName="bottom-container">
                    {this.state.width > 767 ? (
                      <div styleName="button-container">
                        <a styleName="button-cancel" onClick={this.closeModal}>
                          {t("filter.cancel")}
                        </a>
                        <a
                          className="button"
                          styleName="button-submit"
                          onClick={this.doFilter}
                        >
                          {t("filter.choose")}
                        </a>


                      </div>
                    ) : (
                      <>
                        <div styleName="text">
                          {t("results.totalFilters", {
                            count: this.selectedItems.length,
                          })}
                        </div>
                        <div styleName="btn" onClick={() => {
                          this.doFilter()
                          this.closeModal()
                        }}>
                          {t("results.closeFiltersPopup")}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const title = t("filter.subSubjectsTitle");
    return (
      <div styleName="cb-wrapper">
        {this.isMobile && (
          <Modal
            show={this.open}
            title={t("filter.subSearchPopupTitle")}
            modalBodyOverrideStyle={{ padding: "0rem" }}
          >
            {this.commonComponents()}
          </Modal>
        )}
        {!this.isMobile && this.commonComponents()}
        <div>
          <a onClick={this.openModal}>{title}</a>
        </div>
      </div>
    );
  }
}