import React from "react";
import { useTranslate } from "react-polyglot";
import { Link } from "react-router-dom";
//import CSSModules from 'react-css-modules'
import "../home.scss";

const Article = ({ articleID, title, imgSrc, goTo }) => {
  const t = useTranslate();
  const url = `/article/${articleID}`;
  return (
    <div styleName="article-item">
      <Link to={url}>
        <img src={imgSrc} alt={title} />
        <h3 styleName="one-article-title">{decodeURIComponent(title)}</h3>
      </Link>
      <div styleName="read-more" onClick={() => goTo(url)}>{t("home.readMore")}</div>
    </div>
  );
};

export default Article;
