import React, { Component, lazy, Suspense } from 'react'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { getImageUrl, setFavStatus } from 'common/utils/util'
//import ResultsItemDetails from 'common/components/ResultsItemDetails'
import ImageView from 'common/components/ImageView'
import LoginDialog from 'common/components/LoginDialog'
import Loading from 'common/components/Loading/Loading'
import NotLogged from 'common/components/NotLogged'

import { getToken } from 'common/services/apiService'
import './tender.scss'

//lazy-load ResultsItemDetails.
//this is to avoid 401 error before all login procedures are completed (ex. profile cookie io op)
const ResultsItemDetails = lazy(() =>
  import('common/components/ResultsItemDetails')
);

export default
@withRouter
@inject('accountStore')
@inject('recordStore')
@observer
class Tender extends Component {
  @observable itemID = -1;
  @observable encryptedID = '';
  @observable showImage = false;
  @observable imageUrl = '';
  @observable imageTitle = '';
  @observable mode = 'singleItem';
  @observable isEmail = false;
  @observable showLoginMsg = true;

  componentDidMount() {
    const {
      match: {
        params: { itemId, mode, isemail },
      }
    } = this.props;
    //this.itemID = parseInt(itemId)
    this.encryptedID = itemId;
    if (mode) {
      this.mode = mode;
    }

    if (isemail) {
      this.isEmail = isemail.toLowerCase() === 'true'
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log('receive', nextProps)
    const { accountStore: { profile } } = nextProps
    if (profile) {
      setTimeout(() => {
        this.showLoginMsg = false
      }, 150);
    }
  }

  showViewer = (fileName, title) => {
    const url = getImageUrl(fileName);
    this.imageUrl = url;
    this.imageTitle = title;
    this.showImage = true;
  };

  closeViewer = () => {
    this.showImage = false;
  };

  onFav = (tenderID, add) => {
    const { accountStore, recordStore } = this.props;
    if (accountStore.profile) {
      setFavStatus(
        tenderID,
        add,
        recordStore.isInChecked,
        recordStore.push,
        recordStore.cut
      );
    }
  };

  continueUnlogged = () => {
    this.showLoginMsg = false;
  };

  render() {
    const {
      accountStore: { profile },
      match: {
        params: { mode },
      },
    } = this.props;
    return (
      <div styleName="tender-sample-page">
        {getToken() || (mode && mode == 'sample') ? (
          <div>
            <div>
              {!this.showImage ? (
                <Suspense fallback={<Loading />}>
                  {profile ? <ResultsItemDetails
                    onClose={this.closeViewer}
                    itemID={this.itemID}
                    encryptedID={this.encryptedID}
                    showViewer={this.showViewer}
                    mode={this.mode}
                    onFav={this.onFav}
                    isEmail={this.isEmail}
                  /> : <NotLogged />}
                </Suspense>
              ) : (
                <ImageView
                  onClose={this.closeViewer}
                  url={this.imageUrl}
                  title={this.imageTitle}
                  tenderID={this.itemID}
                />
              )}
            </div>
          </div>
        ) : (
          this.showLoginMsg && <LoginDialog onCancel={this.continueUnlogged} fromItem={this.encryptedID} />
        )}
      </div>
    );
  }
}
