import React, { useState } from "react";
import { useHistory } from "react-router";
import { useTranslate } from "react-polyglot";
import { useDimensions } from "common/utils/util";
import {
  bellGrey,
  heartSrc,
  heartGrey,
  searchIcon,
  logoSmall,
} from "common/style/icons";
import "./bottomBar.scss";

const menuItems = [
  {
    image: logoSmall,
    text: "bottomBar.assistant",
    link: "/main",
  },
  {
    image: searchIcon,
    text: "bottomBar.search",
  },
  {
    image: heartGrey,
    text: "bottomBar.favourites",
    link: "/favorites",
  },
  {
    image: bellGrey,
    text: "bottomBar.reminders",
    link: "/reminders",
  },
];

const BottomBar = () => {
  const { width } = useDimensions();
  const t = useTranslate();
  const history = useHistory();
  const [activeMenuIndex, setActiveMenuIndex] = useState();

  return (
    <>
      {width < 768 ? (
        <div styleName="bottomBar">
          {menuItems.map((menu) => (
            <div
              styleName={`menu ${
                window.location.pathname === menu.link ? "active-menu" : ""
              }`}
              key={menu.text}
              onClick={() => history.push(menu.link)}
            >
              <img src={menu.image} alt="menu-image" />
              <div>{t(menu.text)}</div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default BottomBar;
