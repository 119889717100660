import React from "react";
import { useTranslate } from "react-polyglot";
import Button from "../../common/components/Button";
import "./Topbar.scss";

const CustomerCareDetails = ({routingStore, closeDrawer}) => {
  const t = useTranslate();

  return (
    <div styleName="customer-details">
      <div styleName="contact-details">
        <div styleName="new-entrants">{t("nav.newEntrants")}</div>
        <Button text="03-563-5000" overrideClassName="button" />
      </div>
      <div styleName="service-timings">{t("nav.serviceTimings")}</div>
      <div styleName="row">
        <div styleName="col">
          <div styleName="label">{t("nav.customerService")}</div>
          <div styleName="value">03-309-5247</div>
        </div>
        <div styleName="col">
          <div styleName="label">{t("nav.technicalSupport")}</div>
          <div styleName="value">03-309-5247</div>
        </div>
      </div>
      <Button text={t("nav.publish")} overrideClassName="publication" onClick={() => {
        routingStore.push('/publish')
        closeDrawer()
      }} />
    </div>
  );
};

export default CustomerCareDetails;
