import React from 'react'
import {inject, observer} from 'mobx-react'
import { translate } from 'react-polyglot'
import {clearCache} from 'clear-cache'
import ReactInterval from 'react-interval'
import NotificationBar from 'react-notification-bar'
import { observable } from 'mobx'

import './checkVersion.scss'

export default
@translate()
@inject('accountStore')
@observer
class CheckVersion extends React.Component {
	
  @observable showVersion = false

	checkVersion = async () => {
    //console.log('checkVersion');
    const {accountStore} = this.props
    const version = await accountStore.checkVersion()
    if (version) {
      const currentVersion = localStorage.getItem('siteVersion')
      if (!currentVersion) {
        localStorage.setItem('siteVersion', version.VersionNumber)
      }
      else {
        if (currentVersion !== version.VersionNumber) {
          console.log('version change', currentVersion, version.VersionNumber)
          localStorage.removeItem('NOTIFICATIONBAR')  //allow open of new notification message
          this.showVersion = true
        }
      }
    }
  }

	reloadMe = () => {
    localStorage.removeItem('siteVersion')
    //console.log(window.caches);    
    if (window.caches) {
      try {
        clearCache()  //modern browser
      }
      catch(e) {
        window.location.reload()
      }
    }
    else {
      window.location.reload(true)  //a pile of crap like IE
    }
  }

	render() {
    const {t} = this.props
    return (
      <span>

        <ReactInterval timeout={300000} enabled={true}
          callback={() => this.checkVersion()} />        

        {this.showVersion && <NotificationBar          
          sound={false}
        >
          <span>{t('newVersion')}</span>
          <a onClick={this.reloadMe} styleName="version-link">{t('reload')}</a>         
        </NotificationBar>}
      </span>
    )
  }
}