import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { translate } from "react-polyglot";
import SidePopupHOC from "common/components/SidePopupHOC";
import NotLogged from "common/components/NotLogged";
import { envelope, Exclude, Include } from "common/style/icons";

import "./smartAgent.scss";

export default
@translate()
@inject("accountStore")
@inject("smartAgentStore")
@inject("routingStore")
@observer
class SmartAgent extends Component {
  @observable sent = false;
  @observable status = "";
  @observable definitionError = false;
  @observable email = "";
  @observable phone = "";
  @observable excludeHR = false;
  @observable excludeMod = false;
  @observable customerDetails = {};
  @observable frequencies = [];
  @observable tendertypes = [];
  @observable queries = [];
  @observable contacts = [];
  @observable word = "";
  @observable compareTo = "";

  componentDidMount() {
    const { smartAgentStore } = this.props;
    smartAgentStore.loadAgentSettings().then(() => {
      this.frequencies = smartAgentStore.results.Frequencies.filter(
        (frequency) => frequency.FrequencySelected == 1
      );
      this.tendertypes = smartAgentStore.results.TenderTypes.filter(
        (tendertype) => tendertype.TenderTypeSelected == 1
      );
      this.queries = smartAgentStore.results.Queries;
      this.contacts = smartAgentStore.results.Contacts;
      this.email =
        smartAgentStore.results.Contacts.length > 0
          ? smartAgentStore.results.Contacts[0].Email
          : "";
      this.phone =
        smartAgentStore.results.Contacts.length > 0
          ? smartAgentStore.results.Contacts[0].Cellular
          : "";
      this.excludeHR =
        smartAgentStore.results.Contacts.length > 0
          ? smartAgentStore.results.Contacts[0].ExcludeHR
          : false;
      this.excludeMod =
        smartAgentStore.results.Contacts.length > 0
          ? smartAgentStore.results.Contacts[0].ExcludeMOD
          : false;
      this.customerDetails = smartAgentStore.results.CustomerDetails;
    });
    smartAgentStore.loadSubSubjects();
    smartAgentStore.checkUser();
  }

  getBackground = (frequency) => {
    if (frequency.FrequencySelected) {
      return {
        backgroundColor: "#D6DDE2",
        borderRadius: "5rem",
        color: "#5D646B",
        paddingRight: "1.5rem",
      };
    }
    return null;
  };

  getFilterStyles = (selected) => {
    if (selected) {
      return null;
    }
    return {
      border: "1px solid #212129",
      opacity: 0.5,
    };
  };

  openContact = () => {
    const { routingStore } = this.props;
    routingStore.push(`/contact`);
  };

  render() {
    const {
      accountStore: { profile },
      smartAgentStore: {
        resultsLoading,
        results,
        query,
        ifatUser,
        estimatedCount,
        text,
      },
      t,
      closeSmartAgent,
    } = this.props;
    const style = this.sent ? "sent" : "errors";
    const defaultEmail =
      results && results.Contacts && results.Contacts.length > 0
        ? results.Contacts[0].Email
        : "";
    const defaultPhone =
      results && results.Contacts && results.Contacts.length > 0
        ? results.Contacts[0].Cellular
        : "";
    const toolTipData = ifatUser.ifat ? "" : t("agent.readOnly");
    return (
      <SidePopupHOC isOpen={true} onClose={closeSmartAgent} isClosedBtn={true}>
        <div>
          <div className="row" styleName="title-container">
            <div className="column large-12">
              <h1 styleName="title" data-tip={toolTipData}>
                {t("agent.title1")}
              </h1>
            </div>
          </div>
          <div styleName="user_details">
            <div style={{ marginBottom: "2.1rem" }} styleName="sub_title">
              {t("agent.userSubTitle")}
            </div>
            <div styleName="user_row">
              <div styleName="label_value">
                <div styleName="label">{t("agentview.userName")}:</div>
                <div styleName="value">{this.customerDetails.CustomerName}</div>
              </div>
              <div styleName="label_value">
                <div styleName="label">{t("agentview.address")}:</div>
                <div styleName="value">{this.customerDetails.Address}</div>
              </div>
            </div>
            <div styleName="user_row" style={{ marginTop: "1.6rem" }}>
              <div styleName="label_value">
                <div styleName="label">{t("agentview.companyNumber")}:</div>
                <div styleName="value">{this.customerDetails.RashamNumber}</div>
              </div>
              <div styleName="label_value">
                <div styleName="link">{t("agentview.updateDetails")} </div>
                <div styleName="link">
                  <u onClick={this.openContact}>{t("agentview.contactUs")}</u>
                </div>
              </div>
            </div>
          </div>
          <div styleName="divider" />
          <div className="row">
            <div className="column large-12">
              {profile ? (
                <div styleName="wrapper">
                  {!resultsLoading && (
                    <div>
                      <div styleName="sub_title">{t("agentview.alerts")}</div>
                      <div>
                        <div
                          styleName="ttl_container"
                          className="medium-3 cell"
                        >
                          <h4>{t("agent.destination")}</h4>
                        </div>
                        <div styleName="contact_container">
                          {defaultEmail && (
                            <div styleName="contact_detail">
                              <div styleName="text">{defaultEmail}</div>
                              <img src={envelope} />
                            </div>
                          )}
                          {defaultPhone && (
                            <div styleName="contact_detail">
                              <img src={envelope} />
                              <div styleName="text">{defaultPhone}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div styleName="value_container">
                        <div
                          styleName="ttl_container"
                          className="medium-3 cell"
                        >
                          <h4>{t("agent.reminderTime")}</h4>
                        </div>
                        <div styleName="agent_content">
                          {results.Frequencies &&
                            results.Frequencies.map((frequency, index) => (
                              <div
                                styleName="radio_text"
                                key={`frequency_${index}`}
                                style={this.getBackground(frequency)}
                              >
                                {frequency.FrequencyName}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div styleName="divider" />
                      <div styleName="sub_title">
                        {t("agentview.definitions")}
                      </div>
                      <div>
                        <div styleName="ttl_container">
                          <h4>{t("agent.infoTypes")}</h4>
                        </div>

                        <div styleName="filter_container">
                          {results.TenderTypes &&
                            results.TenderTypes.map((tendertype, index) => (
                              <div
                                key={`tendertype_${index}`}
                                styleName="filter"
                                style={this.getFilterStyles(
                                  tendertype.TenderTypeSelected
                                )}
                              >
                                {tendertype.TenderTypeName}
                                {tendertype.TenderTypeSelected !== 0 && (
                                  <div styleName="checked">
                                    <div styleName="tick" />
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div>
                        <div styleName="queryies">
                          {this.queries.map((query, index) => (
                            <div
                              styleName="filter_container"
                              style={{ flexDirection: "row" }}
                              key={`query_${index}`}
                            >
                              <div
                                styleName="ttl_container"
                                className="medium-3 cell"
                              >
                                <div styleName="includeExcludeIcon"><img src={query.Exclude ? Exclude : Include} /> </div>
                                <h4>
                                  {getQueryTitle(query, t("agentview.generalSearch"))}
                                </h4>
                              </div>
                              {query.SearchWords &&
                                query.SearchWords.split(",").map(
                                  (word, index) => (
                                    <div
                                      key={`word-${index}`}
                                      styleName="filter"
                                    >
                                      {word}
                                      {
                                        <div styleName="checked">
                                          <div styleName="tick" />
                                        </div>
                                      }
                                    </div>
                                  )
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div styleName="divider" />
                      <div styleName="sub_title">
                        {t("agentview.extensions")}
                      </div>
                      <div styleName="filter_container">
                        <div
                          styleName="filter"
                          style={this.getFilterStyles(this.excludeHR)}
                        >
                          {t("agentview.excludeHR")}
                          {this.excludeHR && (
                            <div styleName="checked">
                              <div styleName="tick" />
                            </div>
                          )}
                        </div>
                        <div
                          styleName="filter"
                          style={this.getFilterStyles(this.excludeMod)}
                        >
                          {t("agentview.excludeMod")}
                          {this.excludeMod && (
                            <div styleName="checked">
                              <div styleName="tick" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <NotLogged />
              )}
            </div>
          </div>
        </div>
      </SidePopupHOC>
    );
  }
}

const getQueryTitle = (query, defaultTitle) => {
  const { TenSubjectName, TenSubsubjectName } = query
  if (TenSubjectName && TenSubsubjectName) {
    return TenSubjectName + "." + TenSubsubjectName
  }
  return TenSubjectName ? TenSubjectName : TenSubsubjectName ? TenSubsubjectName : defaultTitle
}
