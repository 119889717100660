import React from 'react'
import './upIcon.scss'
import { UpArrow } from "common/style/icons";

const UpIcon = ({ }) => {

    const handleClick = () => {
        window.scrollTo(0, 0)
    }

    return <div styleName="upicon-container" onClick={handleClick}>
        <img src={UpArrow} />
    </div>
}

export default UpIcon
