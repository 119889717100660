import React, { useState } from "react";
import './QandA.scss'
import { Plus, Minus } from "common/style/icons";


const Question = ({index, question}) => {

    const [visible, setVisible] = useState(false)

    return <li key={index} styleName="termsTitle" >
        <img src={visible ? Minus : Plus} onClick={() => setVisible(!visible)} /> <strong>{question.title}</strong>
        {visible && <ol styleName="ol">
            <li key={index} >{question.answer}</li>
        </ol>}
    </li>
}

export default Question