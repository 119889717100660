import React from 'react'
import {number} from 'prop-types'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { translate } from 'react-polyglot'
import { setFeedback /*, clearCache*/ } from 'common/services/apiService'
import Button from 'common/components/Button'
import {
  LikeOutlined,
  DislikeOutlined,
} from '@ant-design/icons'
//import CSSModules from 'react-css-modules'
import  './Feedback.scss'

export default
@translate()
@inject('itemStore')
@observer
class Feedback extends React.Component {

  static propTypes = {
    feedback: number
  }

  @observable feedback = 0
  @observable sent = false

  componentDidMount() {
    const {feedback} = this.props
    this.feedback = feedback
  }

  likeTender = liked => {
    const { itemStore: { item } } = this.props
    //console.log('liked', item.TenderID, liked)
    setFeedback(item.TenderID, liked ? 1 : -1).then(() => {
      //clearCache()
      this.feedback = liked ? 1 : -1
      this.sent = true
    })
  }

  render() {
    const {t} = this.props
    //const clsLike = this.feedback == 1 ? 'focused' : ''
    //const clsDisLike = this.feedback == -1 ? 'focused' : ''
    return (

      <div styleName="action-container dotted-border">
        {this.sent ? 
          <div styleName="title">{t('tender.sentFeedback')}</div>
          :
          <div>
            <div styleName="title">{t('tender.didLike')}</div>
            <div styleName="subtitle">{t('tender.didLikeSub')}</div>
          </div>
        }
        <div styleName="like-dislike-buttons">
          <Button
            text={t('tender.liked')}
            icon={<LikeOutlined />}
            overrideClassName="like-dislike-btn"
            onClick={() => this.likeTender(true)}
          />
          <Button
            text={t('tender.disliked')}
            icon={<DislikeOutlined />}
            overrideClassName="like-dislike-btn"
            onClick={() => this.likeTender(false)}
          />
        </div>
      </div>
    )
  }
}
