import React from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { translate } from "react-polyglot";
import {
  MailOutlined,
  PrinterOutlined,
  PrinterFilled,
  BellOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import {
  createUrl,
  addToFavorites,
  getEmailData,
  clearCache,
} from "common/services/apiService";
import LoginDialog from "common/components/LoginDialog";
import ReactTooltip from "react-tooltip";

import "./Toolbar.scss";

export default
@translate()
@inject("accountStore")
@inject("recordStore")
@observer
class Toolbar extends React.Component {
  @observable showLoginMsg = false;
  @observable width = window.innerWidth;

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.width = window.innerWidth;
  };

  email = () => {
    /* send email with url to selected tenders */
    const { accountStore, recordStore, t } = this.props;
    if (accountStore.profile) {
      const itemsToAdd = recordStore.extractItems();
      getEmailData(itemsToAdd).then(
        (res) => {
          //console.log('email', uid, t("toolbar.emailBody", { uid: res.uid }))
          location.href = `mailto:someone@email.com?subject=${
            t("toolbar.emailSubject")
          }&body=${
            encodeURIComponent(t("toolbar.emailBody", { uid: res.uid }))
          }`
        }
      );
      //onClose()
      recordStore.cleanChecked();
    } else {
      this.showLoginMsg = true;
    }
  };

  print = (isBig) => {
    /* create pdf from selected tenders */
    const { accountStore, recordStore } = this.props;
    if (accountStore.profile) {
      const itemsToAdd = recordStore.extractItems();
      window.open(
        createUrl(
          "Export/ExportData",
          {
            ExportType: isBig ? 2 : 1,
            InfoList: itemsToAdd,
          },
          false
        ),
        "_blank"
      );
      //onClose()
      recordStore.cleanChecked();
    } else {
      this.showLoginMsg = true;
    }
  };

  addFavorites = () => {
    /* add selected tenders to favorites */
    const { accountStore, recordStore } = this.props;
    if (accountStore.profile) {
      const itemsToAdd = recordStore.extractItems();
      //iterate over the relevant items, and change IsFavorite state on original array
      //(this will cause the list to re-render, and show fav state on ResultsItem)
      itemsToAdd.map((tenderID) => {
        const found = recordStore.isInChecked(tenderID);
        //new way: add it anyway because it was touched
        recordStore.cut(tenderID);
        //add the item again with new fav state
        recordStore.push((found && found.checked) || false, tenderID, true);
      });
      //call api with items and add action
      addToFavorites("Favorite_add", itemsToAdd);
      clearCache();
      //onClose()
      recordStore.cleanChecked();
    } else {
      this.showLoginMsg = true;
    }
  };

  continueUnlogged = () => {
    this.showLoginMsg = false;
  };

  render() {
    const {
      recordStore: { checkedItems },
      t,
      showToolbar,
      closeToolbar,
    } = this.props;

    const relevantItems = checkedItems.filter((item) => item.checked || false);
    const toolBarStyle =
      relevantItems.length > 0 && showToolbar
        ? "action_bar active"
        : "action_bar";
    return (
      <div id="action_bar" styleName={toolBarStyle}>
        <div className="grid-container" styleName="container">
          <div styleName="action_bar_wraper">
            <div styleName="content">
              <div styleName="title">{t("toolbar.text")}</div>
              <div styleName="subTitle">
                {t("toolbar.totalSelectedTenders", {
                  count: relevantItems.length,
                })}
              </div>
            </div>
            <div styleName="icon-container">
              <div styleName="icon heart" data-tip={t("toolbar.favTooltip")}>
                <HeartOutlined onClick={this.addFavorites} />
              </div>
              <ReactTooltip />
              {/*this.width > 767 && (
                <div
                  styleName="icon bell"
                  data-tip={t("toolbar.addReminderTooltip")}
                >
                  <BellOutlined />
                </div>
              )*/}
              <ReactTooltip />
              <div
                styleName="icon mail"
                onClick={() => this.print(false)}
                data-tip={t("toolbar.printTooltip")}
              >
                <PrinterOutlined />
              </div>
              <div
                styleName="icon mail"
                onClick={() => this.print(true)}
                data-tip={t("toolbar.printBigTooltip")}
              >
                <PrinterFilled />
              </div>
              <ReactTooltip />
              <div
                styleName="icon mail"
                onClick={this.email}
                data-tip={t("toolbar.mailTooltip")}
              >
                <MailOutlined />
              </div>
              <ReactTooltip />
              {/* <div styleName="icon close" onClick={closeToolbar}>
                <CloseOutlined />
              </div> */}
            </div>
          </div>
        </div>
        {this.showLoginMsg && <LoginDialog onCancel={this.continueUnlogged} />}
        <ReactTooltip />
      </div>
    );
  }
}
