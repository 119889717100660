import React, {Component} from 'react'
import ThankYouComponent from 'components/ThankYou'
////import CSSModules from 'react-css-modules'
//import  './Tender.scss'

//
export default class ThankYou extends Component {


  //componentWillMount() {}
  //componentWillReceiveProps = (nextProps, nextState) => {};

  render(){
    return <div><ThankYouComponent/></div>
  }
}
