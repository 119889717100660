import React from "react";
import { logoSrc } from "common/style/icons";

import "./LoginDialog.scss";

/*const req = require.context('common/style/icons/', false)
const logo = req('./logo.png').default
*/

const LoginForm = ({
  accountStore,
  error,
  userName,
  password,
  updateField,
  onKeyDown,
  login,
  onStateChange,
  t,
  isMobile,
}) => {
  const errorMessage =
    error && error == "username or password are not correct"
      ? t("loginForm.badUserPass")
      : error?.indexOf('Incorrect format of phone number') > -1 
      ? t("loginForm.badPhoneNumber")
      : error;
  return (
    <div styleName="loginForm">
      <div styleName="logo">
        <img src={logoSrc} />
      </div>
      <div styleName="innerContainer">
        <div styleName="mb36">
          <div styleName="heading">{t("loginForm.title")}</div>
          <div styleName="divider"></div>
        </div>
        <div>
          {error != null && accountStore.profile == null && (
            <div styleName="error_box">{errorMessage}</div>
          )}
          <div styleName="inputContainer">
            <div styleName="label">{t("loginForm.userName")}</div>
            <input
              styleName="input"
              type="text"
              name="userName"
              value={userName}
              onChange={updateField}
              placeholder=""
              onKeyDown={(e) => onKeyDown(e, "login")}
            />
          </div>
          <div styleName="inputContainer">
            <div styleName="label">{t("loginForm.password")}</div>
            <input
              styleName="input"
              type="password"
              name="password"
              value={password}
              onChange={updateField}
              onKeyDown={(e) => onKeyDown(e, "login")}
            />
          </div>
          <div styleName="bottom">
            <div styleName="checkbox-container">
              <div styleName="label">{t("loginForm.remember")}</div>
              <div styleName="checkbox">
                <input
                  type="checkbox"
                  name="rememberMe"
                  onChange={updateField}
                />
              </div>
            </div>
            <div styleName="link link1">
              <a
                onClick={() => onStateChange("forgot")}
                style={{ paddingRight: "20px" }}
              >
                {t("loginForm.forgot")}
              </a>
            </div>
          </div>
        </div>
        <button styleName="btn" onClick={login}>
          {t("loginForm.login")}
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
