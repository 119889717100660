import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'
import {translate} from 'react-polyglot'
import {getMetaData} from 'common/utils/meta'
import Footer from 'common/components/Footer'
import Adsense from "common/components/Adsense/Adsense";
import SmallContactForm from 'common/components/SmallContactForm'
import './TermsOfUse.scss'
import { termsOfUseLists } from 'common/constants'


@translate()
class TermsOfUse extends Component {
  render() {
    const {t} = this.props
    const meta = getMetaData(t('meta.homeTitle'), t('meta.homeDesc'), t('meta.homeKeywords'))

    return (
      <div>
        <DocumentMeta {...meta} />
        <section styleName="section" >
            <div styleName="adsense-container-desktop" style={{height: `${0.75*window.innerHeight}px`}} >
                <Adsense
                    style={{
                        display: "block",
                        width: "160px",
                        height: "600px",
                        // position: "absolute",
                        // border:"1px solid black",
                        // left: "0",
                        // top: "20px",
                        // zIndex: "99999999",
                    }}
                    slot="4313418986"
                    googleAdId="ca-pub-7408487462494237"
                />
            </div>
            <div className="row" style={{width: '80%', maxWidth: '155rem'}} >
                <div className="large-12 small-12 columns" >
                    <h1 styleName="title" >{t('privacyPolicy.terms')}</h1>
                    <h2 styleName="subtitle">{t('privacyPolicy.readTheDocumentCarefully')}</h2>
                    <ol styleName="ol" >
                        {
                            termsOfUseLists.map((terms,index) => {
                                return (
                                    <li key={index} styleName="termsTitle" >
                                        <strong>{terms.title}</strong>
                                        <ol styleName="ol">
                                            {
                                                terms.list.map((term,index)=> {
                                                    return <li key={index} >{term}</li>
                                                })
                                            }
                                        </ol>
                                    </li>
                                )
                            })
                        }
                    </ol>
                </div>
                {/* <div className="large-4 small-12 columns">
                    <SmallContactForm />
                </div> */}
            </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default  TermsOfUse
